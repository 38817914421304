import React, { Component } from 'react'
import { Modal, Accordion, Card } from "react-bootstrap";

export default class Varient extends Component {
    constructor (props) {
        super(props);
        this.state = {
            rowData: {
                DishId: '',
                DishVariantId: '0',
                VariantName: "",
                Quantity: 0,
                Price: 0,
                Discount: 0,
                PackingCharges: 0,
                FinalPrice: 0,
                QuantityType: ''
            },


            show15: false
        }
        this.id = this.props.id;
        this._handleAddVarientEvent = this.AddVarientEvent.bind(this);
        this._handleVarientChangeEvent = this.VarientItemChangeEvent.bind(this);
    }

    componentDidMount() {
        function formValidation() {
            window.addEventListener('load', function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function (form) {
                    form.addEventListener('submit', function (event) {
                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add('was-validated');
                        }
                        else {
                            form.classList.remove('was-validated');
                        }
                    }, false);
                });
            }, false);
        }

        formValidation();
    }
    handleShowVarient() {
        this.setState({ show15: true });

    }

    handleClose() {
        this.setState({ show15: false });
    }

    VarientItemChangeEvent = event => {
        const value = event.target.value;
        const key = event.target.name;

        let rowData = this.state.rowData;
        rowData[key] = value;
        if (key != 'VariantName' || key != 'QuantityType') {
            rowData.FinalPrice = Math.round(parseFloat(rowData.Price) - ((parseFloat(rowData.Price) * parseFloat(rowData.Discount)) / 100) + parseFloat(rowData.PackingCharges), 2);
            //rowData.FinalPrice = parseFloat(rowData.Price);
        }



        this.setState({
            rowData: rowData
        });

    }
    AddVarientEvent = (event) => {

        event.preventDefault();


        if (event.target.checkValidity()) {
            let rowData = this.state.rowData;
            console.log(rowData);
            rowData.DishId = this.id;
            this.props.FillVarientTable(rowData);
            rowData = {
                DishId: '',
                DishVariantId: '0',
                VariantName: "",
                Quantity: 0,
                Price: 0,
                Discount: 0,
                PackingCharges: 0,
                FinalPrice: 0,
                QuantityType: ''
            }

            this.setState(
                { rowData: rowData }
            )
        }
        else {
            console.log(event.target.checkValidity())
        }
    }

    render() {
        return (
            <div>
                <div className="row">

                    <Modal className="modal-max" show={ this.state.show15 } onHide={ () => this.handleClose() } aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Body>
                            <div className="col-md-12 mb-3">
                                <button type="button" className="close" onClick={ () => this.handleClose() }><span aria-hidden="true">×</span></button>
                                <h1 style={ { textAlign: "center" } }>Add Variant</h1>
                                <hr></hr>
                            </div>
                            <div className="col-md-12 mb-3">

                                <form className="needs-validation" noValidate onSubmit={ this._handleAddVarientEvent } id="VarientForm" >
                                    <div className="form-group row">
                                        <label htmlFor="colFormLabelSm" className="col-sm-4 col-form-label col-form-label-sm">Variant Name</label>
                                        <div className="col-sm-8">
                                            <input type="text" value={ this.state.rowData.VariantName || '' } onChange={ this._handleVarientChangeEvent } id="VariantName" name="VariantName" placeholder="Large" required className="form-control form-control-sm" />
                                            <div className="invalid-feedback">
                                                Please enter variant name.
                                             </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="colFormLabelSm" className="col-sm-4 col-form-label col-form-label-sm">Quantity</label>
                                        <div className="col-sm-4">
                                            <input type="text" value={ this.state.rowData.Quantity || '' } onChange={ this._handleVarientChangeEvent } id="Quantity" name="Quantity" placeholder="10 Inch" required className="form-control form-control-sm" />
                                            <div className="invalid-feedback">
                                                Please enter quantity.
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <select className="form-control" name="QuantityType" id="QuantityType" required value={ this.state.rowData.QuantityType || '' } onChange={ this._handleVarientChangeEvent }>
                                                <option></option>
                                                <option>Mg</option>
                                                <option>Pic</option>
                                                <option>Pkg</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                Please select quantity unit.
                                                         </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="colFormLabelSm" className="col-sm-4 col-form-label col-form-label-sm">Price</label>
                                        <div className="col-sm-8">
                                            <input type="number" step=".01" value={ this.state.rowData.Price || '' } onChange={ this._handleVarientChangeEvent } id="Price" name="Price" placeholder="$10" required className="form-control form-control-sm" />
                                            <div className="invalid-feedback">
                                                Please enter price.
                                    </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="colFormLabelSm" className="col-sm-4 col-form-label col-form-label-sm">Discount</label>
                                        <div className="col-sm-8">
                                            <input type="number" value={ this.state.rowData.Discount || '' } onChange={ this._handleVarientChangeEvent } id="Discount" name="Discount" placeholder="0%" className="form-control form-control-sm" />
                                            <div className="invalid-feedback">
                                                Please enter discount.
                                    </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="colFormLabelSm" className="col-sm-4 col-form-label col-form-label-sm">Packing Charges</label>
                                        <div className="col-sm-8">
                                            <input type="number" step=".01" value={ this.state.rowData.PackingCharges || '' } onChange={ this._handleVarientChangeEvent } id="PackingCharges" name="PackingCharges" placeholder="$1" className="form-control form-control-sm" />
                                            <div className="invalid-feedback">
                                                Please enter packing charges.
                                    </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="colFormLabelSm" className="col-sm-4 col-form-label col-form-label-sm">Final Price</label>
                                        <div className="col-sm-8">
                                            <input type="number" value={ this.state.rowData.FinalPrice || '' } readOnly onChange={ this._handleVarientChangeEvent } id="FinalPrice" name="FinalPrice" placeholder="$11" className="form-control form-control-sm" />
                                            <div className="invalid-feedback">
                                                Please enter final price.
                                    </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">

                                        <div className="align-self-center mx-auto">
                                            <button className="btn btn-primary d-block" type="submit">Add</button>
                                        </div>

                                    </div>

                                </form>
                            </div>
                        </Modal.Body>
                    </Modal>


                </div>


            </div>
        )
    }
}
