import React, { Component } from 'react'

import ApiInstance from '../../Utilities/axiosConfig';

export default class EmailVarification extends Component {
    constructor (props) {
        super(props)
        this.id = this.props.match.params.id;

        this.state = {
            successmsg: "",
        }
    }

    componentDidMount() {
        this.GetOrderDetails();
    }

    GetOrderDetails = () => {


        ApiInstance().post('CookMaster/UpdateCookEmailVerificationComplete', JSON.stringify(this.id), { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                console.log(response.data)
                if (response.data.status) {
                    this.setState({ successmsg: true })
                }
                else {
                    this.setState({ successmsg: false })
                }
            }, error => {
                console.log(error);
            });
    }
    render() {
        console.log(this.state)
        return (
            <>
                <div className="main-sec"></div>
                <br></br>
                <section className="ex-collection ">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="section-header-left">
                                    { this.state.successmsg === true && <h4 className="text-light-black header-title text-center">Your Email id verified successfully! <a href="/Cook">Please Sig-in here. </a></h4> }
                                    { this.state.successmsg == false && <h4 className="text-light-black header-title text-center">Your Email id is not valid ! </h4> }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}
