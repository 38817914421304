import React, { Component } from 'react'
import jQuery from 'jquery'
import toastr from 'toastr';
import 'toastr/build/toastr.css'


export const ToastMessage = (title, message, type) => {
    toastr.options =
    {
        "closeButton": false,
        "debug": false,
        "newestOnTop": false,
        "progressBar": true,
        "positionClass": "toast-top-right",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    }

    switch (type) {
        case 'success':
            toastr.success(message, title);
            break;
        case 'info':
            toastr.info(message, title);
            break;
        case 'warning':
            toastr.warning(message, title);
            break;
        case 'danger':
            toastr.error(message, title);
            break;
        default:
            toastr.success(message, title);
            break;
    }

    jQuery("#toast-options").text("toastr.options = " + JSON.stringify(toastr.options));

};
