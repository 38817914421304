import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server'
import ReactDOM from 'react-dom';
import "datatables.net-bs4/js/dataTables.bootstrap4"
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css"
import $ from 'jquery';
import ApiInstance from '../../../Utilities/axiosConfig';
import Swal from 'sweetalert2'
import Config from '../../../Config';

// verifiedcls


class VerifiedCookProfileData extends Component {

    constructor () {
        super();

        this.state = {
            VerifiedRegisteredData: [],
            objkeylist: []
        }

        this._sweetalerthtml_Decline = this.sweetalerthtml_Decline.bind(this);



    }

    componentDidMount() {

        // this.BindDataTable();

    }

    GetNewRegistrationList() {

        ApiInstance().get('CookMaster/GetCookMasterListByIsApproved?IsApproved=1')
            .then(response => {
                var data = response.data.data;
                if (data) {
                    let dataObject = JSON.parse(response.data.data);
                    this.setState({ VerifiedRegisteredData: dataObject.Data, objkeylist: dataObject.Columns.split(",").map(i => i.trim()) }, () => { this.BindDataTable() });
                }
                else {
                }
            }, error => {
                console.log(error);
            });

    }

    BindDataTable() {

        var objkeylist = this.state.objkeylist;
        var columnlist = []
        objkeylist.forEach(element => {
            element = element.trim();
            if (element !== 'CookId')
                columnlist.push({ title: element.split(/(?=[A-Z])/).join(" "), data: element })
        });

        columnlist.push({
            data: 'CookId',
            title: 'Action',
            render: function (td, data, type, row) {
                return " ";
            }

        })



        $('#VerifiedCook_data-table-2').DataTable({

            data: this.state.VerifiedRegisteredData,
            columns: columnlist,

            "bDestroy": true,
            columnDefs: [
                {
                    targets: objkeylist.length - 1,
                    createdCell: (td, cellData, rowData, row, col) => {
                        ReactDOM.render(<>
                            <a href="#"
                                onClick={ () => this._sweetalerthtml_Decline(cellData, rowData) }>
                                Decline
                                             </a>  </>, td)
                    },
                },
                {
                    targets: objkeylist.indexOf("InsuranceDocument"),
                    createdCell: (td, cellData, rowData, row, col) =>

                        ReactDOM.render(<>

                            <a href={ Config.BaseURL + cellData } target="_blank"
                            >
                                view
                            </a> </>, td),
                }
            ],


        });



    }

    sweetalerthtml_Decline(data, rowData) {

        Swal.fire({
            text: "Are you sure to decline this cook?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!',
            showClass: {
                popup: 'animated fadeInDown faster',
                icon: 'animated heartBeat delay-1s'
            },
            hideClass: {
                popup: 'animated fadeOutUp faster',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                this.CookOperation(data, 2, rowData)

            }
        })

    }

    CookOperation(id, isapprove, rowData) {

        var dataobj = { "CookId": id, "IsApproved": isapprove };
        var thisobj = this;

        ApiInstance().post("CookMaster/CookMasterIsActiveById", JSON.stringify(dataobj), { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                let data = response.data;
                console.log(response.data.status);
                if (response.data.status) {
                    Swal.fire(
                        'Success!',
                        'Cook has been ' + ((isapprove === 1) ? 'approved' : 'decline') + '  successfully.',
                        'success'
                    )

                    let VerifiedRegisteredData = this.state.VerifiedRegisteredData;
                    VerifiedRegisteredData.splice(VerifiedRegisteredData.indexOf(rowData), 1);
                    this.setState({ VerifiedRegisteredData: VerifiedRegisteredData })
                    this.BindDataTable();
                }

            }, error => {
                console.log(error);
            });
        return false;
    }


    render() {
        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <div className="ms-panel">
                            <div className="ms-panel-header">
                                <h6></h6>
                            </div>
                            <div className="ms-panel-body">
                                <div className="table-responsive">
                                    <table id="VerifiedCook_data-table-2" className="table table-striped thead-primary w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default VerifiedCookProfileData;