import React, { Component } from 'react';
import Breadcrumb from './Breadcrumb';
import { Tab, Nav } from "react-bootstrap";
import NewCookProfileData from '../NewCookProfileData';
import VerifiedCookProfileData from '../VerifiedCookProfileData';
import DeclineCookProfileData from '../DeclineCookProfileData';


class CookProfileContent extends Component {

    constructor () {
        super();
        this.state = {
            CookProfileData: [],
            VerifiedRegisteredData: [],
            DeclineRegisteredData: []
        }

        this.RefNewCookProfileData = React.createRef();
        this.RefVerifiedCookProfileData = React.createRef();
        this.RefDeclineCookProfileData = React.createRef();
    }
    componentDidMount() {

    }

    GetCookProfileDataList(IsApprovedtype) {

        if (IsApprovedtype === 0) {
            this.RefNewCookProfileData.current.GetNewRegistrationList();
        };
        if (IsApprovedtype === 1) {
            this.RefVerifiedCookProfileData.current.GetNewRegistrationList();
        };
        if (IsApprovedtype === 2) {
            this.RefDeclineCookProfileData.current.GetNewRegistrationList();
        };

    }

    render() {
        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <Breadcrumb />
                    </div>
                    <div className="col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-header">
                                <h6>Cook Profile</h6>
                            </div>
                            <div className="ms-panel-body">
                                {/* <p className="ms-directions">Use <code>.tabs-bordered</code> in <code>.nav-tabs</code></p> */ }
                                <Tab.Container defaultActiveKey="tab1">
                                    <Nav variant="tabs" className="nav nav-tabs tabs-bordered d-flex nav-justified mb-4">
                                        <Nav.Item>
                                            <Nav.Link eventKey="tab1" onClick={ () => this.GetCookProfileDataList(0) }>New</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tab2" onClick={ () => this.GetCookProfileDataList(1) }>Verified</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tab3" onClick={ () => this.GetCookProfileDataList(2) }>Declined</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="tab1">
                                            <NewCookProfileData ref={ this.RefNewCookProfileData } />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tab2">
                                            <VerifiedCookProfileData ref={ this.RefVerifiedCookProfileData } />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tab3">
                                            <DeclineCookProfileData ref={ this.RefDeclineCookProfileData } />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default CookProfileContent;