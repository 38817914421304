import React, { Component } from 'react'
import { GetSessionData } from '../../../GlobalFactory';
import ApiInstance from '../../../Utilities/axiosConfig';
import Swal from 'sweetalert2'
import { renderIntoDocument } from 'react-dom/test-utils';
import LoaderComponent from '../../../components/LoaderComponent';
import Config from '../../../Config';
import { ToastMessage } from '../../../Utilities/ToastMessage'
import $ from 'jquery';

export default class PhotoGallary extends Component {
    constructor () {
        super()
        this.state = {
            GallaryData: {},
            GallaryList: [],
            ActiveLoader: false,
            Setformvisible: false
        }
        this._handleChangeEvent = this.ItemChangeEvent.bind(this);
        this._handleSubmitEvent = this.handleSubmit.bind(this);
        this._handleDeleteEvent = this.handleDeleteEvent.bind(this);
        this._setFile = this.setFile.bind(this);
    }

    componentDidMount() {

        //initialize datatable
        function formValidation() {
            window.addEventListener('load', function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function (form) {
                    form.addEventListener('submit', function (event) {
                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add('was-validated');
                        }
                        else {
                            form.classList.remove('was-validated');
                        }
                    }, false);
                });

            }, false);
        }
        formValidation();


        this.GetGallaryDetails();
    }


    GetGallaryDetails() {

        ApiInstance().get('/PhotoGalleryMaster/SelecttblPhotoGalleryMasterByCookId/' + GetSessionData().UserId)
            .then(response => {
                var data = JSON.parse(response.data.data);
                if (data) {
                    console.log(data)
                    this.setState({ GallaryList: data.Data })
                }
                else {
                }
            }, error => {
                console.log(error);
            });
    }


    ItemChangeEvent = event => {

        const value = event.target.value;
        const key = event.target.name;



        let GallaryData = this.state.GallaryData;
        GallaryData[key] = value;

        this.setState({
            GallaryData: GallaryData
        });


    }

    setFile(e) {
        e.preventDefault();

        if (this.validate(e.target)) {

            console.log(e.target.files[0])

            // var image = document.getElementById('previewimage1');
            // image.src = URL.createObjectURL(e.target.files[0]);

            // var imputfilename = document.getElementById("imputfilename1");
            // imputfilename.innerText = e.target.files[0].name;

            var image = document.getElementById('previewimage_g');
            image.src = URL.createObjectURL(e.target.files[0]);

            var imputfilename = document.getElementById("imputfilename_g");
            imputfilename.innerText = e.target.files[0].name;


            this.setState({ file: e.target.files[0] }, () => {
            });
        }

    }
    validate(file) {


        var valid = true;
        var file_name = "";
        var file_type = "";
        var file_size = "";
        var error_msg = "";
        var valid_size = 2 * 1000 * 1000;
        var display_error = document.getElementById('file_error');

        if (file.files.length != 0) {
            file_name = file.files[0].name;
            file_size = file.files[0].size;
            file_type = file.files[0].type;

            if (file_type != "image/png" && file_type != "image/jpeg" && file_type != "image/bmp") {
                valid = false;
                error_msg = error_msg + "\n* Only 'JPG/JPEG' and 'BMP'  file type supported.";
            }
            if (file_size > valid_size) {
                valid = false;
                error_msg = error_msg + "\n* Filesize should be upto 2MB.";
            }
        }
        else {
            valid = false;
            error_msg = error_msg + "\n* Please select any image file.";
        }
        if (valid == true) {

            /*Write ajax code here to send file to the server.*/
            return true;
        }
        else {
            display_error.innerHTML = error_msg;
            return false;
        }
    }


    handleSubmit = event => {
        event.preventDefault()

        if (event.target.checkValidity()) {
            this.setState({ ActiveLoader: true })
            const formData = new FormData();
            formData.append("file", this.state.file);
            formData.append("data", JSON.stringify(this.state.GallaryData));
            console.log(formData)

            ApiInstance().post("PhotoGalleryMaster/InserttblPhotoGalleryMaster", formData, { headers: { 'Content-Type': 'multipart/form-data', } })
                .then(response => {
                    let data = response.data;
                    console.log(data);
                    if (data.status) {
                        ToastMessage('Success', 'Record has been inserted successfully', 'success')
                        this.resetFormData()
                    }
                    this.setState({ ActiveLoader: false })

                }, error => {
                    //window.init_PNotify("Error", "error", "Record not updated!!");
                    this.setState({ ActiveLoader: false })
                    console.log(error);
                });



        }
        else {
            console.log(event.target.checkValidity())
        }

    }

    resetFormData = () => {

        const clonedState = this.state.GallaryData;
        const keys = Object.keys(clonedState);
        keys.forEach(key => (delete clonedState[key]));

        this.setState({
            GallaryData: {},
            ActiveLoader: false

        });
        document.getElementById("previewimage_g").src = "../assets/img/costic/customer-2.jpg";
        var imputfilename = document.getElementById("imputfilename_g");
        imputfilename.innerText = "Upload Images...";
        var forms = document.getElementsByClassName('needs-validation');
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.classList.remove('was-validated');
        }, false);

    }


    handleDeleteEvent = (id) => {

        var thisobj = this;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showClass: {
                popup: 'animated fadeInDown faster',
                icon: 'animated heartBeat delay-1s'
            },
            hideClass: {
                popup: 'animated fadeOutUp faster',
            },
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(function (result) {
            if (result.value) {

                let formdata = {
                    'PhotoGalleryId': id
                }

                ApiInstance().post('PhotoGalleryMaster/DeletetblPhotoGalleryMasterByPhotoGalleryId', formdata, { headers: { 'Content-Type': 'application/json' } })
                    .then(response => {
                        let data = response.data;
                        if (data.status) {
                            ToastMessage('Success', 'Record has been deleted successfully.', 'success')
                            thisobj.GetGallaryDetails();
                        }
                        else {
                            ToastMessage('Fail!', data.data, 'danger')
                        }

                    }, error => {
                        console.log(error);
                    });

            }
        });




    }

    render() {
        return (
            <>
                <div>
                    <LoaderComponent ActiveLoader={ this.state.ActiveLoader }></LoaderComponent>
                    { this.state.Setformvisible &&
                        <form className="needs-validation" noValidate onSubmit={ this._handleSubmitEvent } id="registrationform">
                            {/* <p style={ { fontSize: "18px", fontWeight: 'bold' } }>Personal Information : </p>
                                    <hr /> */}
                            <div className="form-row">
                                <div className="col-md-6">
                                    <div className="col-md-12">
                                        <label htmlFor="Title">Title <span className="required"></span></label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" id="Title" value={ this.state.GallaryData.Title || '' } name="Title" placeholder="" onChange={ this._handleChangeEvent } minLength="2" maxLength="15" />
                                            <div className="invalid-feedback">
                                                Please enter Title.
                                             </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="Description">Description<span className="required"></span></label>
                                        <div className="input-group">
                                            <textarea className="form-control" id="Description" name="Description" value={ this.state.GallaryData.Description || '' } placeholder="" onChange={ this._handleChangeEvent } minLength="2" maxLength="200" ></textarea>

                                            <div className="invalid-feedback">
                                                Please enter Description.
                                             </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">


                                        <label htmlFor="validationCustom12">Upload File</label>
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="validatedCustomFile_g" onChange={ this._setFile } style={ { display: 'block' } } />
                                            <label className="custom-file-label" htmlFor="validatedCustomFile_g" id="imputfilename_g">Upload Images...</label>
                                            <div className="invalid-feedback">Example invalid custom file feedback</div>
                                            <div id="file_error" className="invalid-feedback d-block"> </div>
                                        </div>

                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <img className="d-block" style={ { margin: "auto", height: "300px", maxHeight: "300px" } } src="../assets/img/costic/customer-2.jpg" alt="First slide" id="previewimage_g" />
                                </div>
                            </div>
                            <button className="btn btn-primary w-20" type="button" onClick={ () => { this.setState({ Setformvisible: false }); this.resetFormData(); this.GetGallaryDetails(); } } >View Gallery</button> &nbsp;&nbsp;&nbsp;
                            <button className="btn btn-primary w-20" type="submit" >Save</button>


                            {/* <div className="col-md-12">

                                <label htmlFor="validationCustom12">Upload File</label>
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="validatedCustomFile1" onChange={ () => this._setFile } />
                                    <label className="custom-file-label" htmlFor="validatedCustomFile" id="imputfilename1">Upload Images...</label>
                                    <div className="invalid-feedback">Example invalid custom file feedback</div>
                                    <div id="file_error" className="invalid-feedback d-block">
                                    </div>
                                </div>

                            </div> */}



                        </form>
                    }
                </div>
                <div>
                    { !this.state.Setformvisible &&
                        <>
                            <button className="btn btn-primary mt-4 d-block w-20" style={ { float: 'left', marginBottom: "25px" } } type="button" onClick={ () => { this.setState({ Setformvisible: true }) } } >Add new</button>
                            <table id="Cuisine_data-table" className="table table-striped thead-primary w-100" >
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Description</th>
                                        <th>Image</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { this.state.GallaryList.map((item) => (
                                        <tr key={ item.PhotoGalleryId }>
                                            <td>{ item.Title }</td>
                                            <td>{ item.Description }</td>
                                            <td>
                                                <img className="d-block" style={ { maxWidth: "150px", maxHeight: "80px", borderRadius: "0%" } } src={ (item.PhotoPath) ? (Config.BaseURL + item.PhotoPath) : "../assets/img/costic/customer-2.jpg" } ></img>

                                            </td>
                                            <td><a href="#" onClick={ () => { this._handleDeleteEvent(item.PhotoGalleryId) } }><i className="far fa-trash-alt ms-text-danger"></i></a></td>
                                        </tr>
                                    )) }
                                </tbody>
                            </table>
                        </>
                    }
                </div>

            </>
        )
    }
}
