import React, { Component } from 'react'
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../../components/layouts/Topnavigation';
import Quickbar from '../../../components/layouts/Quickbar';
import Breadcrumb from '../Breadcrumb';
import Productslider from './Productslider';
import ApiInstance from '../../../Utilities/axiosConfig';
import Clientmanagement from '../../../components/pages/Clientmanagement';
import $ from 'jquery';
import { Modal, Accordion, Card } from "react-bootstrap";
import ReactDOM from 'react-dom';
import Varient from './Varient';
import DishAddOns from './DishAddOns'
import { ToastMessage } from '../../../Utilities/ToastMessage'
import { GetSessionData } from '../../../GlobalFactory';
import Config from '../../../Config';

export default class DishesForm extends Component {
    constructor (props) {
        super(props)

        this.id = this.props.match.params.id;


        this.state = {
            DishData: {
                CookId: '',
                DishName: '',
                CuisineId: '',
                FoodTypeId: '164',
                FoodMealTypeId: '',
                FoodCategoryTypeId: '',
                AllergensId: '',
                Description: '',
                IsInStock: false,
                Keyword: '',
                TotalCalories: '',
                tblDishAddOns: [],
                tblDishVariants: [],
                Nutritions: '',
                Quantity: '',
                QuantityType: '',
                Price: 0,
                Discount: 0,
                PackingCharges: 0,
                FinalPrice: 0

            },

            file: '',
            FoodTypeList: [],
            CuisineList: [],
            FoodMealList: [],
            FoodCategoryList: [],
            AllergensList: [],
            drpNutritions: [],
            NutritionsShow: false,
            SelectdNutritions: []
        }

        this._handleChangeEvent = this.ItemChangeEvent.bind(this);
        this._ItemCheckedEvent = this.ItemCheckedEvent.bind(this);

        this._handleSubmitEvent = this.SubmitData.bind(this);
        this.handleShowVarient = this.handleShowVarient.bind(this);
        this.handleShowAddOns = this.handleShowAddOns.bind(this);
        this.handleNutritionsShow = this.handleNutritionsShow.bind(this);

        this.refVarient = React.createRef();
        this.refAddOns = React.createRef();
        this._FillVarientTable = this.FillVarientTable.bind(this);
        this._FillDishAddOnsTable = this.FillDishAddOnsTable.bind(this);

    }

    handleShowVarient() {
        this.refVarient.current.handleShowVarient();

    }

    handleShowAddOns() {
        this.refAddOns.current.handleShowAddOns();
    }
    handleNutritionsShow() {
        this.setState({ NutritionsShow: true }, () => {
            this.FillNutritionsList();
        });
    }
    handleClose() {
        this.setState({ NutritionsShow: false });
    }

    componentDidMount() {
        function formValidation() {
            window.addEventListener('load', function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function (form) {
                    form.addEventListener('submit', function (event) {
                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add('was-validated');
                        }
                        else {
                            form.classList.remove('was-validated');
                        }
                    }, false);
                });
            }, false);
        }
        formValidation();

        this.BindAllMethod();

        if (this.id) {
            this.GetDishById()
        }
    }

    BindAllMethod() {
        this.GetFoodType();
        this.GetFoodMealList();
        this.GetCuisineList();
        this.GetFoodCategoryList();
        this.GetAllergensList();
        this.GetNutritionsList();
    }

    GetDishById() {
        ApiInstance().get('DishMaster/SelectAlltblDishMasterByDishId?DishId=' + this.id)
            .then(response => {
                var data = response.data.data;
                console.log(data)
                if (data) {
                    var objdata = JSON.parse(data)
                    console.log(objdata.Data[0])
                    var DishData = objdata.Data[0];
                    DishData.Nutritions = JSON.parse(objdata.Data[0].Nutritions)

                    let SelectdNutritions = DishData.Nutritions;
                    this.setState({
                        DishData: DishData, SelectdNutritions: SelectdNutritions
                    });
                }
            }, error => {
                console.log(error);
            });
    }

    FillVarientTable(objDishVariants) {
        let DishData = this.state.DishData;
        if (DishData.tblDishVariants == null) {
            DishData.tblDishVariants = [];
        }
        DishData.tblDishVariants.push(objDishVariants)
        this.setState({ DishData })
    }

    FillDishAddOnsTable(objDishAddOns) {
        let DishData = this.state.DishData;
        if (DishData.tblDishAddOns == null) {
            DishData.tblDishAddOns = [];
        }
        DishData.tblDishAddOns.push(objDishAddOns)
        this.setState({ DishData })
    }

    DeleteVarient(element) {
        let DishData = this.state.DishData;
        // var index = DishData.tblDishVariants.indexOf(element);
        // delete DishData.tblDishVariants[index]
        DishData.tblDishVariants = DishData.tblDishVariants.filter(e => e.VariantName !== element.VariantName)
        this.setState({ DishData })
    }

    DeleteDishAddOns(element) {
        let DishData = this.state.DishData;
        // var index = DishData.tblDishAddOns.indexOf(element);
        // delete DishData.tblDishAddOns[index]
        DishData.tblDishAddOns = DishData.tblDishAddOns.filter(e => e.AddOnsName !== element.AddOnsName)
        this.setState({ DishData })
    }

    validate(file) {


        var valid = true;
        var file_name = "";
        var file_type = "";
        var file_size = "";
        var error_msg = "";
        var valid_size = 5 * 1000 * 1000;
        var display_error = document.getElementById('file_error');

        if (file.files.length != 0) {
            file_name = file.files[0].name;
            file_size = file.files[0].size;
            file_type = file.files[0].type;

            if (file_type != "image/png" && file_type != "image/jpeg" && file_type != "image/bmp") {
                valid = false;
                error_msg = error_msg + "\n* Only 'PNG', 'JPG/JPEG' and 'BMP'  file type supported.";
            }
            if (file_size > valid_size) {
                valid = false;
                error_msg = error_msg + "\n* Filesize should be upto 5MB.";
            }
        }
        else {
            valid = false;
            error_msg = error_msg + "\n* Please select any image file.";
        }
        if (valid == true) {

            /*Write ajax code here to send file to the server.*/
            return true;
        }
        else {
            display_error.innerHTML = error_msg;
            return false;
        }
    }

    GetFoodType() {
        ApiInstance().get('FoodTypeMaster/SelectAlltblFoodTypeMaster')
            .then(response => {
                var data = response.data.data;
                if (data) {
                    var objdata = JSON.parse(data)
                    var FoodTypeId = objdata.Data.find(e => e.FoodTypeName == "Veg").FoodTypeId;
                    var DishData = this.state.DishData;
                    //DishData.FoodTypeId = FoodTypeId;
                    this.setState({
                        FoodTypeList: objdata.Data
                    });
                }
            }, error => {
                console.log(error);
            });
    }

    GetFoodMealList() {

        ApiInstance().get('FoodMealTypeMaster/SelectAlltblFoodMealTypeMaster')
            .then(response => {

                var data = response.data.data;


                if (data) {

                    let dataObject = JSON.parse(response.data.data);

                    this.setState({ FoodMealList: dataObject.Data });
                }
                else {
                }
            }, error => {
                console.log(error);
            });
    }

    GetCuisineList() {

        ApiInstance().get('CuisineMaster/SelectAlltblCuisineMaster')
            .then(response => {

                var data = response.data.data;
                if (data) {
                    let dataObject = JSON.parse(response.data.data);
                    this.setState({ CuisineList: dataObject.Data })
                }

            }, error => {
                console.log(error);
            });
    }

    GetFoodCategoryList() {

        ApiInstance().get('FoodCategoryTypeMaster/SelectAlltblFoodCategoryTypeMaster')
            .then(response => {

                var data = response.data.data;


                if (data) {

                    let dataObject = JSON.parse(response.data.data);


                    this.setState({ FoodCategoryList: dataObject.Data });
                }
                else {
                }
            }, error => {
                console.log(error);
            });
    }

    GetAllergensList() {

        ApiInstance().get('AllergensMaster/SelectAlltblAllergensMaster')
            .then(response => {

                var data = response.data.data;


                if (data) {

                    let dataObject = JSON.parse(response.data.data);

                    this.setState({ AllergensList: dataObject.Data });
                }

            });
    }

    handleTableRowChangeEvent = (event, textboxname, id) => {



        if (event.target.checked) {
            document.getElementById(textboxname).disabled = false
            document.getElementById(textboxname).focus();
        }
        else {
            document.getElementById(textboxname).disabled = true
            document.getElementById(textboxname).value = '';
            var SelectdNutritions = this.state.SelectdNutritions;
            var index = SelectdNutritions.findIndex(element => element.NutritionId == id)
            if (index > -1) {
                var obj = SelectdNutritions[index]
                obj.InitialValue = '';
                SelectdNutritions[index] = obj
                this.setState({ SelectdNutritions: SelectdNutritions }, () => { })
            }

        }
    }

    handleTableRowTextBoxEvent = (event, rowData) => {

        var SelectdNutritions = this.state.SelectdNutritions;
        var index = SelectdNutritions.findIndex(element => element.NutritionId == rowData['NutritionId'])
        if (index > -1) {

            var obj = SelectdNutritions[index]
            obj.InitialValue = event.target.value;
            SelectdNutritions[index] = obj
            this.setState({ SelectdNutritions: SelectdNutritions }, () => { })
        }

    }

    setFile(e) {
        if (this.validate(e.target)) {
            this.setState({ file: e.target.files[0] }, () => { });

            var previews = $("#previews");
            previews.empty();
            Array.prototype.slice.call(e.target.files)
                .forEach(function (file, idx) {
                    var reader = new FileReader();
                    reader.onload = function (event) {
                        $("<img />", {
                            "src": event.target.result,
                            "class": 'd-block w-100'
                        }).appendTo(previews);
                    };
                    reader.readAsDataURL(file);
                });

        }

    }


    ItemChangeEvent = event => {
        const value = event.target.value;
        const key = event.target.name;
        let DishData = this.state.DishData;



        DishData[key] = value.replace(/  +/g, ' ');
        DishData.FinalPrice = (parseFloat(DishData.Price) - ((parseFloat(DishData.Price) * parseFloat(DishData.Discount)) / 100) + parseFloat(DishData.PackingCharges)).toFixed(2);
        this.setState({
            DishData: DishData
        });





    }

    ItemCheckedEvent = event => {
        const value = event.target.checked;
        const key = event.target.name;
        let DishData = this.state.DishData;
        DishData[key] = value;

        this.setState({
            DishData: DishData
        });

    }


    DropDwonGetSelectedText(id) {
        var text = ""
        var e = document.getElementById(id);
        if (e) {
            text = e.options[e.selectedIndex].text;
        }
        return text;

    }

    ConcateKeywors(DishData) {

        var getVarient = "";
        DishData.tblDishVariants.map(i =>
            getVarient = i.VariantName + "," + getVarient
        )

        DishData.Keyword =
            DishData.DishName + ',' +
            this.DropDwonGetSelectedText("CuisineId") + ',' +
            this.state.FoodTypeList.find(e => e.FoodTypeId == DishData.FoodTypeId).FoodTypeName + ',' +
            this.DropDwonGetSelectedText("FoodMealTypeId") + ',' +
            this.DropDwonGetSelectedText("FoodCategoryTypeId") + ',' +
            DishData.Description + ',' + getVarient

        return DishData.Keyword;
    }


    SubmitData = (event) => {
        event.preventDefault();
        if (event.target.checkValidity()) {
            let url = ''
            if (this.id) {
                url = 'DishMaster/UpdatetblDishMaster'
            }
            else {
                url = 'DishMaster/InserttblDishMaster'
            }


            var DishData = this.state.DishData

            DishData.Keyword = this.ConcateKeywors(DishData)

            DishData.CookId = GetSessionData().UserId;
            //    var filterednutrition = this.state.SelectdNutritions.filter((e) => e.InitialValue !== '')
            DishData.Nutritions = (this.state.SelectdNutritions) ? JSON.stringify(this.state.SelectdNutritions) : '';
            const formData = new FormData();
            formData.append("data", JSON.stringify(DishData));
            formData.append("file", this.state.file);
            console.log("Submit data call..")

            console.log(JSON.stringify(DishData))
            console.log(DishData)

            ApiInstance().post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(response => {
                    var data = response.data;
                    console.log(response);
                    if (data.message == "Success") {
                        if (this.id) {
                            ToastMessage('Success', 'Record has been updated successfully', 'success')
                        }
                        else {
                            ToastMessage('Success', 'Record has been inserted successfully', 'success')
                            // this.ResetData()
                            setTimeout(function () { window.location.reload(); }, 1500);

                        }
                    }

                }, error => {
                    console.log(error);
                });
        }
    }


    GetNutritionsList() {
        ApiInstance().get('NutritionMaster/SelectAlltblNutritionMaster')
            .then(response => {
                var data = response.data.data;
                if (data) {
                    var objdata = JSON.parse(data)
                    //   NutritionId: rowData['NutritionId'], NutritionName: rowData['NutritionName'], Measurement: rowData['Measurement'], InitialValue: event.target.value
                    var SelectdNutritions = objdata.Data.map(
                        (element) => {
                            var ele = element;
                            ele.InitialValue = '';
                            return ele;
                        }
                    )

                    this.setState({
                        drpNutritions: objdata,
                        SelectdNutritions: SelectdNutritions
                    });
                }
            }, error => {
                console.log(error);
            });
    }

    handleTableRowChangeEvent = (event, textboxname, id) => {



        if (event.target.checked) {
            document.getElementById(textboxname).disabled = false
            document.getElementById(textboxname).focus();
        }
        else {
            document.getElementById(textboxname).disabled = true
            document.getElementById(textboxname).value = '';
            var SelectdNutritions = this.state.SelectdNutritions;
            var index = SelectdNutritions.findIndex(element => element.NutritionId == id)
            if (index > -1) {
                var obj = SelectdNutritions[index]
                obj.InitialValue = '';
                SelectdNutritions[index] = obj
                this.setState({ SelectdNutritions: SelectdNutritions }, () => { })
            }

        }
    }

    handleTableRowTextBoxEvent = (event, rowData) => {

        var SelectdNutritions = this.state.SelectdNutritions;
        var index = SelectdNutritions.findIndex(element => element.NutritionId == rowData['NutritionId'])
        if (index > -1) {

            var obj = SelectdNutritions[index]
            obj.InitialValue = event.target.value;
            SelectdNutritions[index] = obj
            this.setState({ SelectdNutritions: SelectdNutritions }, () => { })
        }

    }


    FillNutritionsList() {

        var objkeylist = this.state.drpNutritions.Columns.split(",").map(i => i.trim());
        var columnlist = [];

        objkeylist.forEach(element => {
            if (element.trim() !== 'NutritionId') columnlist.push({ title: element.split(/(?=[A-Z])/).join(" "), data: element })
        });

        columnlist.push({
            data: 'NutritionId',
            title: 'Action',
            render: function (td, data, type, row) {
                return " ";
            }
        })

        $('#Nutrition_data-table').DataTable({

            data: this.state.SelectdNutritions,
            columns: columnlist,
            "bDestroy": true,
            "paging": false,
            "searching": false,
            "drawCallback": function (settings) {
                //  alert('DataTables has finished its initialisation.');
                //console.log(settings)



            },
            columnDefs: [
                {
                    targets: objkeylist.length - 3,
                    createdCell: (td, cellData, rowData, row, col) =>

                        ReactDOM.render(
                            <>
                                <input type="text" className="form-control" disabled={ (rowData['InitialValue'] !== '') ? '' : 'disabled' } defaultValue={ rowData['InitialValue'] } onChange={ (e) => this.handleTableRowTextBoxEvent(e, rowData) } id={ "txtNutritionValue" + rowData['NutritionId'] } name={ "txtNutritionValue" + rowData['NutritionId'] } />
                            </>, td),
                },
                {
                    targets: objkeylist.length - 1,
                    createdCell: (td, cellData, rowData, row, col) =>
                        ReactDOM.render(<>
                            <input type="checkbox" defaultChecked={ (rowData['InitialValue'] !== '') } onChange={ (e) => this.handleTableRowChangeEvent(e, "txtNutritionValue" + cellData, cellData) }></input>
                        </>, td),
                }
            ],
        });
    }

    ResetData() {

        var SelectdNutritions = this.state.drpNutritions.Data.map(
            (element) => {
                var ele = element;
                ele.InitialValue = '';
                return ele;
            }
        )

        var FoodTypeId = this.state.FoodTypeList.find(e => e.FoodTypeName == "Veg").FoodTypeId;

        this.setState({
            DishData: {
                CookId: '',
                DishName: '',
                CuisineId: '',
                FoodTypeId: FoodTypeId,
                FoodMealTypeId: '',
                FoodCategoryTypeId: '',
                AllergensId: '',
                Description: '',
                IsInStock: false,
                Keyword: '',
                Nutritions: '',
                Quantity: '',
                QuantityType: '',
                Price: 0,
                Discount: 0,
                PackingCharges: 0,
                FinalPrice: 0,
                tblDishAddOns: [],
                tblDishVariants: [],
                Photo: ""


            },

            SelectdNutritions: SelectdNutritions,
            file: '',
        })



        var forms = document.getElementsByClassName('needs-validation');
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.classList.remove('was-validated');
        }, false);
        document.getElementById("previewimage_profile").src = "../assets/img/costic/add-product-2.jpg";

    }
    render() {


        return (
            <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                <Sidenavigation />
                <main className="body-content">
                    <Topnavigation />
                    <div className="ms-content-wrapper">
                        <form id="DishForm" className="needs-validation clearfix" noValidate onSubmit={ this._handleSubmitEvent }>
                            <div className="row">
                                <div className="col-md-12">
                                    <Breadcrumb FormName="Add Dish" />
                                </div>

                                <div className="col-xl-6 col-md-12">
                                    <div className="ms-panel ms-panel-fh">
                                        <div className="ms-panel-header">
                                            <h6>Add New Dish</h6>
                                        </div>
                                        <div className="ms-panel-body">

                                            <div className="form-row">


                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="DishName">Dish Name</label>
                                                    <div className="input-group">
                                                        <input type="text" value={ this.state.DishData.DishName || '' } onChange={ this._handleChangeEvent } className="form-control"
                                                            id="DishName"
                                                            name="DishName"
                                                            placeholder="Pizza"
                                                            minLength="1" maxLength="25"
                                                            required />
                                                        <div className="invalid-feedback">
                                                            Please enter dish name.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="CuisineId">Type of cusine</label>
                                                    <div className="input-group">
                                                        <select className="form-control" id="CuisineId" name="CuisineId" required value={ this.state.DishData.CuisineId || '' } onChange={ this._handleChangeEvent }>
                                                            <option></option>
                                                            {
                                                                this.state.CuisineList.map((element, index) => <option key={ index } value={ element.CuisineId }>{ element.CuisineName }</option>)
                                                            }
                                                        </select>
                                                        <div className="invalid-feedback">
                                                            Please select type of cuisine.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="FoodTypeId">Type of food</label>
                                                    <div className="input-group">
                                                        {/* <select className="form-control" id="FoodTypeId" name="FoodTypeId" required value={ this.state.DishData.FoodTypeId || '' } onChange={ this._handleChangeEvent }>
                                                            <option></option>
                                                            {
                                                                this.state.FoodTypeList.map((element, index) => <option key={ index } value={ element.FoodTypeId }>{ element.FoodTypeName }</option>)
                                                            }
                                                        </select> */}
                                                        <ul className="ms-list d-flex">
                                                            {
                                                                this.state.FoodTypeList.map((element, index) =>
                                                                    <li className="ms-list-item pl-0" key={ index }>
                                                                        <label className="ms-checkbox-wrap">
                                                                            <input type="radio" name="FoodTypeId" onChange={ this._handleChangeEvent } value={ element.FoodTypeId } defaultChecked={ (element.FoodTypeId == this.state.DishData.FoodTypeId) } /> <i className="ms-checkbox-check" />
                                                                        </label> <span> { element.FoodTypeName } </span>
                                                                    </li>
                                                                )


                                                            }
                                                        </ul>
                                                        <div className="invalid-feedback">
                                                            Please select type of food.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="FoodMealTypeId">Type of meal</label>
                                                    <div className="input-group">
                                                        <select className="form-control" id="FoodMealTypeId" name="FoodMealTypeId" required value={ this.state.DishData.FoodMealTypeId || '' } onChange={ this._handleChangeEvent }>
                                                            <option></option>
                                                            {
                                                                this.state.FoodMealList.map((element, index) => <option key={ index } value={ element.FoodMealTypeId }>{ element.FoodMealTypeName }</option>)
                                                            }
                                                        </select>
                                                        <div className="invalid-feedback">
                                                            Please select type of meal.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="FoodCategoryTypeId">Food category</label>
                                                    <div className="input-group">
                                                        <select className="form-control" id="FoodCategoryTypeId" name="FoodCategoryTypeId" required value={ this.state.DishData.FoodCategoryTypeId || '' } onChange={ this._handleChangeEvent }>
                                                            <option></option>
                                                            {
                                                                this.state.FoodCategoryList.map((element, index) => <option key={ index } value={ element.FoodCategoryTypeId }>{ element.FoodCategoryTypeName }</option>)
                                                            }
                                                        </select>
                                                        <div className="invalid-feedback">
                                                            Please select food category.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="AllergensId">Allergens</label>
                                                    <div className="input-group">
                                                        <select className="form-control" id="AllergensId" name="AllergensId" required value={ this.state.DishData.AllergensId || '' } onChange={ this._handleChangeEvent }>
                                                            <option></option>
                                                            {
                                                                this.state.AllergensList.map((element, index) => <option key={ index } value={ element.AllergensId }>{ element.AllergensName }</option>)
                                                            }
                                                        </select>
                                                        <div className="invalid-feedback">
                                                            Please select allergens.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="TotalCalories">Total Calaries</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" value={ this.state.DishData.TotalCalories || '' } onChange={ this._handleChangeEvent } id="TotalCalories" name="TotalCalories" placeholder="TotalCalories" required />
                                                        <div className="invalid-feedback">
                                                            Please select total calaries.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="Description">Description</label>
                                                    <div className="input-group">
                                                        <textarea rows={ 5 } id="Description" value={ this.state.DishData.Description || '' } onChange={ this._handleChangeEvent } name="Description" className="form-control" placeholder="Description" required minLength="15" maxLength="200" />
                                                        <div className="invalid-feedback">
                                                            Please enter dish description.
                                                         </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-12 mb-3">
                                                    <label htmlFor="Keyword">Search keywords</label>
                                                    <div className="input-group">
                                                        <textarea rows={ 3 } id="Keyword" name="Keyword" value={ this.state.DishData.Keyword || '' } onChange={ this._handleChangeEvent } className="form-control" placeholder="Search keywords" required />
                                                        <div className="invalid-feedback">
                                                            Please provide a Search keywords.
                                                         </div>
                                                    </div>
                                                </div> */}

                                                {/* --------------------------- */ }





                                                {/* <div className="col-md-12 mb-3">
                                                    <label htmlFor="Ingredients">Ingredients</label>
                                                    <div className="input-group">
                                                        <textarea rows={ 5 } id="Ingredients" value={ this.state.DishData.Ingredients || '' } onChange={ this._handleChangeEvent } name="Ingredients" className="form-control" placeholder="Ingredients" required />
                                                        <div className="invalid-feedback">
                                                            Please provide a Ingredients.
                                                         </div>
                                                    </div>
                                                </div> */}


                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="Nutritions" onClick={ () => this.handleNutritionsShow() }>Nutrition</label>
                                                    <div className="input-group">
                                                        <a href="#" className="form-control" onClick={ () => this.handleNutritionsShow() }>Add Nutritions</a>
                                                    </div>

                                                    <Modal className="modal-max" show={ this.state.NutritionsShow } onHide={ () => this.handleClose() } aria-labelledby="contained-modal-title-vcenter"
                                                        centered>
                                                        <Modal.Body>
                                                            <button type="button" className="close" onClick={ () => this.handleClose() }><span aria-hidden="true">×</span></button>
                                                            <h1>Select Nutrition</h1>
                                                            <table id="Nutrition_data-table" className="table table-striped thead-primary w-100" />

                                                            <div className="d-flex justify-content-between">
                                                                <button type="button" className="btn btn-light" onClick={ () => this.handleClose() }>Close</button>

                                                            </div>

                                                        </Modal.Body>
                                                    </Modal>

                                                </div>

                                                <div className="col-md-12 mb-3">
                                                    {
                                                        this.state.SelectdNutritions.map((element, index) => (
                                                            element.InitialValue !== '' && <>
                                                                { ' ' + element.NutritionName + ' - ' + element.InitialValue + ' ' + element.Measurement } <br></br>
                                                            </>


                                                        ))
                                                    }

                                                </div>



                                                {/* <div className="col-md-12 mb-3">
                                                    <label htmlFor="Instruction">Instructions</label>
                                                    <div className="input-group">
                                                        <textarea rows={ 5 } id="Instruction" name="Instruction" value={ this.state.DishData.Instruction || '' } onChange={ this._handleChangeEvent } className="form-control" placeholder="Instruction" required />
                                                        <div className="invalid-feedback">
                                                            Please provide a Instruction.
                                                         </div>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-8 mb-3">
                                                    <label htmlFor="Instruction">Quantity</label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" value={ this.state.DishData.Quantity | '' } onChange={ this._handleChangeEvent } id="Quantity" name="Quantity" placeholder="1 regular" required />
                                                        <div className="invalid-feedback">
                                                            Please provide a Quantity.
                                                         </div>


                                                    </div>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <label htmlFor="Instruction">Quantity Unit</label>
                                                    <div className="input-group">
                                                        <select className="form-control" name="QuantityType" id="QuantityType" required value={ this.state.DishData.QuantityType || '' } onChange={ this._handleChangeEvent }>
                                                            <option></option>
                                                            <option>Mg</option>
                                                            <option>Pic</option>
                                                            <option>Pkg</option>
                                                        </select>
                                                        <div className="invalid-feedback">
                                                            Please select quantity unit.
                                                         </div>


                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="Instruction">Price</label>
                                                    <div className="input-group">
                                                        <input type="number" min="0" minLength="1" step=".01" maxLength="10" className="form-control" value={ this.state.DishData.Price || '' } onChange={ this._handleChangeEvent } id="Price" name="Price" placeholder="$10" required />
                                                        <div className="invalid-feedback">
                                                            Please enter dish price.
                                                         </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="Instruction">Packaging price</label>
                                                    <div className="input-group">
                                                        <input type="number" min="0" minLength="1" step=".01" maxLength="10" className="form-control" value={ this.state.DishData.PackingCharges || '' } onChange={ this._handleChangeEvent } id="PackingCharges" name="PackingCharges" placeholder="$2.5" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="Instruction">Discount</label>
                                                    <div className="input-group">
                                                        <input type="number" min="0" minLength="1" maxLength="3" className="form-control" value={ this.state.DishData.Discount || '' } onChange={ this._handleChangeEvent } id="Discount" name="Discount" placeholder="10%" />

                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="Instruction">Final price</label>
                                                    <div className="input-group">
                                                        <input type="text" readOnly className="form-control" value={ this.state.DishData.FinalPrice || '' } onChange={ this._handleChangeEvent } id="FinalPrice" name="FinalPrice" placeholder="0.00" />
                                                        <div className="invalid-feedback">
                                                            Please provide a final price.
                                                         </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="ms-panel">
                                                <div className="ms-panel-header">
                                                    <h6>Dish Image </h6>
                                                </div>
                                                <div className="ms-panel-body" id="previews">
                                                    {/* <Productslider /> */ }
                                                    <img className="d-block w-100" src=
                                                        { (this.state.DishData.Photo) ? (Config.BaseURL + this.state.DishData.Photo) : "../assets/img/costic/add-product-2.jpg" } alt="First slide" id="previewimage_profile" />

                                                </div>
                                                <div className="ms-panel-header new" >
                                                    <div className="col-md-12 mb-3">
                                                        <label htmlFor="validationCustom12">Upload File</label>
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input" id="validatedCustomFile" onChange={ e => this.setFile(e) } />
                                                            <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Images...</label>
                                                            <div className="invalid-feedback">Example invalid custom file feedback</div>
                                                            <div id="file_error" className="invalid-feedback d-block">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="ms-panel-header new">

                                                    <div className="col-md-12 mb-3">
                                                        <a href="#" onClick={ () => this.handleShowVarient() } className="float-right">Add Variant</a>
                                                        <div class="col-lg-12 col-12 content-overflow p-0">
                                                        <table className="table table-striped w-100">
                                                            <thead>
                                                                <tr>
                                                                    <th>Variant Name</th>
                                                                    <th>Quantity</th>
                                                                    <th>Price</th>
                                                                    <th>Discount</th>
                                                                    <th>Packing Charges</th>
                                                                    <th>Final Price</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                { this.state.DishData.tblDishVariants && this.state.DishData.tblDishVariants.map((element, index) => {
                                                                    return (
                                                                        <tr key={ index }>
                                                                            <td>{ element.VariantName }</td>
                                                                            <td>{ element.Quantity + ' ' + element.QuantityType }</td>
                                                                            <td>{ element.Price }</td>
                                                                            <td>{ element.Discount }</td>
                                                                            <td>{ element.PackingCharges }</td>
                                                                            <td>{ element.FinalPrice }</td>
                                                                            <td> <button onClick={ (e) => { e.preventDefault(); this.DeleteVarient(element) } }>Delete </button>  </td>
                                                                        </tr>)
                                                                })
                                                                }
                                                            </tbody>
                                                        </table>

                                                        </div>
                                                     
                                                    </div>

                                                </div>

                                                <div className="ms-panel-header new">

                                                    <div className="col-md-12 mb-3">
                                                        <a href="#" onClick={ () => this.handleShowAddOns() } className="float-right">Add AddOns</a>
                                                      <div class="col-lg-12 col-12 content-overflow p-0">
                                                      <table className="table table-striped w-100">
                                                            <thead>
                                                                <tr>
                                                                    <th>AddOn Name</th>
                                                                    <th>Food Type</th>
                                                                    <th>AddOn Charges</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                { this.state.DishData.tblDishAddOns && this.state.DishData.tblDishAddOns.map((element, index) => {
                                                                    return (
                                                                        <tr key={ index }>
                                                                            <td>{ element.AddOnsName }</td>
                                                                            <td>{ (this.state.FoodTypeList.length > 0) ? this.state.FoodTypeList.find(e => e.FoodTypeId == element.FoodTypeId).FoodTypeName : '' }</td>
                                                                            <td>{ element.AddOnsCharges }</td>
                                                                            <td> <button onClick={ (e) => { e.preventDefault(); this.DeleteDishAddOns(element) } }>Delete </button>  </td>
                                                                        </tr>)
                                                                })
                                                                }
                                                            </tbody>
                                                        </table>

                                                      </div>
                                                      </div>
                                                </div>

                                                <div className="ms-panel-header new">
                                                    <p className="medium">Status Available</p>
                                                    <div>
                                                        <label className="ms-switch">
                                                            <input type="checkbox" id="IsInStock" name="IsInStock" checked={ this.state.DishData.IsInStock || false } onChange={ this._ItemCheckedEvent } />
                                                            <span className="ms-switch-slider round" />
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* <div className="ms-panel-header new">
                                                <p className="medium">Discount Active</p>
                                                <div>
                                                    <label className="ms-switch">
                                                        <input type="checkbox" defaultChecked />
                                                        <span className="ms-switch-slider round" />
                                                    </label>
                                                </div>
                                            </div> */}
                                                <div className="ms-panel-header new">
                                                    <button className="btn btn-primary d-block" type="submit">Save and Add</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </form>
                        <Varient ref={ this.refVarient } FillVarientTable={ this._FillVarientTable } id={ this.id }></Varient>
                        <DishAddOns ref={ this.refAddOns } FillDishAddOnsTable={ this._FillDishAddOnsTable } id={ this.id } ></DishAddOns>
                    </div>

                </main>
                <Quickbar />
            </div>
        )
    }
}
