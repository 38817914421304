import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../../components/layouts/Topnavigation';
import Quickbar from '../../../components/layouts/Quickbar';
import Breadcrumb from '../Breadcrumb';
import "datatables.net-bs4/js/dataTables.bootstrap4"
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css"
import $ from 'jquery';
import ApiInstance from '../../../Utilities/axiosConfig'
import { ToastMessage } from '../../../Utilities/ToastMessage'
import Swal from 'sweetalert2'
import { GetSessionData } from '../../../GlobalFactory';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { OverlayTrigger, Tooltip, Tab, Nav } from "react-bootstrap";
import { Link } from 'react-router-dom'
import Recentorder from './Recentorder'
import Restaurantlisting from './Restaurantlisting';
import Scrollbar from 'react-perfect-scrollbar'
import Trendingorder from './Trendingorder';
import Orderchart from './Orderchart';
import Chart from './Chart';
import Config from '../../../Config';


const phonetip = (
    <Tooltip>
        Call
    </Tooltip>
);
const videotip = (
    <Tooltip>
        Video Call
    </Tooltip>
);
const invitetip = (
    <Tooltip>
        Add to Chat
    </Tooltip>
);

class Dashboard extends Component {
    constructor () {
        super();

        this.state = {
            DashboardData: {},
            NewRestaurentListing: [],
            RecentOrderRequest: [],
            RecentPlacedOrders: [],
            TrendingOrders: [],
            DisputeData: []
        }
    }
    componentDidMount() {


        this.GetOrderList();
    }


    GetOrderList = () => {

        // var today = new Date()

        // var dtto = (document.getElementById("dtTo").value) ? document.getElementById("dtTo").value : today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        // var dtfrom = (document.getElementById("dtFrom").value) ? document.getElementById("dtFrom").value : today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

        // let jsonobj = {
        //     StartDate: dtfrom,
        //     EndDate: dtto,
        //     CustomerId: GetSessionData().UserId
        // };

        // console.log(jsonobj);

        ApiInstance().get('AdminLogin/GetAdminDashboardData')
            .then(response => {
                var data = response.data;
                console.log(data)
                if (data) {

                    var dataObject = JSON.parse(data.data)
                    console.log(dataObject.Data)


                    this.setState({
                        DashboardData: dataObject.Data[0],
                        NewRestaurentListing: JSON.parse(dataObject.Data[0].NewRestaurentListing).Data,
                        RecentOrderRequest: JSON.parse(dataObject.Data[0].RecentOrderRequest).Data,
                        RecentPlacedOrders: JSON.parse(dataObject.Data[0].RecentPlacedOrders).Data,
                        TrendingOrders: JSON.parse(dataObject.Data[0].TrendingOrders).Data,
                        DisputeData: JSON.parse(dataObject.Data[0].DisputeData).Data
                    }, () => {
                        //     var objkeylist = dataObject.Columns.split(",").map(i => i.trim());
                        //     var columnlist = []
                        //     objkeylist.forEach(element => {
                        //         element = element.trim();
                        //         if (element !== 'OrderMasterId')
                        //             columnlist.push({ title: element.split(/(?=[A-Z])/).join(" "), data: element })
                        //     });

                        //     columnlist.push({
                        //         data: 'OrderMasterId',
                        //         title: 'Action',
                        //         render: function (td, data, type, row) {
                        //             return " ";
                        //         }

                        //     })

                        //     $('#Cook_OrderList_data-table').DataTable({

                        //         data: this.state.OrderList,
                        //         columns: columnlist,
                        //         "bDestroy": true,
                        //         // columnDefs: [
                        //         //     {
                        //         //         targets: objkeylist.length - 1,
                        //         //         createdCell: (td, cellData, rowData, row, col) =>

                        //         //             ReactDOM.render(<>
                        //         //                 <a href={ "/Report/ReportCookOrderMasterDetailsByOrderMasterId/" + rowData.OrderMasterId } className="btn btn-primary btn-sm">View Details</a>

                        //         //             </>, td),
                        //         //     }
                        //         // ],
                        //     });
                    }

                    );
                }
            }, error => {
                console.log(error);
            });
    }



    render() {

        console.log(this.state)


        return (
            <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                <Sidenavigation />
                <main className="body-content">
                    <Topnavigation />
                    {/* <Homecontent /> */ }
                    <div className="ms-content-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="db-header-title">Welcome, { GetSessionData().UserName }</h1>
                            </div>
                            <div className="col-12">
                                {/* <Cart /> */ }
                                <div className="row">
                                    <div className="col-xl-2 col-lg-6 col-md-6">
                                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                                            {/* <span className="ms-chart-label bg-black"> <i className="material-icons">arrow_upward</i> 3.2%</span> */ }
                                            <div className="ms-card-body media">
                                                <div className="media-body">
                                                    <span className="black-text"><strong>Total Order</strong></span>
                                                    <h2>{ this.state.DashboardData.TotalOrder }</h2>
                                                </div>
                                            </div>
                                            {/* <LineChart data={this.state.data1} options={options} /> */ }
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-6 col-md-6">
                                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                                            {/* <span className="ms-chart-label bg-red"><i className="material-icons">arrow_downward</i> 4.5%</span> */ }
                                            <div className="ms-card-body media">
                                                <div className="media-body">
                                                    <span className="black-text"><strong>Total Sell</strong></span>
                                                    <h2>${ this.state.DashboardData.TotalSell }</h2>
                                                </div>
                                            </div>
                                            {/* <LineChart data={ this.state.data2 } options={ options } /> */ }
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-6 col-md-6">
                                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                                            {/* <span className="ms-chart-label bg-black"><i className="material-icons">arrow_upward</i> 12.5%</span> */ }
                                            <div className="ms-card-body media">
                                                <div className="media-body">
                                                    <span className="black-text"><strong>Total Cook Registered</strong></span>
                                                    <h2>{ this.state.DashboardData.TotalCook }</h2>
                                                </div>
                                            </div>
                                            {/* <LineChart data={ this.state.data3 } options={ options } /> */ }
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-6 col-md-6">
                                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                                            {/* <span className="ms-chart-label bg-red"><i className="material-icons">arrow_upward</i> 9.5%</span> */ }
                                            <div className="ms-card-body media">
                                                <div className="media-body">
                                                    <span className="black-text"><strong>Total Customer Registered</strong></span>
                                                    <h2>{ this.state.DashboardData.TotalCustomer }</h2>
                                                </div>
                                            </div>
                                            {/* <LineChart data={ this.state.data4 } options={ options } /> */ }
                                        </div>
                                    </div>

                                    <div className="col-xl-2 col-lg-6 col-md-6">
                                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                                            {/* <span className="ms-chart-label bg-black"><i className="material-icons">arrow_upward</i> 3.2%</span> */ }
                                            <div className="ms-card-body media">
                                                <div className="media-body">
                                                    <span className="black-text"><strong>Total Refund</strong></span>
                                                    <h2>${ this.state.DashboardData.RefundAmount }</h2>
                                                </div>
                                            </div>
                                            {/* <LineChart data={this.state.data1} options={options} /> */ }
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-6 col-md-6">
                                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                                            {/* <span className="ms-chart-label bg-red"><i className="material-icons">arrow_downward</i> 4.5%</span> */ }
                                            <div className="ms-card-body media">
                                                <div className="media-body">
                                                    <span className="black-text"><strong>Total Visitors</strong></span>
                                                    <h2>3,973</h2>
                                                </div>
                                            </div>
                                            {/* <LineChart data={ this.state.data2 } options={ options } /> */ }
                                        </div>
                                    </div>

                                </div>
                                {/* <Cart /> */ }
                            </div>
                            {/* Recent Orders Requested */ }
                            <div className="col-xl-6 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <div className="d-flex justify-content-between">
                                            <div className="align-self-center align-left">
                                                <h6>Recent Orders Requested</h6>
                                            </div>
                                            {/* <button type="button" className="btn btn-primary">View All</button> */ }
                                        </div>
                                    </div>
                                    <div className="ms-panel-body">
                                        <div className="table-responsive">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">OrderNumber</th>
                                                        <th scope="col">Restaurant Name</th>

                                                        <th scope="col">Qty</th>
                                                        <th scope="col">Total</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.RecentOrderRequest.map((item, index) =>
                                                            <tr key={ item.OrderMasterId }>
                                                                <td>#{ item.OrderMasterId }</td>
                                                                <td className="ms-table-f-w"> <img src={ (item.ProfilePhoto) ? (Config.BaseURL + item.ProfilePhoto) : "../assets/img/costic/pizza.jpg" } alt="people" /> { item.BussinessName } </td>
                                                                <td>{ item.TotalQty }</td>
                                                                <td>${ item.Grandtotal }</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <div className="d-flex justify-content-between">
                                            <div className="align-self-center align-left">
                                                <h6>Dispute Requested</h6>
                                            </div>
                                            {/* <button type="button" className="btn btn-primary">View All</button> */ }
                                        </div>
                                    </div>
                                    <div className="ms-panel-body">
                                        <div className="table-responsive">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Order Number</th>
                                                        <th scope="col">Dispute Id</th>
                                                        <th scope="col">Status</th>

                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Reason</th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.DisputeData.map((item, index) =>
                                                            <tr key={ item.OrderMasterId }>
                                                                <td>{ item.InsertDate }</td>
                                                                <td>#{ item.OrderMasterId }</td>
                                                                <td>{ item.DisputeId }</td>
                                                                <td>{ item.DisputeStatus }</td>

                                                                <td>{ item.OrderAmount }</td>
                                                                <td>{ item.ClientDisputeReason }</td>

                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* Food Orders */ }
                            <div className="col-md-12">
                                <Trendingorder TrendingOrders={ this.state.TrendingOrders } />
                            </div>
                            {/* END/Food Orders */ }

                            {/* Recent Orders Requested */ }

                            {/* Total Earnings */ }
                            {/* Recent Placed Orders< */ }
                            <div className="col-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <h6>Recently Placed Orders</h6>
                                    </div>
                                    <div className="ms-panel-body">
                                        <Recentorder RecentPlacedOrders={ this.state.RecentPlacedOrders } />
                                    </div>
                                </div>
                            </div>
                            {/* Recent Orders< */ }
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <h6>New Resturant Listings</h6>
                                    </div>
                                    <div className="ms-panel-body">
                                        <Restaurantlisting NewRestaurentListing={ this.state.NewRestaurentListing } />
                                    </div>
                                </div>
                            </div>
                            {/* Recent Support Tickets */ }

                        </div>
                    </div>
                </main>
                <Quickbar />
            </div>
        );
    }
}

export default Dashboard;