import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { GetSessionData } from './GlobalFactory';
/* ========================  Project Link  ========================================*/
import Preloader from './components/layouts/Preloader';
import Dashboard from './components/pages/Dashboard';
import OrderDetails from './Modules/Order/OrderDetails'

import Defaultlogin from './Modules/Login/LoginContent';
import Defaultregister from './Modules/Cook/Defaultregister/Defaultregister';
import OnBoardScreen from './Modules/Cook/Defaultregister/OnBoardScreen';

import DayWeekSpecialDishes from './Modules/Cook/DayWeekSpecialDishes/DayWeekSpecialDishes'

import Dishes from './Modules/Cook/DishesMaster/Dishes';
import DishesForm from './Modules/Cook/DishesMaster/DishesForm';
import CookDashboard from './Modules/Cook/Dashboard/Dashboard';

import CookProfile from './Modules/Admin/CookProfile/CookProfile';
import FoodTypeMaster from './Modules/Admin/FoodType/FoodTypeMaster';
import FoodCategoryMaster from './Modules/Admin/FoodCategory/FoodCategoryMaster';
import FoodMealMaster from './Modules/Admin/FoodMeal/FoodMealMaster';
import CuisineMaster from './Modules/Admin/Cuisine/CuisineMaster';
import AllergensMaster from './Modules/Admin/Allergens/AllergensMaster';
import NutritionMaster from './Modules/Admin/Nutrition/NutritionMaster';
import VerifyUser from '../src/Modules/EmailVarification/EmailVarification'
import CookProfileTab from './Modules/Cook/CookProfile/CookProfile';
import ChangePassword from './Modules/Login/ChangePassword';
import CookOrderList from './Modules/Cook/CookReport/CookOrderList';
import CookOrderDetails from './Modules/Cook/CookReport/CookOrderDetails';
import CookOrderDishWiseSelling from './Modules/Cook/CookReport/CookOrderDishWiseSelling';
import AdminOrderList from './Modules/Admin/AdminReports/AdminOrderList';
import Refund from './Modules/Admin/Dispute/Refund';
import RefundHistory from './Modules/Admin/Dispute/RefundHistory';
import AdminCookWiseSummary from './Modules/Admin/AdminReports/AdminCookWiseSummary';

import AdminDashboard from './Modules/Admin/Dashboard/Dashboard';

/* ========================  Project Link  End ========================================*/

import Accordions from './components/pages/Accordions';
import Addproduct from './components/pages/Addproduct';
import Alerts from './components/pages/Alerts';
import Animations from './components/pages/Animations';
import Badges from './components/pages/Badges';
import Basictables from './components/pages/Basictables';
import Breadcrumbs from './components/pages/Breadcrumbs';
import Buttons from './components/pages/Buttons';
import Cards from './components/pages/Cards';
import Chartjs from './components/pages/Chartjs';
import Chat from './components/pages/Chat';
import Cropper from './components/pages/Cropper';
import Customerlist from './components/pages/Customerlist';
import Customerreview from './components/pages/Customerreview';
import Datatables from './components/pages/Datatables';
import Draggables from './components/pages/Draggables';
import Email from './components/pages/Email';
import Flaticons from './components/pages/Flaticons';
import Fontawesome from './components/pages/Fontawesome';
import Formelements from './components/pages/Formelements';
import Formlayouts from './components/pages/Formlayouts';
import Formvalidation from './components/pages/Formvalidation';

import Googlemaps from './components/pages/Googlemaps';
import Invoicedetail from './components/pages/Invoicedetail';
import Invoicelist from './components/pages/Invoicelist';
import Materialize from './components/pages/Materialize';
import Menucatalogue from './components/pages/Menucatalogue';
import Menugrid from './components/pages/Menugrid';
import Menulist from './components/pages/Menulist';
import Modals from './components/pages/Modals';
import Googlechart from './components/pages/Googlechart';
import Orders from './components/pages/Orders';
import Pagination from './components/pages/Pagination';
import Preloaders from './components/pages/Preloaders';
import Productdetail from './components/pages/Productdetail';
import Progressbars from './components/pages/Progressbars';
import Rangeslider from './components/pages/Rangeslider';
import Rating from './components/pages/Rating';
import Restaurantlist from './components/pages/Restaurantlist';
import Sales from './components/pages/Sales';
import Sliders from './components/pages/Sliders';
import Socialactivity from './components/pages/Socialactivity';
import Sweetalerts from './components/pages/Sweetalerts';

import Toast from './components/pages/Toast';
import Todolist from './components/pages/Todolist';
import Tour from './components/pages/Tour';
import Typography from './components/pages/Typography';
import Vectormaps from './components/pages/Vectormaps';
import Widgets from './components/pages/Widgets';
import Clientmanagement from './components/pages/Clientmanagement';
import Comingsoon from './components/pages/Comingsoon';

import Error from './components/pages/Error';
import Faq from './components/pages/Faq';
import Invoice from './components/pages/Invoice';
import Lockscreen from './components/pages/Lockscreen';
import Modallogin from './components/pages/Modallogin';
import Modalregister from './components/pages/Modalregister';
import Portfolio from './components/pages/Portfolio';
import Stockmanagement from './components/pages/Stockmanagement';
import Userprofile from './components/pages/Userprofile';
import Webanalytics from './components/pages/Webanalytics';


class App extends Component {
  constructor () {
    super()
    // if (!(window.location.pathname === "/Cook/" || window.location.pathname === "/Cook" || window.location.pathname === "/admin/" || window.location.pathname === "/admin")) {
    //   if (GetSessionData.UserId == null)
    //     (window.location.pathname.includes("cook")) ? window.location.href = "/Cook" : window.location.href = "/Admin";
    // }
  }

  componentDidMount() {

  }

  render() {
    return (
      <>
        <Router>
          <Preloader />
          <Switch>


            {/* ========================  Project Link  ========================================*/ }

            <Route path="/Cook/default-register" component={ Defaultregister } />
            <Route path="/Cook/CookOnBoarding" component={ OnBoardScreen } />

            <Route path='/Cook/EditDish/:id' component={ DishesForm } />
            <Route path='/Cook/AddDish' component={ DishesForm } />
            <Route path='/Cook/Specials' component={ DayWeekSpecialDishes } />

            <Route path='/Cook/Dishes' component={ Dishes } />
            <Route exact path="/Cook" component={ Defaultlogin } />

            <Route exact path="/Admin" component={ Defaultlogin } />
            <Route path='/Admin/CookProfile' component={ CookProfile } />
            <Route path='/Admin/FoodType' component={ FoodTypeMaster } />
            <Route path='/Admin/FoodCategory' component={ FoodCategoryMaster } />
            <Route path='/Admin/FoodMeal' component={ FoodMealMaster } />
            <Route path='/Admin/Cuisine' component={ CuisineMaster } />
            <Route path='/Admin/Allergens' component={ AllergensMaster } />
            <Route path='/Admin/Nutrition' component={ NutritionMaster } />


            <Route path="/Cook/Dashboard" component={ CookDashboard } />

            <Route path="/Admin/Dashboard" component={ AdminDashboard } />
            <Route path="/Admin/OrderDetails/:id" component={ OrderDetails } />
            <Route path="/Admin/AdminOrderList" component={ AdminOrderList } />
            <Route path="/Admin/AdminCookWiseSummary" component={ AdminCookWiseSummary } />

            <Route path="/Admin/Refund" component={ Refund }></Route>
            <Route path="/Admin/RefundHistory" component={ RefundHistory }></Route>

            <Route path="/Cook/OrderDetails/:id" component={ OrderDetails } />

            <Route path="/Cook/CookOrderList" component={ CookOrderList } />
            <Route path="/Cook/CookOrderDishWiseSelling" component={ CookOrderDishWiseSelling } />
            <Route path="/Cook/CookOrderDetails/:id" component={ CookOrderDetails } />

            <Route path="/Cook/CookProfile" component={ CookProfileTab } />
            <Route path="/VerifyUser/:id" component={ VerifyUser }></Route>

            <Route path="/Cook/ChangePassword" component={ ChangePassword }></Route>
            <Route path="/Admin/ChangePassword" component={ ChangePassword }></Route>


            { /* ========================  Project Link  ========================================*/ }

            <Route path="/accordions" component={ Accordions } />
            <Route path="/add-product" component={ Addproduct } />
            <Route path="/alerts" component={ Alerts } />
            <Route path="/animations" component={ Animations } />
            <Route path="/badges" component={ Badges } />
            <Route path="/basic-tables" component={ Basictables } />
            <Route path="/breadcrumbs" component={ Breadcrumbs } />
            <Route path="/buttons" component={ Buttons } />
            <Route path="/cards" component={ Cards } />
            <Route path="/chartjs" component={ Chartjs } />
            <Route path="/chat" component={ Chat } />
            <Route path="/cropper" component={ Cropper } />
            <Route path="/customer-list" component={ Customerlist } />
            <Route path="/customer-review" component={ Customerreview } />
            <Route path="/data-tables" component={ Datatables } />
            <Route path="/draggables" component={ Draggables } />
            <Route path="/email" component={ Email } />
            <Route path="/flaticons" component={ Flaticons } />
            <Route path="/fontawesome" component={ Fontawesome } />
            <Route path="/form-elements" component={ Formelements } />
            <Route path="/form-layouts" component={ Formlayouts } />
            <Route path="/form-validation" component={ Formvalidation } />

            <Route path="/google-maps" component={ Googlemaps } />
            <Route path="/invoice-detail" component={ Invoicedetail } />
            <Route path="/invoice-list" component={ Invoicelist } />
            <Route path="/materialize" component={ Materialize } />
            <Route path="/menu-catalogue" component={ Menucatalogue } />
            <Route path="/menu-grid" component={ Menugrid } />
            <Route path="/menu-list" component={ Menulist } />
            <Route path="/modals" component={ Modals } />
            <Route path="/google-chart" component={ Googlechart } />
            <Route path="/orders" component={ Orders } />
            <Route path="/pagination" component={ Pagination } />
            <Route path="/preloaders" component={ Preloaders } />
            <Route path="/product-detail" component={ Productdetail } />
            <Route path="/progress-bars" component={ Progressbars } />
            <Route path="/range-slider" component={ Rangeslider } />
            <Route path="/rating" component={ Rating } />
            <Route path="/restaurant-list" component={ Restaurantlist } />
            <Route path="/sales" component={ Sales } />
            <Route path="/sliders" component={ Sliders } />
            <Route path="/social-activity" component={ Socialactivity } />
            <Route path="/sweet-alerts" component={ Sweetalerts } />

            <Route path="/toast" component={ Toast } />
            <Route path="/todo-list" component={ Todolist } />
            <Route path="/tour" component={ Tour } />
            <Route path="/typography" component={ Typography } />
            <Route path="/vector-maps" component={ Vectormaps } />
            <Route path="/widgets" component={ Widgets } />
            <Route path="/client-management" component={ Clientmanagement } />
            <Route path="/coming-soon" component={ Comingsoon } />

            <Route path="/error" component={ Error } />
            <Route path="/faq" component={ Faq } />
            <Route path="/invoice" component={ Invoice } />
            <Route path="/lock-screen" component={ Lockscreen } />
            <Route path="/modal-login" component={ Modallogin } />
            <Route path="/modal-register" component={ Modalregister } />
            <Route path="/portfolio" component={ Portfolio } />
            <Route path="/stock-management" component={ Stockmanagement } />
            <Route path="/user-profile" component={ Userprofile } />
            <Route path="/web-analytics" component={ Webanalytics } />
          </Switch>
        </Router>
        {/* <LoadCommanJS></LoadCommanJS> */ }
      </>
    );
  }
}

export default App;
