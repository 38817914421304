import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ApiInstance from '../../../Utilities/axiosConfig';
import Config from '../../../Config';
import $ from 'jquery';



class Placedorder extends Component {

    constructor () {
        super();
        this.state = {
            PlacedorderOrderList: []
        }
    }

    componentDidMount() {
        this.GetOrderList();
        //  setInterval(this.GetOrderList, 15000);
    }

    GetOrderList = () => {
        var d = new Date();
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var dt = d.getFullYear() + '-' + months[d.getMonth()] + '-' + d.getDate();
        let obj = {
            StartDate: dt,
            EndDate: dt
        }

        console.log(obj)
        ApiInstance().post('Report/ReportCookOrderMasterByCookID', obj, { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                var data = response.data.data;
                if (data) {
                    let dataObject = JSON.parse(response.data.data);
                    console.log(dataObject)

                    let PlacedorderOrderList = (dataObject.Data.length > 0) ? dataObject.Data.filter(e => e.OrderStatusId != 1) : [];

                    this.setState({ PlacedorderOrderList: PlacedorderOrderList }, () => {
                        var objkeylist = dataObject.Columns.split(",").map(i => i.trim());
                        var columnlist = []
                        objkeylist.forEach(element => {
                            element = element.trim();
                            if (element !== 'OrderMasterId')
                                columnlist.push({ title: element.split(/(?=[A-Z])/).join(" "), data: element })
                        });

                        columnlist.push({
                            data: 'OrderMasterId',
                            title: 'Action',
                            render: function (td, data, type, row) {
                                return " ";
                            }

                        })

                        $('#Placed_Order_data-table').DataTable({

                            data: this.state.PlacedorderOrderList,
                            columns: columnlist,
                            "bDestroy": true,
                            columnDefs: [
                                {
                                    targets: objkeylist.length - 1,
                                    createdCell: (td, cellData, rowData, row, col) =>

                                        ReactDOM.render(<>
                                            <a href={ "/Cook/OrderDetails/" + rowData.OrderMasterId } className="btn btn-primary btn-sm">View Details</a>

                                        </>, td),
                                }
                            ],
                        });
                    });
                }
                else {
                }
            }, error => {
                console.log(error);
            });
    }

    render() {
        return (
            <div className="table-responsive">
                <table id="Placed_Order_data-table" className="table table-striped thead-primary w-100" />
            </div>
        );
    }
}

export default Placedorder;