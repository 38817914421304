import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server'
import ReactDOM from 'react-dom';
import "datatables.net-bs4/js/dataTables.bootstrap4"
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css"
import $ from 'jquery';
import ApiInstance from '../../../Utilities/axiosConfig';
import Swal from 'sweetalert2'
import Config from '../../../Config';

// verifiedcls


class NewCookProfileData extends Component {

    constructor () {
        super();

        this.state = {
            NewRegisteredData: []
        }

        this._sweetalerthtml_Verify = this.sweetalerthtml_Verify.bind(this);
        this._sweetalerthtml_Decline = this.sweetalerthtml_Decline.bind(this);


    }

    componentDidMount() {

        this.GetNewRegistrationList();

    }

    CookOperation(id, isapprove) {

        var dataobj = { "CookId": id, "IsApproved": isapprove };

        console.log(dataobj);
        ApiInstance().post("CookMaster/CookMasterIsActiveById", JSON.stringify(dataobj), { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                let data = response.data;
                console.log(response.data.status);
                if (response.data.status) {
                    Swal.fire(
                        'Success!',
                        'Cook has been ' + ((isapprove === 1) ? 'approved' : 'decline') + '  successfully.',
                        'success'
                    )
                    //$('#data-table-2').DataTable().clear().draw();
                    $('#NewCook_data-table-2').DataTable().destroy(false)
                    this.GetNewRegistrationList()
                }

            }, error => {
                console.log(error);
            });
        return false;
    }


    GetNewRegistrationList() {

        ApiInstance().get('CookMaster/GetCookMasterListByIsApproved?IsApproved=0')
            .then(response => {
                var data = JSON.parse(response.data.data);

                if (data) {
                    let dataObject = JSON.parse(response.data.data);
                    this.setState({ NewRegisteredData: dataObject.Data }, () => {

                        var objkeylist = dataObject.Columns.split(",").map(i => i.trim());

                        var columnlist = [];
                        objkeylist.forEach(element => {

                            if (element !== 'CookId')
                                columnlist.push({ title: element.split(/(?=[A-Z])/).join(" "), data: element })
                        });

                        columnlist.push({
                            data: 'CookId',
                            title: 'Action',
                            render: function (td, data, type, row) {
                                return " ";
                            }

                        })

                        console.log(objkeylist)

                        $('#NewCook_data-table-2').DataTable({

                            data: this.state.NewRegisteredData,
                            columns: columnlist,
                            "bDestroy": true,
                            columnDefs: [
                                {
                                    targets: objkeylist.indexOf("CookId"),
                                    createdCell: (td, cellData, rowData, row, col) =>

                                        ReactDOM.render(<>

                                            <a href="#"
                                                onClick={ () => this._sweetalerthtml_Verify(cellData) }>
                                                Verify
                                             </a>  | <a href="#"
                                                onClick={ () => this._sweetalerthtml_Decline(cellData) }>
                                                Decline
                                             </a> </>, td),
                                },
                                {
                                    targets: objkeylist.indexOf("InsuranceDocument"),
                                    createdCell: (td, cellData, rowData, row, col) =>

                                        ReactDOM.render(<>

                                            <a href={ Config.BaseURL + cellData } target="_blank"
                                            >
                                                view
                                            </a> </>, td),
                                }
                            ],


                        });

                    });
                }
                else {

                }
            }, error => {
                console.log(error);
            });

    }

    sweetalerthtml_Verify(data) {

        console.log(data)
        Swal.fire({

            text: "Are you sure to Verify this cook?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#ff5757',
            confirmButtonText: 'Yes!',
            showClass: {
                popup: 'animated fadeInDown faster',
                icon: 'animated heartBeat delay-1s'
            },
            hideClass: {
                popup: 'animated fadeOutUp faster',
            }

        }).then((result) => {
            if (result.isConfirmed) {
                this.CookOperation(data, 1)
                // if () {
                //     Swal.fire(
                //         'Success!',
                //         'Cook has been approved successfully.',
                //         'success'
                //     )
                // }
            }
        })

    }

    sweetalerthtml_Decline(data) {
        console.log(data);
        Swal.fire({
            text: "Are sure to decline this cook?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!',
            showClass: {
                popup: 'animated fadeInDown faster',
                icon: 'animated heartBeat delay-1s'
            },
            hideClass: {
                popup: 'animated fadeOutUp faster',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                this.CookOperation(data, 2)
            }
        })

    }

    render() {
        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <div className="ms-panel">
                            <div className="ms-panel-header">
                                <h6></h6>
                            </div>
                            <div className="ms-panel-body">
                                <div className="table-responsive">
                                    <table id="NewCook_data-table-2" className="table table-striped thead-primary w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default NewCookProfileData;