import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Quickbar from '../../../components/layouts/Quickbar';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../../components/layouts/Topnavigation';
import ApiInstance from '../../../Utilities/axiosConfig';
import Swal from 'sweetalert2';

import { GetSessionData } from '../../../GlobalFactory';
import Config from '../../../Config';
export default class CookOrderDetails extends Component {

    constructor (props) {
        super(props)
        this.id = this.props.match.params.id;

        this.state = {
            OrderData: {},
            tblOrderChild: [],
            OrderStatus: ''
        }
    }

    GetOrderDetails() {
        ApiInstance().post('Report/ReportCookOrderMasterDetailsByOrderMasterId', this.id, { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                var data = response.data.data;
                console.log(response.data)
                if (data) {
                    var objdata = JSON.parse(data)
                    console.log(objdata.Data)
                    let tblOrderChild = JSON.parse(objdata.Data[0].tblOrderChild);
                    console.log(tblOrderChild)
                    this.setState({
                        OrderData: objdata.Data[0]
                        , tblOrderChild: tblOrderChild
                    });
                }
            }, error => {
                console.log(error);
            });
    }
    componentDidMount() {
        this.GetOrderDetails();
    }

    ChangeOrderStatus(ChangeOrderStatus) {


        var thisobj = this;
        Swal.fire({
            title: 'Are you sure?',
            text: "you want to change this order status!",
            icon: 'info',
            showClass: {
                popup: 'animated fadeInDown faster',
                icon: 'animated heartBeat delay-1s'
            },
            hideClass: {
                popup: 'animated fadeOutUp faster',
            },
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!'
        }).then(function (result) {
            if (result.value) {

                let obj = {
                    "OrderMasterId": thisobj.state.OrderData.OrderMasterId,
                    "OrderStatusId": ChangeOrderStatus
                }
                console.log(obj)
                ApiInstance().post('OrderMaster/UpdateOrderStatusByOrderMasterId', obj, { headers: { 'Content-Type': 'application/json' } })
                    .then(response => {
                        var data = response.data;
                        if (data.status) {
                            console.log("reloadcall");
                            window.location.reload();
                        }
                    }, error => {
                        console.log(error);
                    });



            }
        });




    }

    render() {
        console.log(GetSessionData().UserType)

        return (
            <>
                <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                    <Sidenavigation />
                    <main className="body-content">
                        <Topnavigation />
                        <div className="ms-content-wrapper">
                            <div className="row">
                                <div className="col-md-12">

                                    <div className="ms-panel">
                                        <div className="ms-panel-header header-mini">
                                            <div className="d-flex justify-content-between">
                                                <h6>Order Details</h6>
                                                <h6>#{ this.state.OrderData.OrderMasterId }</h6>
                                            </div>
                                        </div>
                                        <div className="ms-panel-body">
                                            {/* Invoice To */ }
                                            <div className="row align-items-center">
                                                <div className="col-md-6">
                                                    <div className="invoice-address">
                                                        <h3>Reciever </h3>
                                                        <h5>{ this.state.OrderData.Customer_FirstName + " " + this.state.OrderData.Customer_LastName }</h5>
                                                        <p>Email: { this.state.OrderData.Customer_EmailId }</p>
                                                        <p className="mb-0">Mobile No: { this.state.OrderData.Customer_MobileNo }</p>
                                                        <p className="mb-0"></p>
                                                        <p></p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 text-md-right">
                                                    <ul className="invoice-date">
                                                        <li>Order Date : { this.state.OrderData.OrderInsertDateTime }</li>
                                                        <li>OrderStatus: { this.state.OrderData.OrderStatus } </li>
                                                        <li>Payment Status: { this.state.OrderData.PaymentStatus } </li>
                                                        <li>Transaction Number: { this.state.OrderData.TransactionNumber } </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* Invoice Table */ }
                                            <div className="ms-invoice-table table-responsive mt-5">
                                                <table className="table table-hover text-right thead-light">
                                                    <thead>
                                                        <tr className="text-capitalize">
                                                            <th className="text-center w-5">id</th>
                                                            <th className="text-left">description</th>
                                                            <th>qty</th>
                                                            <th>Unit Cost</th>
                                                            <th>Discount</th>
                                                            <th>Packing Charges</th>
                                                            <th>total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { this.state.tblOrderChild.map((item, index) =>
                                                            <tr key={ index }>
                                                                <td className="text-center">{ index + 1 }</td>
                                                                <td className="text-left">
                                                                    <img src={ (item.Photo) ? (Config.BaseURL + item.Photo) : "../assets/img/costic/customer-6.jpg" } style={ { maxHeight: "50px", maxWidth: "50px" } }></img>
                                                                    { item.DishName + " " }  <span style={ { fontSize: "12px" } }>({ item.VariantName })</span>
                                                                    <br /><span>{ }</span>
                                                                </td>
                                                                <td>{ item.BuyQuantity }</td>
                                                                <td>${ item.Price }</td>
                                                                <td>${ item.Discount }</td>
                                                                <td>${ item.PackingCharges }</td>
                                                                <td>${ item.FinalPrice }</td>
                                                            </tr>
                                                        ) }


                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colSpan={ 2 } style={ { textAlign: "right" } }>Total Qty</td>
                                                            <td>{ this.state.OrderData.TotalQty }</td>
                                                            <td colSpan={ 3 }>Total Cost:</td>
                                                            <td>${ this.state.OrderData.TotalAmount }</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={ 6 }>Tip:</td>
                                                            <td>${ this.state.OrderData.Tip }</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={ 6 }>Processing Charge:</td>
                                                            <td>${ this.state.OrderData.ProcessingCharge }</td>
                                                        </tr>

                                                        <tr>
                                                            <td colSpan={ 6 }>Grand Total:</td>
                                                            <td>${ this.state.OrderData.Grandtotal }</td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-md-6">
                                                    <div className="invoice-address">
                                                        <h5>Additional notes: </h5>
                                                        <p>{ this.state.OrderData.ExtraNotes }</p>
                                                        <p></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="invoice-buttons text-right">
                                                <Link to={ "/" + GetSessionData().UserType + "/CookOrderList" } className="btn btn-primary mr-2" >Back</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </main>
                    <Quickbar />
                </div>
            </>
        )
    }
}

