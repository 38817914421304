import React, { Component } from 'react';
import ApiInstance from '../../Utilities/axiosConfig';
import Swal from 'sweetalert2'
import LoaderComponent from '../../components/LoaderComponent';
import Sidenavigation from '../../components/layouts/Sidenavigation';
import Topnavigation from '../../components/layouts/Topnavigation';
import { GetSessionData } from '../../GlobalFactory';
export default class ChangePassword extends Component {
    constructor () {
        super();

        this.state = {
            RegistrationData: {},
            ActiveLoader: false
        }


        this._handleChangeEvent = this.ItemChangeEvent.bind(this);

        this.sweetalerthtml = this.sweetalerthtml.bind(this);
        this._handleSubmitEvent = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        //initialize datatable
        function formValidation() {
            window.addEventListener('load', function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function (form) {
                    form.addEventListener('submit', function (event) {


                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add('was-validated');
                        }
                        else {
                            form.classList.remove('was-validated');
                        }
                    }, false);
                });

            }, false);
        }
        formValidation();

    }

    ItemChangeEvent = event => {

        const value = event.target.value;
        const key = event.target.name;

        this.validatePassword(key);
        let RegistrationData = this.state.RegistrationData;
        RegistrationData[key] = value;

        this.setState({
            RegistrationData: RegistrationData
        });


    }

    resetFormData = () => {
        const clonedState = this.state.RegistrationData;
        const keys = Object.keys(clonedState);
        keys.forEach(key => (delete clonedState[key]));

        this.setState({
            RegistrationData: {},
        });

        var forms = document.getElementsByClassName('needs-validation');
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.classList.remove('was-validated');
        }, false);

    }

    sweetalerthtml() {
        Swal.fire({
            title: '<spam>Your password has been change successfully !</spam>',
            icon: '',
            html: '',
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            cancelButtonText: 'Close',
            cancelButtonAriaLabel: 'Thumbs down'
        }).then(function (result) {
            if (result.value) {
                window.location.reload();
                //this.resetFormData();
            }
        });

    }

    validatePassword(pname) {

        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,20})");
        var txtPassword = document.getElementById(pname);
        if (!strongRegex.test(txtPassword.value)) {
            document.getElementById(pname + "_ValidatePassword").innerHTML = "Password must be contain min 6 and max 20 character with the combination of uppercase ,lower case, numeric and special char";
            return false;
        }
        else if (pname === "CPassword") {
            var pwd = document.getElementById("NewPassword").value;
            var cpwd = document.getElementById("CPassword").value;

            console.log(pwd)
            console.log(cpwd)

            if (pwd !== cpwd) {

                document.getElementById(pname + "_ValidatePassword").innerHTML = "Confirm password is not match with new password!";
                return false;
            }
            else {
                document.getElementById(pname + "_ValidatePassword").innerHTML = "";
                return true;
            }
        }
        else {
            document.getElementById(pname + "_ValidatePassword").innerHTML = "";
            return true;
        }

    }


    handleSubmit = event => {
        event.preventDefault()

        if (event.target.checkValidity()) {
            this.setState({ ActiveLoader: true })
            var objsessiondata = GetSessionData();
            let url = (objsessiondata.UserType.toLowerCase() === 'cook') ? "CookMaster/UpdatetblCookMasterPassword" : "AdminLogin/UpdatetblCustomerMasterPassword";

            ApiInstance().post(url, this.state.RegistrationData, { headers: { 'Content-Type': 'application/json', } })
                .then(response => {
                    let data = response.data;
                    console.log(data);
                    if (data.status) {
                        this.sweetalerthtml();
                    }
                    else {
                        document.getElementById("OldPassword_ValidatePassword").innerText = data.data;
                    }

                    this.setState({ ActiveLoader: false })

                }, error => {
                    //window.init_PNotify("Error", "error", "Record not updated!!");
                    this.setState({ ActiveLoader: false })
                    console.log(error);
                });



        }
        else {
            console.log(event.target.checkValidity())
        }

    }



    onKeyPress(event) {
        return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122));
    }
    render() {
        var objsessiondata = GetSessionData();
        return (
            <>
                <LoaderComponent ActiveLoader={ this.state.ActiveLoader }></LoaderComponent>
                <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                    <Sidenavigation />
                    <main className="body-content">
                        <Topnavigation />
                        <div className="ms-content-wrapper">
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="ms-panel ms-panel-fh">
                                        <div className="ms-panel-header">
                                            <h6>Change Password</h6>
                                        </div>
                                        <div className="ms-panel-body">
                                            <form className="needs-validation" noValidate onSubmit={ this._handleSubmitEvent } id="registrationform">

                                                <div className="row">
                                                    <div className="col-md-4">&nbsp;</div>
                                                    <div className="col-md-4">
                                                        <div className="form-row">
                                                            <div className="col-md-12 ">
                                                                <label htmlFor="Password">Old Password <span className="required">*</span></label>
                                                                <div className="input-group">
                                                                    <input type="password" minLength="6" maxLength="20" className="form-control" id="OldPassword" name='OldPassword' value={ this.state.RegistrationData.OldPassword || '' } placeholder="*******" required onChange={ this._handleChangeEvent } onBlur={ () => this.validatePassword("OldPassword") } />
                                                                    <div className="invalid-feedback ">
                                                                        Please enter old password.
                                                                     </div>
                                                                    <div className="invalid-feedback d-block" id="OldPassword_ValidatePassword"> </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="col-md-12 ">
                                                                <label htmlFor="NewPassword">New Password <span className="required">*</span></label>
                                                                <div className="input-group">
                                                                    <input type="password" minLength="6" maxLength="20" className="form-control" id="NewPassword" name='NewPassword' value={ this.state.RegistrationData.NewPassword || '' } placeholder="*******" required onChange={ this._handleChangeEvent } onBlur={ () => this.validatePassword("NewPassword") } />
                                                                    <div className="invalid-feedback ">
                                                                        Please enter new password.
                                                                     </div>
                                                                    <div className="invalid-feedback d-block" id="NewPassword_ValidatePassword"> </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="col-md-12">
                                                                <label htmlFor="Password">Confirm Password <span className="required">*</span></label>
                                                                <div className="input-group">
                                                                    <input type="password" minLength="6" maxLength="20" className="form-control" id="CPassword" name='CPassword' value={ this.state.RegistrationData.CPassword || '' } placeholder="*******" required onChange={ this._handleChangeEvent } onBlur={ () => this.validatePassword("CPassword") } />
                                                                    <div className="invalid-feedback ">
                                                                        Please enter confirm password.
                                                                    </div>
                                                                    <div className="invalid-feedback d-block" id="CPassword_ValidatePassword"> </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="col-md-12 text-center">
                                                                <button className="btn btn-primary float-right" type="submit" >Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">&nbsp;</div>
                                                </div>






                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>


            </>
        );
    }
}
