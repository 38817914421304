import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../../components/layouts/Topnavigation';
import Quickbar from '../../../components/layouts/Quickbar';
import Breadcrumb from '../Breadcrumb';
import "datatables.net-bs4/js/dataTables.bootstrap4"
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css"
import $ from 'jquery';
import ApiInstance from '../../../Utilities/axiosConfig'
import { ToastMessage } from '../../../Utilities/ToastMessage'
import Swal from 'sweetalert2'
import { GetSessionData } from '../../../GlobalFactory';
// Dataset 

class DayWeekSpecialDishes extends Component {
    constructor () {
        super();
        this.state = {
            DishList: [],
            DishId: '',
            SpecialType: ''


        }
        this._handleChangeEvent = this.ItemChangeEvent.bind(this);
        this._handleSubmitEvent = this.handleSubmit.bind(this);
        this._handleDeleteEvent = this.handleDeleteEvent.bind(this);
    }

    componentDidMount() {
        function formValidation() {
            window.addEventListener('load', function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function (form) {
                    form.addEventListener('submit', function (event) {
                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add('was-validated');
                        }
                        else {
                            form.classList.remove('was-validated');
                        }
                    }, false);
                });
            }, false);
        }
        formValidation();
        this.GetDishList();
        this.GetSpecialsList();
    }

    GetDishList() {

        ApiInstance().get('DishMaster/SelectAlltblDishMasterByCookId?CookId=' + GetSessionData().UserId)
            .then(response => {

                var data = response.data.data;


                if (data) {

                    let dataObject = JSON.parse(response.data.data);
                    console.log(dataObject)
                    this.setState({ DishList: dataObject.Data });

                }
                else {
                }
            }, error => {
                console.log(error);
            });
    }

    ItemChangeEvent = event => {

        const value = event.target.value;
        const key = event.target.name;
        this.setState({
            [key]: value
        });

    }

    ResetData() {
        this.setState({ DishId: '', SpecialType: '' })
        var forms = document.getElementsByClassName('needs-validation');
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.classList.remove('was-validated');
        }, false);

    }

    handleSubmit = event => {
        //  TodaysSpecial / InsertUpdatetblTodaysSpecial
        // WeekSpecial/InsertUpdatetblWeekSpecial
        // "{ CookId: 6,DishId: 7 }"

        event.preventDefault()

        if (event.target.checkValidity()) {
            var url = '';
            var formdata = {};

            if (this.state.SpecialType == 'TodaysSpecial') {
                url = 'TodaysSpecial/InsertUpdatetblTodaysSpecial';

            }
            else {
                url = 'WeekSpecial/InsertUpdatetblWeekSpecial';
            }

            formdata = {
                "CookId": GetSessionData().UserId,
                'DishId': this.state.DishId
            }
            console.log(formdata)
            ApiInstance().post(url, formdata, { headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    let data = response.data;

                    if (data.status) {
                        ToastMessage('Success', 'Record has been added successfully.', 'success')
                        this.ResetData()
                        this.GetSpecialsList()

                    }
                    else {
                        ToastMessage('Fail!', data.data, 'danger')
                    }

                }, error => {
                    console.log(error);
                });

        }
    }

    handleDeleteEvent = (id) => {

        var thisobj = this;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showClass: {
                popup: 'animated fadeInDown faster',
                icon: 'animated heartBeat delay-1s'
            },
            hideClass: {
                popup: 'animated fadeOutUp faster',
            },
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(function (result) {
            if (result.value) {

                let formdata = {
                    'AllergensId': id
                }

                ApiInstance().post('AllergensMaster/DeletetblAllergensMaster', formdata, { headers: { 'Content-Type': 'application/json' } })
                    .then(response => {
                        let data = response.data;
                        if (data.status) {
                            ToastMessage('Success', 'Record has been deleted successfully.', 'success')
                            thisobj.GetAllergensList();
                        }
                        else {
                            ToastMessage('Fail!', data.data, 'danger')
                        }

                    }, error => {
                        console.log(error);
                    });

            }
        });



    }

    GetSpecialsList() {

        ApiInstance().get(`TodaysSpecial/SelectTodayWeekSpecialByCookId?CookId=` + GetSessionData().UserId)
            .then(response => {

                var data = response.data.data;
                if (data) {
                    console.log(data)

                    let dataObject = JSON.parse(response.data.data);

                    this.setState({ CuisineList: dataObject.Data }, () => {

                        var objkeylist = dataObject.Columns.split(",").map(i => i.trim());

                        var columnlist = []

                        objkeylist.forEach(element => {
                            element = element.trim();

                            columnlist.push({ title: element.split(/(?=[A-Z])/).join(" "), data: element })
                        });

                        // columnlist.push({
                        //     data: 'CuisineId',
                        //     title: 'Action',
                        //     render: function (td, data, type, row) {
                        //         return " ";
                        //     }

                        // })

                        $('#Special_data-table').DataTable({

                            data: this.state.CuisineList,
                            columns: columnlist,
                            "bDestroy": true
                        });
                    });
                }
                else {
                }
            }, error => {
                console.log(error);
            });
    }

    render() {
        return (
            <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                <Sidenavigation />
                <main className="body-content">
                    <Topnavigation />
                    <div className="ms-content-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <Breadcrumb FormName="Special dishes" />
                            </div>
                            <div className="col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-header">
                                        <h6>Add New special dish</h6>
                                    </div>
                                    <div className="ms-panel-body">
                                        <form className="needs-validation" noValidate onSubmit={ this._handleSubmitEvent } >
                                            <div className="form-row align-items-center">
                                                <div className="col-md-3 mb-3">
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="DishName">Today Special/Week Special</label>
                                                    <div className="input-group">
                                                        <select onChange={ this._handleChangeEvent } className="form-control" id="SpecialType" name="SpecialType" required value={ this.state.SpecialType || '' } >
                                                            <option></option>
                                                            <option value='TodaysSpecial'>Todays Special</option>
                                                            <option value='WeekSpecial'>Week Special</option>
                                                        </select>
                                                        <div className="invalid-feedback">
                                                            Please select day/week
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="DishName">Dish Name</label>
                                                    <div className="input-group">
                                                        <select onChange={ this._handleChangeEvent } className="form-control" id="DishId" name="DishId" required value={ this.state.DishId || '' } >
                                                            <option></option>
                                                            {
                                                                this.state.DishList.map((element, index) => <option key={ index } value={ element.DishId }>{ element.DishName }</option>)
                                                            }
                                                        </select>
                                                        <div className="invalid-feedback">
                                                            Please select dish
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <button className="btn btn-primary" type="submit">{ (this.state.IsEditState) ? 'Edit' : 'Add' }</button>
                                                    &nbsp;&nbsp; <button className="btn btn-primary" type="button" onClick={ () => { this.ResetData() } }>Reset</button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-header">
                                        <h6></h6>
                                    </div>
                                    <div className="ms-panel-body">
                                        <table id="Special_data-table" className="table table-striped thead-primary w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Quickbar />
            </div>
        );
    }
}

export default DayWeekSpecialDishes;