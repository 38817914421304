import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../../components/layouts/Topnavigation';
import Quickbar from '../../../components/layouts/Quickbar';
import Breadcrumb from '../Breadcrumb';
import "datatables.net-bs4/js/dataTables.bootstrap4"
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css"
import $ from 'jquery';
import ApiInstance from '../../../Utilities/axiosConfig'
import { ToastMessage } from '../../../Utilities/ToastMessage'
import Swal from 'sweetalert2'

// Dataset 

class FoodTypeMaster extends Component {
    constructor () {
        super();
        this.state = {
            FoodTypeList: [],
            FoodTypeName: '',
            IsEditState: false,
            FoodTypeId: ''
        }
        this._handleChangeEvent = this.ItemChangeEvent.bind(this);
        this._handleSubmitEvent = this.handleSubmit.bind(this);
        this._handleDeleteEvent = this.handleDeleteEvent.bind(this);
    }

    componentDidMount() {
        function formValidation() {
            window.addEventListener('load', function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function (form) {
                    form.addEventListener('submit', function (event) {
                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add('was-validated');
                        }
                        else {
                            form.classList.remove('was-validated');
                        }
                    }, false);
                });
            }, false);
        }
        formValidation();
        this.GetFoodTypeList();
    }

    GetFoodTypeList() {

        ApiInstance().get('FoodTypeMaster/SelectAlltblFoodTypeMaster')
            .then(response => {

                var data = response.data.data;


                if (data) {

                    let dataObject = JSON.parse(response.data.data);

                    this.setState({ FoodTypeList: dataObject.Data }, () => {

                        var objkeylist = dataObject.Columns.split(",").map(i => i.trim());

                        var columnlist = []

                        objkeylist.forEach(element => {
                            element = element.trim();
                            if (element !== 'FoodTypeId')
                                columnlist.push({ title: element.split(/(?=[A-Z])/).join(" "), data: element })
                        });

                        columnlist.push({
                            data: 'FoodTypeId',
                            title: 'Action',
                            render: function (td, data, type, row) {
                                return " ";
                            }

                        })

                        $('#FoodType_data-table').DataTable({

                            data: this.state.FoodTypeList,
                            columns: columnlist,
                            "bDestroy": true,
                            columnDefs: [
                                {
                                    targets: objkeylist.length - 1,
                                    createdCell: (td, cellData, rowData, row, col) =>

                                        ReactDOM.render(<>
                                            <a href='#'><i className='fas fa-pencil-alt text-secondary' onClick={ () => { this.setState({ FoodTypeName: rowData.FoodTypeName, FoodTypeId: cellData, IsEditState: true }) } }></i></a>
                                            <a href='#' onClick={ () => this._handleDeleteEvent(cellData) }><i className='far fa-trash-alt ms-text-danger'></i></a>
                                        </>, td),
                                }
                            ],
                        });
                    });
                }
                else {
                }
            }, error => {
                console.log(error);
            });
    }

    ItemChangeEvent = event => {

        const value = event.target.value;
        const key = event.target.name;
        if (value.length === 0 || (value.length > 0 && value.trim().length)) {
            this.setState({
                FoodTypeName: value
            });
        }

    }

    ResetData() {
        this.setState({ FoodTypeName: '', FoodTypeId: '', IsEditState: false })
        var forms = document.getElementsByClassName('needs-validation');
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.classList.remove('was-validated');
        }, false);

    }

    handleSubmit = event => {

        event.preventDefault()

        if (event.target.checkValidity()) {
            var url = '';
            var formdata = {};
            if (this.state.IsEditState) {
                url = 'FoodTypeMaster/UpdatetblFoodTypeMaster';
                formdata = {
                    "FoodTypeId": this.state.FoodTypeId,
                    'FoodTypeName': this.state.FoodTypeName
                }
            }
            else {
                url = "FoodTypeMaster/InsertFoodTypeMaster";
                formdata = {
                    'FoodTypeName': this.state.FoodTypeName
                }
            }

            ApiInstance().post(url, formdata, { headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    let data = response.data;

                    if (data.status) {
                        ToastMessage('Success', 'Record has been ' + ((this.state.IsEditState == true) ? 'updated' : 'successfully') + ' successfully.', 'success')
                        this.ResetData()
                        this.GetFoodTypeList();
                    }
                    else {
                        ToastMessage('Fail!', data.data, 'danger')
                    }

                }, error => {
                    console.log(error);
                });
        }
        // else {
        //     console.log(event.target.checkValidity())
        // }
    }

    handleDeleteEvent = (id) => {

        var thisobj = this;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showClass: {
                popup: 'animated fadeInDown faster',
                icon: 'animated heartBeat delay-1s'
            },
            hideClass: {
                popup: 'animated fadeOutUp faster',
            },
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(function (result) {
            if (result.value) {

                let formdata = {
                    'FoodTypeId': id
                }

                ApiInstance().post('FoodTypeMaster/DeletetblFoodTypeMasterByFoodTypeId', formdata, { headers: { 'Content-Type': 'application/json' } })
                    .then(response => {
                        let data = response.data;
                        if (data.status) {
                            ToastMessage('Success', 'Record has been deleted successfully.', 'success')
                            thisobj.GetFoodTypeList();
                        }
                        else {
                            ToastMessage('Fail!', data.data, 'danger')
                        }

                    }, error => {
                        console.log(error);
                    });

            }
        });



    }

    render() {
        return (
            <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                <Sidenavigation />
                <main className="body-content">
                    <Topnavigation />
                    <div className="ms-content-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <Breadcrumb FormName="Add Food Type" />
                            </div>
                            <div className="col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-header">
                                        <h6>Add New Food Type</h6>
                                    </div>
                                    <div className="ms-panel-body">
                                        <form className="needs-validation" noValidate onSubmit={ this._handleSubmitEvent } >
                                            <div className="form-row">
                                                <div className="col-md-3 mb-3">
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <br></br>
                                                    <div className="input-group">
                                                        <input type="text" value={ this.state.FoodTypeName || '' } onChange={ this._handleChangeEvent } className="form-control" id="validationCustom11" placeholder="Food Type" required />
                                                        <div className="invalid-feedback">
                                                            Food type is required.
                                                        </div>
                                                        <div className="valid-feedback">Looks good!</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <button className="btn btn-primary" type="submit">{ (this.state.IsEditState) ? 'Edit' : 'Add' }</button>
                                                    &nbsp;&nbsp; <button className="btn btn-primary" type="button" onClick={ () => { this.ResetData() } }>Reset</button>
                                                </div>
                                                <div className="col-md-3 mb-3">

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-header">
                                        <h6>Food Type List</h6>
                                    </div>
                                    <div className="ms-panel-body">
                                        <table id="FoodType_data-table" className="table table-striped thead-primary w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Quickbar />
            </div>
        );
    }
}

export default FoodTypeMaster;