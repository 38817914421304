import React, { Component } from 'react';


const recentordertable = [
    {
        orderid: "1",
        ordername: "French Fries",
        customername: "Jhon Leo",
        location: "New Town",
        orderstatus: "Pending",
        orderstatuscls: "badge badge-primary",
        deliverytime: "10:05",
        price: "$10"
    },
    {
        orderid: "2",
        ordername: "Mango Pie",
        customername: "Kristien",
        location: "Old Town",
        orderstatus: "Cancelled",
        orderstatuscls: "badge badge-dark",
        deliverytime: "14:05",
        price: "$9"
    },
    {
        orderid: "3",
        ordername: "Fried Egg Sandwich",
        customername: "Jack Suit",
        location: "Oxford Street",
        orderstatus: "Delivered",
        orderstatuscls: "badge badge-success",
        deliverytime: "12:05",
        price: "$19"
    },
    {
        orderid: "4",
        ordername: "Lemon Yogurt Parfait",
        customername: "Alesdro Guitto",
        location: "Church hill",
        orderstatus: "Delivered",
        orderstatuscls: "badge badge-success",
        deliverytime: "12:05",
        price: "$18"
    },
    {
        orderid: "5",
        ordername: "Spicy Grill Sandwich",
        customername: "Jacob Sahwny",
        location: "Palace Road",
        orderstatus: "Delivered",
        orderstatuscls: "badge badge-success",
        deliverytime: "12:05",
        price: "$21"
    },
    {
        orderid: "6",
        ordername: "Chicken Sandwich",
        customername: "Peter Gill",
        location: "Street 21",
        orderstatus: "Pending",
        orderstatuscls: "badge badge-primary",
        deliverytime: "12:05",
        price: "$15"
    },
]


class Recentorder extends Component {
    constructor (props) {
        super(props);

        this.state = {
            RecentPlacedOrders: []
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.RecentPlacedOrders !== this.state.RecentPlacedOrders) {
            this.setState({ RecentPlacedOrders: nextProps.RecentPlacedOrders })
        }
    }

    GetClassName(type) {
        var clsname = "";

        if (type.toLowerCase() === "pending") clsname = "badge badge-primary";
        else if (type.toLowerCase() === "cancelled") clsname = "badge badge-dark";
        else if (type.toLowerCase() === "delivered") clsname = "badge badge-success";
        else clsname = ""

        return clsname;

    }

    render() {
        return (
            <div className="table-responsive">
                <table className="table table-hover thead-primary">
                    <thead>
                        <tr>
                            <th scope="col">Order ID</th>
                            <th scope="col">Order Name</th>
                            <th scope="col">Customer Name</th>

                            <th scope="col">Order Status</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.RecentPlacedOrders.map((item, i) => (
                            <tr key={ i }>
                                <th scope="row">#{ item.OrderMasterId }</th>
                                <td>{ item.CookBussinessName }</td>
                                <td>{ item.CustomerName }</td>

                                <td><span className={ this.GetClassName(item.OrderStatus) }>{ item.OrderStatus }</span>
                                </td>
                                <td>{ item.TotalQty }</td>
                                <td>${ item.Grandtotal }</td>
                            </tr>
                        )) }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Recentorder;