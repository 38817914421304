import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Accordion, Card } from "react-bootstrap";
import Config from '../../Config';
import Swal from 'sweetalert2';
import ApiInstance from '../../Utilities/axiosConfig';

class LoginContent extends Component {

    constructor (props, context) {
        super(props, context);
        this.handleShow1 = this.handleShow1.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            show: false,
            UserName: "",
            Password: "",
            error_description: "",
            isChecked: false
        };
        localStorage.removeItem("tokenData");




        this._handleSubmitEvent = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        //initialize datatable
        function formValidation() {
            window.addEventListener('load', function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');

                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function (form) {
                    form.addEventListener('submit', function (event) {
                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add('was-validated');
                        }
                        else {
                            form.classList.remove('was-validated');
                        }
                    }, false);
                });

            }, false);


        }
        formValidation();

        let savedLoginData = JSON.parse(localStorage.getItem("savedLoginData"));
        if (savedLoginData) {
            if (savedLoginData.isChecked) {
                this.setState({ UserName: savedLoginData.UserName, Password: savedLoginData.Password, isChecked: savedLoginData.isChecked })
                document.getElementById("validationCustom08").value = savedLoginData.UserName;
                document.getElementById("validationCustom09").value = savedLoginData.Password;
            }
            else {
                localStorage.removeItem("savedLoginData");
            }
        }

    }

    handleShow1() {
        this.setState({ show1: true });
    }
    handleClose() {
        this.setState({ show1: false });
    }

    handleUserName = event => {
        this.setState({
            UserName: event.target.value
        })

    }

    handlePassword = event => {
        this.setState({
            Password: event.target.value
        })
    }
    onChangeCheckbox = event => {
        this.setState({
            isChecked: event.target.checked
        })
    }

    handleSubmit = event => {

        event.preventDefault()
        if (event.target.checkValidity()) {

            let currentcomponent = this;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();
            urlencoded.append("grant_type", "password");
            urlencoded.append("username", this.state.UserName);
            urlencoded.append("password", this.state.Password);
            urlencoded.append("Scope", (window.location.pathname.replaceAll('/', '')));

            console.log(window.location.pathname)

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };


            fetch(Config.apiTokenURL, requestOptions)
                .then(response => response.text())
                .then(result => {

                    var sessionobj = JSON.parse(result);
                    if (sessionobj["error_description"]) {
                        this.setState({ error_description: sessionobj["error_description"] })
                    }
                    else {

                        var savedLoginData = {
                            UserName: this.state.UserName,
                            Password: this.state.Password,
                            isChecked: this.state.isChecked
                        }


                        localStorage.setItem("savedLoginData", JSON.stringify(savedLoginData));

                        localStorage.setItem("tokenData", JSON.stringify(sessionobj))

                        console.log(sessionobj)
                        window.location.href = sessionobj.RedirectURL;


                    }
                }
                ).catch(error => console.log('error', error));

        }

    }
    ResetPassword() {
        Swal.fire({
            title: 'Please enter email id',
            input: 'email',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Submit',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                ApiInstance().get('CustomerMaster/CustomerforgotPassword?UserName=' + login)
                    .then(response => {
                        console.log(response)
                        if (response.data.status) {
                            Swal.fire('Reset password link has been sent to your register email id!')
                        }
                        else {
                            Swal.fire(response.data.data)
                        }
                        // if (!response.ok) {
                        //     throw new Error(response.statusText)
                        // }
                        return response.data
                    })
                    .catch(error => {
                        console.log(error)
                        Swal.showValidationMessage(
                            `Request failed: ${error}`
                        )
                    });
                // return fetch(`https://localhost:44328/api/CustomerMaster/CustomerforgotPassword/${login}`)
                //     .then(response => {
                //         if (!response.ok) {
                //             throw new Error(response.statusText)
                //         }
                //         return response.json()
                //     })
                //     .catch(error => {
                //         console.log(error)
                //         Swal.showValidationMessage(
                //             `Request failed: ${error}`
                //         )
                //     })

            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            console.log(result)
            // if (result.isConfirmed) {
            //     Swal.fire('Reset password link has been sent to your register email id!')
            // }
            // else {
            //     Swal.fire("Invalid email id!")
            // }
        })
    }
    render() {
        return (
            <div className="ms-body ms-primary-theme ms-logged-out">
                <main className="body-content">
                    <div className="ms-content-wrapper ms-auth">
                        <div className="ms-auth-container">
                            <div className="ms-auth-col">
                                <div className="ms-auth-bg" />
                            </div>
                            <div className="ms-auth-col">
                                <div className="ms-auth-form">
                                    <form className="needs-validation" noValidate onSubmit={ this._handleSubmitEvent }>
                                        <h3>Login to Account</h3>

                                        <p></p>
                                        { this.state.error_description && <p className="required" >{ this.state.error_description }</p> }
                                        <div className="mb-3">
                                            <label htmlFor="validationCustom08">Email / Mobile / UserName <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="validationCustom08" placeholder="Email /Mobile /UserName" required onChange={ this.handleUserName } />
                                                <div className="invalid-feedback">Please enter field.</div>
                                            </div>
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="validationCustom09">Password <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="password" className="form-control" id="validationCustom09" placeholder="Password" required onChange={ this.handlePassword } />
                                                <div className="invalid-feedback">Please enter password.</div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="ms-checkbox-wrap">
                                                <input className="form-check-input" type="checkbox" defaultValue onChange={ this.onChangeCheckbox } checked={ this.state.isChecked } /> <i className="ms-checkbox-check" />
                                            </label> <span> Remember Password </span>

                                            <label className="d-block mt-3"><Link to={ (window.location.pathname.toLowerCase().includes('cook')) ? "/Cook" : "/Admin" } className="btn-link" onClick={ () => { this.ResetPassword() } }>Reset Password?</Link>
                                            </label>

                                        </div>

                                        <button className="btn btn-primary mt-4 d-block w-100" type="submit" >Sign In</button>
                                        { (window.location.pathname.toLowerCase().includes('cook')) && <p className="mb-0 mt-3 text-center">Don't have an account? <a className="btn-link" href="/Cook/default-register">Create Account</a>
                                        </p> }

                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* <Modal className="modal-min" show={ this.state.show1 } onHide={ this.handleClose } aria-labelledby="contained-modal-title-vcenter"
                            centered>
                            <Modal.Body className="text-center">
                                <button type="button" className="close" onClick={ this.handleClose }><span aria-hidden="true">×</span></button>
                                <i className="flaticon-secure-shield d-block" />
                                <h1>Forgot Password?</h1>
                                <p>Enter your email to recover your password</p>
                                <form method="post">
                                    <div className="ms-form-group has-icon">
                                        <input type="text" placeholder="Email Address" className="form-control" name="forgot-password" /> <i class="material-icons">email</i>
                                    </div>
                                    <button type="submit" className="btn btn-primary shadow-none">Reset Password</button>
                                </form>
                            </Modal.Body>
                        </Modal> */}
                    </div>
                </main>
            </div >
        );
    }
}

export default LoginContent;