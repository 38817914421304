import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ApiInstance from '../../../Utilities/axiosConfig';
import Swal from 'sweetalert2'
import { renderIntoDocument } from 'react-dom/test-utils';
import LoaderComponent from '../../../components/LoaderComponent';

class Defaultregister extends Component {
    constructor () {
        super();

        this.state = {
            RegistrationData: {},
            IsEmailExist: false,
            IsUserNameExist: false,
            IsMobileExist: false,
            TermsAndCondition: '',
            file: '',
            ActiveLoader: false
        }


        this._handleChangeEvent = this.ItemChangeEvent.bind(this);
        this._checkboxChangeHandler = this.checkboxChangeEvent.bind(this)
        this.sweetalerthtml = this.sweetalerthtml.bind(this);

    }
    componentDidMount() {
        //initialize datatable
        function formValidation() {
            window.addEventListener('load', function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function (form) {
                    form.addEventListener('submit', function (event) {


                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add('was-validated');
                        }
                        else {
                            form.classList.remove('was-validated');
                        }
                    }, false);
                });

            }, false);
        }
        formValidation();
        this._handleSubmitEvent = this.handleSubmit.bind(this);
    }

    ItemChangeEvent = event => {

        const value = event.target.value;
        const key = event.target.name;

        if (key === 'FirstName' || key === 'LastName') {
            var regex = /^[a-zA-Z\s]*$/;
            if (value.trim().match(regex)) {

                let RegistrationData = this.state.RegistrationData;
                RegistrationData[key] = value;

                this.setState({
                    RegistrationData: RegistrationData
                });
            }
        }
        else if (key === "InsuranceNumber") {
            var regex = /^[a-zA-Z0-9]*$/;
            if (value.match(regex)) {

                let RegistrationData = this.state.RegistrationData;
                RegistrationData[key] = value;

                this.setState({
                    RegistrationData: RegistrationData
                });
            }
        }
        else if (key === 'MobileNo') {
            var regex = /^[0-9]*$/;

            if (value.match(regex)) {
                let RegistrationData = this.state.RegistrationData;
                RegistrationData[key] = value;

                this.setState({
                    RegistrationData: RegistrationData
                });
            }

        }
        else if (key === 'EmailId') {

            let RegistrationData = this.state.RegistrationData;
            RegistrationData[key] = value;

            this.setState({
                RegistrationData: RegistrationData
            });

        }
        else if (key === 'Password') {

            let RegistrationData = this.state.RegistrationData;
            RegistrationData[key] = value;

            this.setState({
                RegistrationData: RegistrationData
            });

            this.validatePassword();

        }
        else {


            let RegistrationData = this.state.RegistrationData;
            RegistrationData[key] = value;

            this.setState({
                RegistrationData: RegistrationData
            });

        }
    }

    resetFormData = () => {

        const clonedState = this.state.RegistrationData;
        const keys = Object.keys(clonedState);
        keys.forEach(key => (delete clonedState[key]));

        this.setState({
            RegistrationData: {},
            IsEmailExist: false,
            IsUserNameExist: false,
            IsMobileExist: false
        });

        var forms = document.getElementsByClassName('needs-validation');
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.classList.remove('was-validated');
        }, false);

    }

    sweetalerthtml() {
        Swal.fire({
            title: '<spam>Congratulation !</spam>',
            icon: '',
            html: 'Thank for registering with us, Our administrator will apporve you shortly!',
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            cancelButtonText: 'Close',
            cancelButtonAriaLabel: 'Thumbs down'
        }).then(function (result) {
            if (result.value) {
                window.location.reload();
                //this.resetFormData();
            }
        });

    }


    checkboxChangeEvent(event) {

        const value = event.target.checked;
        const key = event.target.name;

        this.setState({
            TermsAndCondition: value
        }, () => { console.log(this.state.TermsAndCondition) });

    }

    validatePassword() {

        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,20})");
        var txtPassword = document.getElementById("Password");
        if (!strongRegex.test(txtPassword.value)) {
            document.getElementById("ValidatePassword").innerHTML = "Password must be contain min 6 and max 20 character with the combination of uppercase ,lower case, numeric and special char";
            return false;
        }
        else {
            document.getElementById("ValidatePassword").innerHTML = "";
            return true;
        }

    }
    setFile(e) {
        if (this.validate(e.target)) {
            this.setState({ file: e.target.files[0] });
            console.log(e.target.files[0]);

        }
    }

    validate(file) {
        console.log(file);

        var valid = true;
        var file_name = "";
        var file_type = "";
        var file_size = "";
        var error_msg = "";
        var valid_size = 5 * 1000 * 1000;
        var display_error = document.getElementById('file_error');
        console.log(file.files.length)


        if (file.files.length != 0) {
            file_name = file.files[0].name;
            file_size = file.files[0].size;
            file_type = file.files[0].type;
            console.log(file_type)
            if (file_type != "image/png" && file_type != "image/jpeg" && file_type != "application/pdf" && file_type != "image/bmp") {
                valid = false;
                error_msg = error_msg + "\n* Only 'PNG', 'JPG/JPEG','BMP' and 'PDF' file type supported.";
            }
            if (file_size > valid_size) {
                valid = false;
                error_msg = error_msg + "\n* Filesize should be upto 5MB.";
            }
        }
        else {
            valid = false;

            error_msg = error_msg + "\n* Please select any image file.";
        }

        if (valid == true) {
            display_error.innerHTML = "";
            document.getElementById("filelabel").innerText = file.files[0].name;
            console.log(valid)
            /*Write ajax code here to send file to the server.*/
            return true;
        }
        else {
            console.log(valid)
            display_error.innerHTML = error_msg;
            return false;
        }
    }

    handleSubmit = event => {
        event.preventDefault()
        let TermsAndCondition = (this.state.TermsAndCondition === '' || this.state.TermsAndCondition === false) ? false : this.state.TermsAndCondition
        this.setState({
            IsEmailExist: false,
            IsUserNameExist: false,
            IsMobileExist: false,
            TermsAndCondition: TermsAndCondition
        })

        if (event.target.checkValidity() && this.validatePassword() && this.validateEmail() && this.state.TermsAndCondition === true) {
            this.setState({ ActiveLoader: true })
            const formData = new FormData();
            formData.append("file", this.state.file);
            formData.append("data", JSON.stringify(this.state.RegistrationData));

            ApiInstance().post("CookMaster/InsertCook", formData, { headers: { 'Content-Type': 'multipart/form-data', } })
                .then(response => {
                    let data = response.data;
                    console.log(data);
                    if (data.status) {
                        this.sweetalerthtml();
                    }
                    else {
                        if (data.data.toLowerCase().includes("username")) {
                            this.setState({ IsUserNameExist: true }, () => { })
                        }

                        if (data.data.toLowerCase().includes("email")) {
                            this.setState({ IsEmailExist: true }, () => { })
                        }

                        if (data.data.toLowerCase().includes("mobileno")) {
                            this.setState({ IsMobileExist: true }, () => { })
                        }
                    }

                    this.setState({ ActiveLoader: false })

                }, error => {
                    //window.init_PNotify("Error", "error", "Record not updated!!");
                    this.setState({ ActiveLoader: false })
                    console.log(error);
                });



        }
        else {
            console.log(event.target.checkValidity())
        }

    }

    validateEmail() {

        var strongRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        var txtEmail = document.getElementById("EmailId");
        console.log(txtEmail.value)
        if (!strongRegex.test(txtEmail.value)) {
            document.getElementById("ValidateEmail").innerHTML = "Invalid email address";

            return false;
        }
        else {
            document.getElementById("ValidateEmail").innerHTML = "";
            return true;
        }

    }


    onKeyPress(event) {
        return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122));
    }

    render() {
        return (
            <>
                <LoaderComponent ActiveLoader={ this.state.ActiveLoader }></LoaderComponent>
                <div className="ms-content-wrapper ms-auth">
                    <div className="container-fluid h-100 p-0">
                       <div className="row h-100">
                       <div className="col-lg-6 col-12 col-md-12">
                            <div className="ms-auth-bg" />
                        </div>
                        <div className="col-lg-6 col-12 col-md-12">
                            <div className="ms-auth-form">
                                <form className="needs-validation" noValidate onSubmit={ this._handleSubmitEvent } id="registrationform">
                                    <h3 style={ { textAlign: 'center' } }>Create Account</h3>
                                    {/* <p style={ { fontSize: "18px", fontWeight: 'bold' } }>Personal Information : </p>
                                <hr /> */}
                                    <div className="form-row">
                                        <div className="col-md-6 ">
                                            <label htmlFor="FristName">First name <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" pattern="^[a-zA-Z\s\t\r\n]+$" id="FirstName" value={ this.state.RegistrationData.FirstName || '' } name="FirstName" placeholder="John" required onChange={ this._handleChangeEvent } minLength="2" maxLength="15" />
                                                <div className="invalid-feedback">
                                                    Please enter first name.
                                              </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 ">
                                            <label htmlFor="LastName">Last name <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" pattern="^[a-zA-Z\s]+$" id="LastName" name="LastName" value={ this.state.RegistrationData.LastName || '' } placeholder="Doe" required onChange={ this._handleChangeEvent } minLength="2" maxLength="15" />

                                                <div className="invalid-feedback">
                                                    Please enter last name.
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-6 ">
                                            <label htmlFor="EmailId">Email address <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="email" className="form-control" id="EmailId" name="EmailId" value={ this.state.RegistrationData.EmailId || '' } placeholder="johndoe@xyz.com" required onChange={ this._handleChangeEvent } />
                                                { !this.state.IsEmailExist && <div className="invalid-feedback">
                                                    Please enter valid email.
                                            </div>
                                                }

                                                { this.state.IsEmailExist && <div className="invalid-feedback d-block">
                                                    Email Id already exist.
                                            </div> }
                                                <div className="invalid-feedback d-block" id="ValidateEmail"> </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 ">
                                            <label htmlFor="MobileNo">Mobile number <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="text" minLength="10" maxLength="10" className="form-control" id="MobileNo" pattern="^[0-9]+$" value={ this.state.RegistrationData.MobileNo || '' } name="MobileNo" placeholder="999 999 9999" required onChange={ this._handleChangeEvent } />
                                                { !this.state.IsMobileExist && <div className="invalid-feedback">
                                                    Please enter number.
                                                </div>
                                                }
                                                { this.state.IsMobileExist && <div className="invalid-feedback d-block">
                                                    Mobile Number already exist.
                                            </div> }

                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-12 ">
                                            <label htmlFor="UserName">User name <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="text" minLength="4" maxLength="15" pattern="^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,18}[a-zA-Z0-9]$" className="form-control" id="UserName" name='UserName' value={ this.state.RegistrationData.UserName || '' } placeholder="JohnDoe" required onChange={ this._handleChangeEvent } />
                                                { !this.state.IsUserNameExist &&
                                                    <div className="invalid-feedback">
                                                        Please enter username.
                                                        </div>
                                                }
                                                { this.state.IsUserNameExist &&
                                                    <div className="invalid-feedback d-block">
                                                        User name already exist.
                                                        </div>
                                                }
                                            </div>
                                        </div>

                                        <div className="col-md-12 ">
                                            <label htmlFor="Password">Password <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="password" minLength="6" maxLength="20" className="form-control" id="Password" name='Password' value={ this.state.RegistrationData.Password || '' } placeholder="*******" required onChange={ this._handleChangeEvent } onBlur={ () => this.validatePassword() } />
                                                <div className="invalid-feedback ">
                                                    Please enter password.
                                             </div>
                                                <div className="invalid-feedback d-block" id="ValidatePassword"> </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    {/* <p style={ { fontSize: "18px", fontWeight: 'bold' } }>Insurance Details : </p>
                                <hr /> */}
                                    <div className="form-row">
                                        <div className="col-md-6 ">
                                            <label htmlFor="InsuranceProvider">Insurance provider <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="InsuranceProvider" name='InsuranceProvider' value={ this.state.RegistrationData.InsuranceProvider || '' } placeholder="XYZ Ltd." required onChange={ this._handleChangeEvent } maxLength="20" />
                                                <div className="invalid-feedback">
                                                    Please enter insurance provider.
                                             </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 ">
                                            <label htmlFor="InsuranceNumber">Insurance card number <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="InsuranceNumber" name='InsuranceNumber' placeholder="ABC56789" value={ this.state.RegistrationData.InsuranceNumber || '' } required onChange={ this._handleChangeEvent } maxLength="20" />
                                                <div className="invalid-feedback">
                                                    Please enter insurance card number.
                                             </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-6 ">
                                            <label htmlFor="InsuranceExpiryDate">Insurance expiry date<span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="date" className="form-control" id="InsuranceExpiryDate" name='InsuranceExpiryDate' value={ this.state.RegistrationData.InsuranceExpiryDate || '' } required onChange={ this._handleChangeEvent } max='9999-12-31' />
                                                <div className="invalid-feedback">
                                                    Please enter insurance expiry date.
                                             </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 ">
                                            <label htmlFor="InsuranceFile">Insurance Document <span className="required">*</span></label>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" onChange={ e => this.setFile(e) } required id="txtfile" />
                                                    <label className="custom-file-label text-overflow" htmlFor="validatedCustomFile" id="filelabel">Upload File (pdf, bmp, jpeg/jpg, png) </label>
                                                    <div className="invalid-feedback">
                                                        Please select file.
                                                         </div>
                                                    <div id="file_error" className="invalid-feedback d-block">

                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                    <div className="form-group">

                                        <div className="form-check pl-0">
                                            <label className="ms-checkbox-wrap">
                                                <input className="form-check-input" type="checkbox" id="TermsAndCondition" name="TermsAndCondition" required onChange={ this._checkboxChangeHandler } />
                                                <i className="ms-checkbox-check" />
                                            </label>
                                            <span> Agree to terms and conditions <span className="required">*</span>
                                                <br></br>

                                                { this.state.TermsAndCondition === false && <span className="required" >Please select terms and conditions</span> }
                                            </span>

                                        </div>
                                    </div>
                                    <button className="btn btn-primary mt-4 d-block w-100" type="submit" >Create Account</button>
                                    <p className="mb-0 mt-3 text-center">Already have an account? <a className="btn-link" href="/Cook">Login</a> </p>
                                </form>

                            </div>
                        </div>
                   
                       </div>
                    </div>
                </div >

            </>
        );
    }
}

export default Defaultregister;