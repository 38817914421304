import React, { Component } from 'react'
import ApiInstance from '../../../Utilities/axiosConfig';
import $ from 'jquery';
import { Multiselect } from 'multiselect-react-dropdown';
import { GetSessionData } from '../../../GlobalFactory';


export default class MultiSelectComponent extends Component {

    constructor (props) {
        super(props);

        this.Typearr = [];
        this.state = {

            objectArray: [],
            selectedValues: [],
            displayValue: ""
        };

        this.onTypeSelect = this.onTypeSelect.bind(this);
        this.onTypeRemove = this.onTypeRemove.bind(this);
    }

    onTypeRemove(selectedList, removedItem) {

        var index = this.Typearr.indexOf(removedItem[this.state.displayValue]);
        this.Typearr.splice(index, 1);


    }
    onTypeSelect(selectedList, selectedItem) {
        this.Typearr.push(selectedItem)

    }

    GetSelectedType() {
        return this.Typearr;
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.dataobject !== undefined) {
            this.setState({ objectArray: nextProps.dataobject.objectArray, selectedValues: nextProps.dataobject.selectedValues, displayValue: nextProps.dataobject.displayValue }
                , () => {
                    this.Typearr = this.state.selectedValues;
                });
        }
    }

    render() {
        const { objectArray, selectedValues } = this.state;


        return (
            <>
                <Multiselect
                    options={ objectArray }
                    displayValue={ this.state.displayValue }
                    selectedValues={ selectedValues }
                    onSelect={ this.onTypeSelect } // Function will trigger on select event
                    onRemove={ this.onTypeRemove } // Function will trigger on remove event
                />
            </>
        )
    }
}

