import React, { Component } from 'react';

import img1 from '../../../assets/img/costic/food-5.jpg';
import img2 from '../../../assets/img/costic/food-2.jpg';
import img3 from '../../../assets/img/costic/food-4.jpg';
import img4 from '../../../assets/img/costic/food-3.jpg';
import Config from '../../../Config';

const trendfood = [
    {
        photo: img1,
        title: "Meat Stew",
        price: "$25.00",
        order: "15",
        income: "$175",
        resposnivecls: "col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-lg-20",
    },
    {
        photo: img2,
        title: "Pancake",
        price: "$50.00",
        order: "75",
        income: "$275",
        resposnivecls: "col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-lg-20",
    },
    {
        photo: img3,
        title: "Burger",
        price: "$45.00",
        order: "85",
        income: "$575",
        resposnivecls: "col-xl-3 col-lg-6 col-md-6 col-sm-6",
    },
    {
        photo: img4,
        title: "Saled",
        price: "$85.00",
        order: "175",
        income: "$775",
        resposnivecls: "col-xl-3 col-lg-6 col-md-6 col-sm-6",
    },
]

class Trendingorder extends Component {

    constructor (props) {
        super(props)
        this.state = {
            TrendingOrders: []
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.TrendingOrders !== this.state.TrendingOrders) {
            this.setState({ TrendingOrders: nextProps.TrendingOrders })
        }
    }

    render() {
        return (
            <div className="ms-panel">
                <div className="ms-panel-header">
                    <h6>Trending Orders</h6>
                </div>
                <div className="ms-panel-body">
                    <div className="row">
                        { this.state.TrendingOrders.map((item, i) => (
                            <div key={ i } className={ "col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-lg-20" }>
                                <div className="ms-card no-margin">
                                    <div className="ms-card-img">
                                        <img src={ (item.Photo) ? (Config.BaseURL + item.Photo) : '../../../assets/img/costic/food-5.jpg' } alt="card_img" style={ { maxWidth: "530", maxHeight: "240", Width: "530", Height: "240" } } />
                                    </div>
                                    <div className="ms-card-body">
                                        <div className="ms-card-heading-title">
                                            <h6>{ item.DishName }</h6>
                                            <span className="green-text"><strong>${ (item.Price) ? item.Price : 0 }</strong></span>
                                        </div>
                                        <div className="ms-card-heading-title">
                                            <p>Orders <span className="red-text">${ item.Orders }</span></p>
                                            <p>Income <span className="red-text">{ item.Income }</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) }
                    </div>
                </div>
            </div>
        );
    }
}

export default Trendingorder;