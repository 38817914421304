import React, { Component } from 'react';
import { a } from 'react-router-dom'
import $ from 'jquery';
import Scrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { GetSessionData } from '../../GlobalFactory';
//import logo from '../../assets/img/costic/costic-logo-216x62.png';
import logo from '../../assets/img/costic/logo_solid.png';

class Sidenavigation extends Component {
    removeoverlay = () => {
        $('.ms-body').toggleClass('ms-aside-left-open');
        $('#ms-side-nav').toggleClass('ms-aside-open');
        $(".ms-aside-overlay.ms-overlay-left").toggleClass('d-block');
    }
    componentDidMount() {
        function setActiveMenuItem() {
            $('.ms-main-aside .menu-item>a').on('click', function () {
                $(this).removeAttr('href');
                var element = $(this).parent('li');
                if (element.hasClass('active')) {
                    element.removeClass('active');
                    element.find('li').removeClass('active');
                    element.find('.collapse').slideUp();
                } else {
                    element.addClass('active');
                    element.children('.collapse').slideDown();
                    element.siblings('li').children('.collapse').slideUp();
                    element.siblings('li').removeClass('active');
                    element.siblings('li').find('li').removeClass('active');
                    element.siblings('li').find('.collapse').slideUp();
                }
            });
        }
        setActiveMenuItem();


    }
    render() {
        var objsessiondata = GetSessionData();

        return (
            <div>
                <div className="ms-aside-overlay ms-overlay-left ms-toggler" onClick={ this.removeoverlay }></div>
                <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
                <Scrollbar id="ms-side-nav" className="side-nav fixed ms-aside-scrollable ms-aside-left">
                    {/* Logo */ }
                    <div className="logo-sn ms-d-block-lg">
                        <a className="pl-0 ml-0 text-center" href="/Cook/Dashboard">
                            <img src={ logo } alt="logo" />
                        </a>
                    </div>
                    {/* Navigation */ }
                    <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">
                        {/* Dashboard */ }
                        { objsessiondata.UserType === '' &&
                            <li className="menu-item">
                                <a href="#" className="has-chevron"> <span><i className="material-icons fs-16" >dashboard</i>Dashboard </span>
                                </a>
                                <ul id="dashboard" className="collapse" aria-labelledby="dashboard" data-parent="#side-nav-accordion">
                                    <li> <a href="/">Costic</a>
                                    </li>
                                </ul>
                            </li> }
                        { objsessiondata.UserType.toLowerCase() === 'cook' &&
                            <>

                                <li className="menu-item" onClick={ () => { window.location.href = "/Cook/Dashboard" } }>
                                    <a href="/Cook/Dashboard/"> <span><i className="fa fa-archive fs-16" />Dashboard</span>
                                    </a>
                                </li >
                                <li className="menu-item">

                                    <a href="#" className="has-chevron"> <span><i className="material-icons fs-16" >dashboard</i>Dish Master</span>
                                    </a>
                                    <ul id="dashboard" className="collapse" aria-labelledby="dashboard" data-parent="#side-nav-accordion">
                                        {/* <li> <a href="/Cook">Cook Login</a> </li> */ }
                                        {/* <li> <a href="/Cook/default-register">Registration</a></li> */ }
                                        <li> <a href="/Cook/Dishes">Dish List</a></li>
                                        <li> <a href="/Cook/AddDish">AddDish</a></li>
                                        <li> <a href="/Cook/Specials">Specials</a></li>
                                        {/* <li>
                                            <a href="/Cook/Dashboard/"> Dashboard
                                        </a>
                                        </li > */}
                                    </ul>
                                </li>
                                <li className="menu-item">

                                    <a href="#" className="has-chevron"> <span><i className="material-icons fs-16" >dashboard</i>Report</span>
                                    </a>
                                    <ul id="dashboard" className="collapse" aria-labelledby="dashboard" data-parent="#side-nav-accordion">
                                        {/* <li> <a href="/Cook">Cook Login</a> </li> */ }
                                        {/* <li> <a href="/Cook/default-register">Registration</a></li> */ }
                                        <li> <a href="/Cook/CookOrderList">Order List</a></li>
                                        <li> <a href="/Cook/CookOrderDishWiseSelling">Dish wise selling</a></li>

                                        {/* <li>
                                            <a href="/Cook/Dashboard/"> Dashboard
                                        </a>
                                        </li > */}
                                    </ul>
                                </li>

                                <li className="menu-item" onClick={ () => { window.location.href = "/Cook/CookProfile" } }>
                                    <a href="/Cook/CookProfile/"> <span><i className="fa fa-user fs-16" />Profile</span>
                                    </a>
                                </li >
                            </>
                        }
                        { objsessiondata.UserType.toLowerCase() === 'admin' && <>
                            <li className="menu-item" onClick={ () => { window.location.href = "/admin/Dashboard" } }>
                                <a href="/admin/Dashboard/"> <span><i className="fa fa-archive fs-16" />Dashboard</span>
                                </a>
                            </li >
                            <li className="menu-item">

                                <a href="#" className="has-chevron"> <span><i className="material-icons fs-16" >dashboard</i>Masters </span>
                                </a>
                                <ul id="dashboard" className="collapse" aria-labelledby="dashboard" data-parent="#side-nav-accordion">

                                    {/* <li> <a href="/Admin/CookProfile">CookProfile</a></li> */ }
                                    <li> <a href="/Admin/FoodType">FoodType</a></li>
                                    <li> <a href="/Admin/FoodCategory">Food Category</a></li>
                                    <li> <a href="/Admin/FoodMeal">Food Meal</a></li>
                                    <li> <a href="/Admin/Nutrition">Nutrition</a></li>
                                    <li> <a href="/Admin/Cuisine">Cuisine</a></li>
                                    <li> <a href="/Admin/Allergens">Allergens</a></li>

                                </ul>
                            </li>
                            <li className="menu-item" onClick={ () => { window.location.href = "/admin/CookProfile" } }>
                                <a href="/admin/CookProfile/"> <span><i className="fa fa-archive fs-16" />Cook Profile</span>
                                </a>
                            </li >
                            <li className="menu-item" onClick={ () => { window.location.href = "/admin/Refund" } }>
                                <a href="/admin/Refund/"> <span><i className="fa fa-archive fs-16" />Refund</span>
                                </a>
                            </li >
                            <li className="menu-item" onClick={ () => { window.location.href = "/admin/RefundHistory" } }>
                                <a href="/admin/RefundHistory"> <span><i className="fa fa-archive fs-16" />Refund History</span>
                                </a>
                            </li >
                            <li className="menu-item">

                                <a href="#" className="has-chevron"> <span><i className="material-icons fs-16" >dashboard</i>Report</span>
                                </a>
                                <ul id="dashboard" className="collapse" aria-labelledby="dashboard" data-parent="#side-nav-accordion">
                                    {/* <li> <a href="/Cook">Cook Login</a> </li> */ }
                                    {/* <li> <a href="/Cook/default-register">Registration</a></li> */ }
                                    <li> <a href="/Admin/AdminOrderList">Order List</a></li>
                                    <li> <a href="/Admin/AdminCookWiseSummary">Cook wise selling</a></li>

                                    {/* <li>
                                            <a href="/Cook/Dashboard/"> Dashboard
                                        </a>
                                        </li > */}
                                </ul>
                            </li>

                            {/* <li className="menu-item" onClick={ () => { window.location.href = "/Admin/CookProfile" } }>
                                <a href="/Admin/CookProfile/"> <span><i className="fa fa-user fs-16" />Profile</span>
                                </a>
                            </li > */}


                        </> }
                        {/* /Dashboard */ }
                        {/* product */ }
                        { objsessiondata.UserType == '' && <>
                            <li className="menu-item">
                                <a href="#" className="has-chevron"> <span><i className="fa fa-archive fs-16" />Menus </span>
                                </a>
                                <ul id="product" className="collapse" aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li> <a href="/menu-catalogue" >Menu Catalogue</a>
                                    </li>
                                    <li> <a href="/menu-list" >Menu List</a>
                                    </li>
                                    <li> <a href="/menu-grid" >Menu Grid</a>
                                    </li>
                                    <li> <a href="/add-product" >Add Product</a>
                                    </li >
                                    <li> <a href="/product-detail" >Product Detail</a>
                                    </li >
                                </ul >
                            </li >


                            <li className="menu-item">
                                <a href="/orders"> <span><i className="fas fa-clipboard-list fs-16" />Orders</span>
                                </a>
                            </li >

                            <li className="menu-item">
                                <a href="/restaurant-list"> <span><i className="fa fa-tasks fs-16" />Restaurants List</span>
                                </a>
                            </li >
                            <li className="menu-item">
                                <a href="#" className="has-chevron"> <span><i className="fas fa-file-invoice fs-16" />Invoice </span>
                                </a>
                                <ul id="invoice" className="collapse" aria-labelledby="invoice" data-parent="#side-nav-accordion">
                                    <li> <a href="/invoice-detail" >Invoice Detail</a>
                                    </li>
                                    <li> <a href="/invoice-list" >Invoice List</a>
                                    </li>
                                </ul >
                            </li >
                            <li className="menu-item">
                                <a href="#" className="has-chevron"> <span><i className="fas fa-user-friends fs-16" />Customers </span>
                                </a>
                                <ul id="customer" className="collapse" aria-labelledby="customer" data-parent="#side-nav-accordion">
                                    <li> <a href="/customer-review" >Customers Review</a>
                                    </li>
                                    <li> <a href="/customer-list" >Customers List</a>
                                    </li>
                                    <li> <a href="/social-activity" >Social Activity</a>
                                    </li >
                                </ul >
                            </li >

                            <li className="menu-item">
                                <a href="/sales" > <span><i className="fa fa-briefcase fs-16" />Sales</span>
                                </a>
                            </li >

                            < li className="menu-item" >
                                <a href="/widgets"> <span><i className="material-icons fs-16">widgets</i>Widgets</span>
                                </a>
                            </li >

                            <li className="menu-item" >
                                <a href="#" className="has-chevron"> <span><i className="material-icons fs-16">filter_list</i>Basic UI Elements</span>
                                </a>
                                <ul id="basic-elements" className="collapse" aria-labelledby="basic-elements" data-parent="#side-nav-accordion">
                                    <li> <a href="/accordions" >Accordions</a>
                                    </li>
                                    <li> <a href="/alerts" >Alerts</a>
                                    </li >
                                    <li> <a href="/buttons" >Buttons</a>
                                    </li >
                                    <li> <a href="/breadcrumbs" >Breadcrumbs</a>
                                    </li >
                                    <li> <a href="/badges" >Badges</a>
                                    </li >
                                    <li> <a href="/cards" >Cards</a>
                                    </li >
                                    <li> <a href="/progress-bars" >Progress Bars</a>
                                    </li >
                                    <li> <a href="/preloaders" >Pre-loaders</a>
                                    </li >
                                    <li> <a href="/pagination" >Pagination</a>
                                    </li >
                                    <li> <a href="/tabs" >Tabs</a>
                                    </li >
                                    <li> <a href="/typography" >Typography</a>
                                    </li >
                                </ul >
                            </li >

                            <li className="menu-item">
                                <a href="#" className="has-chevron"> <span><i className="material-icons fs-16">code</i>Advanced UI Elements</span>
                                </a>
                                <ul id="advanced-elements" className="collapse" aria-labelledby="advanced-elements" data-parent="#side-nav-accordion">
                                    <li> <a href="/draggables" >Draggables</a>
                                    </li>
                                    <li> <a href="/sliders" >Sliders</a>
                                    </li>
                                    <li> <a href="/modals" >Modals</a>
                                    </li >
                                    <li> <a href="/rating" >Rating</a>
                                    </li >
                                    <li> <a href="/tour" >Tour</a>
                                    </li >
                                    <li> <a href="/cropper" >Cropper</a>
                                    </li >
                                    <li> <a href="/range-slider" >Range Slider</a>
                                    </li >
                                </ul >
                            </li >

                            <li className="menu-item" >
                                <a href="/animations"> <span><i className="material-icons fs-16">format_paint</i>Animations</span>
                                </a>
                            </li>

                            < li className="menu-item" >
                                <a href="#" className="has-chevron"> <span><i className="material-icons fs-16">input</i>Form Elements</span>
                                </a>
                                <ul id="form-elements" className="collapse" aria-labelledby="form-elements" data-parent="#side-nav-accordion">
                                    <li> <a href="/form-elements" >Form Elements</a>
                                    </li>
                                    <li> <a href="/form-layouts" >Form Layouts</a>
                                    </li >
                                    <li> <a href="/form-validation" >Form Validation</a>
                                    </li >
                                    <li> <a href="/form-wizard" >Form Wizard</a>
                                    </li >
                                </ul >
                            </li >

                            <li className="menu-item">
                                <a href="#" className="has-chevron"> <span><i className="material-icons fs-16">equalizer</i>Charts</span>
                                </a>
                                <ul id="charts" className="collapse" aria-labelledby="charts" data-parent="#side-nav-accordion">
                                    <li> <a href="/chartjs" >Chart JS</a>
                                    </li>
                                    <li> <a href="/google-chart" >Google Chart</a>
                                    </li>
                                </ul >
                            </li >

                            <li className="menu-item">
                                <a href="#" className="has-chevron"> <span><i className="material-icons fs-16">tune</i>Tables</span>
                                </a>
                                <ul id="tables" className="collapse" aria-labelledby="tables" data-parent="#side-nav-accordion">
                                    <li> <a href="/basic-tables" >Basic Tables</a>
                                    </li>
                                    <li> <a href="/data-tables" >Data tables</a>
                                    </li>
                                </ul >
                            </li >

                            <li className="menu-item">
                                <a href="#" className="has-chevron"> <span><i className="material-icons fs-16">message</i>Popups</span>
                                </a>
                                <ul id="popups" className="collapse" aria-labelledby="popups" data-parent="#side-nav-accordion">
                                    <li> <a href="/sweet-alerts" >Sweet Alerts</a>
                                    </li>
                                    <li> <a href="/toast" >Toast</a>
                                    </li>
                                </ul >
                            </li >

                            <li className="menu-item">
                                <a href="#" className="has-chevron"> <span><i className="material-icons fs-16">border_color</i>Icons</span>
                                </a>
                                <ul id="icons" className="collapse" aria-labelledby="icons" data-parent="#side-nav-accordion">
                                    <li> <a href="/fontawesome" >Fontawesome</a>
                                    </li>
                                    <li> <a href="/flaticons" >Flaticons</a>
                                    </li>
                                    <li> <a href="/materialize" >Materialize</a>
                                    </li >
                                </ul >
                            </li >

                            <li className="menu-item">
                                <a href="#" className="has-chevron"> <span><i className="material-icons fs-16">map</i>Maps</span>
                                </a>
                                <ul id="maps" className="collapse" aria-labelledby="maps" data-parent="#side-nav-accordion">
                                    <li> <a href="/google-maps" >Google Maps</a>
                                    </li>
                                    <li> <a href="/vector-maps" >Vector Maps</a>
                                    </li>
                                </ul >
                            </li >

                            <li className="menu-item">
                                <a href="#" className="has-chevron" data-toggle="collapse" data-target="#bonuspages" aria-expanded="false" aria-controls="bonuspages"> <span><i className="material-icons fs-16">insert_drive_file</i> Bonus Pages</span>
                                </a>
                                <ul id="bonuspages" className="collapse" aria-labelledby="bonuspages" data-parent="#side-nav-accordion">
                                    <li> <a href="/web-analytics"> Web Analytics </a>
                                    </li>
                                    <li> <a href="/stock-management">Stock Management</a>
                                    </li>
                                    <li> <a href="/client-management">Client Management</a>
                                    </li>
                                </ul>
                            </li>

                            <li className="menu-item">
                                <a href="#" className="has-chevron" data-toggle="collapse" data-target="#pages" aria-expanded="false" aria-controls="pages"> <span><i className="material-icons fs-16">insert_drive_file</i>Pages</span>
                                </a>
                                <ul id="pages" className="collapse" aria-labelledby="pages" data-parent="#side-nav-accordion">
                                    <li className="menu-item"> <a href="#" className="has-chevron" data-toggle="collapse" data-target="#authentication" aria-expanded="false" aria-controls="authentication">Authentication</a>
                                        <ul id="authentication" className="collapse" aria-labelledby="authentication" data-parent="#pages">
                                            <li> <a href="/default-login">Default Login</a>
                                            </li>
                                            <li> <a href="/modal-login">Modal Login</a>
                                            </li>
                                            <li> <a href="/default-register">Default Registration</a>
                                            </li>
                                            <li> <a href="/modal-register">Modal Registration</a>
                                            </li>
                                            <li> <a href="/lock-screen">Lock Screen</a>
                                            </li >
                                        </ul >
                                    </li >
                                    <li> <a href="/coming-soon">Coming Soon</a>
                                    </li >
                                    <li> <a href="/error">Error Page</a>
                                    </li >
                                    <li className="menu-item"> <a href="/faq"> FAQ </a>
                                    </li >
                                    <li className="menu-item"> <a href="/portfolio"> Portfolio </a>
                                    </li >
                                    <li className="menu-item"> <a href="/user-profile"> User Profile </a>
                                    </li >
                                    <li className="menu-item"> <a href="/invoice"> Invoice </a>
                                    </li >
                                </ul >
                            </li >

                            < li className="menu-item" >
                                <a href="#" className="has-chevron"> <span><i className="material-icons fs-16">phone_iphone</i>Apps</span>
                                </a>
                                <ul id="apps" className="collapse" aria-labelledby="apps" data-parent="#side-nav-accordion">
                                    <li> <a href="/chat" >Chat</a>
                                    </li>
                                    <li> <a href="/email" >Email</a>
                                    </li>
                                    <li> <a href="/todo-list" >To-do List</a>
                                    </li >
                                </ul >
                            </li >
                        </>
                        }
                    </ul >
                </Scrollbar >
            </div >
        );
    }
}

export default Sidenavigation;