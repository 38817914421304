import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../../components/layouts/Topnavigation';
import Quickbar from '../../../components/layouts/Quickbar';
import Breadcrumb from '../Breadcrumb';
import "datatables.net-bs4/js/dataTables.bootstrap4"
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css"
import $ from 'jquery';
import ApiInstance from '../../../Utilities/axiosConfig'
import { ToastMessage } from '../../../Utilities/ToastMessage'
import Swal from 'sweetalert2'
import { GetSessionData, TodaysDate, DateFromLastMonth } from '../../../GlobalFactory';
import { Modal, Accordion, Card } from "react-bootstrap";
import Config from '../../../Config';
export default class Refund extends Component {
    constructor () {
        super();

        this.state = {
            OrderList: [],

            RefundObj: {
                OrderMasterId: 0,
                RefundAmount: '',
                AdminReply: '',
                DisputeModelShow: false
            }
        }

        this._handleSubmitRefund = this.SubmitRefund.bind(this)

    }
    componentDidMount() {
        function formValidation() {
            window.addEventListener('load', function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function (form) {
                    form.addEventListener('submit', function (event) {


                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add('was-validated');
                        }
                        else {
                            form.classList.remove('was-validated');
                        }
                    }, false);
                });

            }, false);
        }
        formValidation();


        this.GetOrderList();
    }

    GetOrderList = () => {

        var today = new Date()

        var dtfrom = (document.getElementById("dtTo").value) ? document.getElementById("dtTo").value : (DateFromLastMonth().PrevMonthdate_YYYY_MMM_DD);
        var dtto = (document.getElementById("dtFrom").value) ? document.getElementById("dtFrom").value : (TodaysDate().Todaydate_YYYYMMMDD);

        console.log(dtto)
        console.log(dtfrom)
        let jsonobj = {
            StartDate: dtfrom,
            EndDate: dtto,
            IsDisputeOpen: true
        };

        console.log(jsonobj);

        ApiInstance().post('DisputeMaster/GetDisputeDataByOpenCloseAndDateTime', jsonobj, { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                var data = response.data;
                console.log(data)
                if (data) {

                    var dataObject = JSON.parse(data.data)
                    console.log(dataObject.Data)


                    this.setState({
                        OrderList: dataObject.Data

                    }, () => {
                        var objkeylist = dataObject.Columns.split(",").map(i => i.trim());
                        var columnlist = []
                        objkeylist.forEach(element => {
                            element = element.trim();
                            if (element !== 'OrderMasterId')
                                columnlist.push({ title: element.split(/(?=[A-Z])/).join(" "), data: element })
                        });

                        columnlist.push({
                            data: 'OrderMasterId',
                            title: 'Action',
                            render: function (td, data, type, row) {
                                return " ";
                            }

                        })

                        $('#Cook_OrderList_data-table').DataTable({

                            data: this.state.OrderList,
                            columns: columnlist,
                            "bDestroy": true,
                            columnDefs: [
                                {
                                    targets: objkeylist.length - 1,
                                    createdCell: (td, cellData, rowData, row, col) =>

                                        ReactDOM.render(<>
                                            {/* <a href={ "/Report/ReportCookOrderMasterDetailsByOrderMasterId/" + rowData.OrderMasterId } className="btn btn-primary btn-sm">View Details</a> */ }
                                            <a href="#" className="form-control" onClick={ () => { this.SetDisputeData(rowData) } }>Refund </a>
                                        </>, td),
                                },
                                {
                                    targets: objkeylist.indexOf("ClientDisputePhoto") - 1,
                                    createdCell: (td, cellData, rowData, row, col) =>

                                        ReactDOM.render(<>
                                            {/* <a href={ "/Report/ReportCookOrderMasterDetailsByOrderMasterId/" + rowData.OrderMasterId } className="btn btn-primary btn-sm">View Details</a> */ }
                                            <a href={ Config.BaseURL + rowData.ClientDisputePhoto } target="_blank" >View </a>
                                        </>, td),
                                },
                                {
                                    targets: objkeylist.indexOf("IsDisputeOpen") - 1,
                                    createdCell: (td, cellData, rowData, row, col) =>

                                        ReactDOM.render(<>
                                            {/* <a href={ "/Report/ReportCookOrderMasterDetailsByOrderMasterId/" + rowData.OrderMasterId } className="btn btn-primary btn-sm">View Details</a> */ }
                                            {(rowData.IsDisputeOpen) ? <span class="badge badge-primary">Open</span> : <span class="badge badge-success">Close</span> }
                                        </>, td),
                                },

                            ],
                        });
                    }

                    );
                }
            }, error => {
                console.log(error);
            });
    }

    SetDisputeData(obj) {
        console.log(obj)
        let RefundObj = this.state.RefundObj;
        RefundObj = {
            OrderMasterId: obj.OrderMasterId,
            DisputeModelShow: true
        }

        this.setState({
            RefundObj: RefundObj
        },
            () => {
                document.getElementById("RefundAmount").value = obj.OrderAmount;
            })


    }

    hideModel() {
        var RefundObj = this.state.RefundObj;
        RefundObj = {
            OrderMasterId: 0,
            RefundAmount: '',
            AdminReply: '',
            DisputeModelShow: false
        }

        this.setState({
            RefundObj
        })
    }


    SubmitRefund(event) {

        event.preventDefault();
        console.log(event.target.checkValidity())
        if (event.target.checkValidity()) {

            var RefundObj = this.state.RefundObj;

            RefundObj.RefundAmount = document.getElementById("RefundAmount").value;
            RefundObj.AdminReply = document.getElementById("AdminReply").value;

            console.log(RefundObj);

            ApiInstance().post('OrderMaster/RefundProcess', RefundObj, { headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    var data = response.data;
                    console.log(data)
                    if (data.status) {

                        this.GetOrderList()
                        ToastMessage('Success', 'Refund initiated successfully', 'success')

                        RefundObj = {
                            DisputeModelShow: false
                        }

                        this.setState({
                            RefundObj: RefundObj
                        })


                    }
                    else {
                        var errobj = document.getElementById("errmessage");
                        errobj.innerText = data.data
                    }
                }, error => {
                    console.log(error);
                });
        }
        else {
            var dirtag = document.getElementById("errmessage")
            var errstring = ""
            if (document.getElementById("AdminReply").value.length == 0) {
                errstring = "Please Enter refund notes \n";
            }
            if (document.getElementById("RefundAmount").value.length == 0) {
                errstring += "Please Enter amount";
            }
            dirtag.innerText = errstring
        }
    }

    render() {
        return (
            <>
                <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                    <Sidenavigation />
                    <main className="body-content">
                        <Topnavigation />
                        <div className="ms-content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <Breadcrumb FormName="Order List" />
                                </div>
                                <div className="col-md-12">
                                    <div className="ms-panel ms-panel-fh">
                                        <div className="ms-panel-header">
                                            <h6>Filter</h6>
                                        </div>
                                        <div className="ms-panel-body">

                                            <div className="form-row">
                                                <div className="col-md-3 mb-3">
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="DishName">From</label>
                                                    <div className="input-group">
                                                        <input type="date" className="form-control" id="dtFrom" />
                                                        <div className="invalid-feedback">
                                                            Please select day/week
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="DishName">To</label>
                                                    <div className="input-group">
                                                        <input type="date" className="form-control" id="dtTo" />
                                                        <div className="invalid-feedback">
                                                            Please select day/week
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <button className="btn btn-primary" type="submit" onClick={ () => { this.GetOrderList() } }>Search</button>
                                                    &nbsp;&nbsp; <button className="btn btn-primary" type="button" onClick={ () => { this.ResetData() } }>Reset</button>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="ms-panel ms-panel-fh">
                                        <div className="ms-panel-header">
                                            <h6>Order List</h6>
                                        </div>
                                        <div className="ms-panel-body">
                                            <table id="Cook_OrderList_data-table" className="table table-striped thead-primary w-100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>

                    <Modal className="modal-max" show={ this.state.RefundObj.DisputeModelShow } onHide={ () => { this.hideModel() } } aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Body>
                            <div className="col-md-12 mb-3">
                                <button type="button" className="close" onClick={ () => { this.hideModel() } }><span aria-hidden="true">×</span></button>
                                <h1 style={ { textAlign: "center" } }>Refund</h1>
                                <hr></hr>
                            </div>
                            <div className="col-md-12 mb-3">
                                <form className="needs-validation clearfix" noValidate onSubmit={ this._handleSubmitRefund }>
                                    <div className="form-group row">
                                        <label htmlFor="colFormLabelSm" className="col-sm-4 col-form-label col-form-label-sm">Refund Notes</label>
                                        <div className="col-sm-8">
                                            <textarea id="AdminReply" name="AdminReply" placeholder="" required className="form-control form-control-sm" maxLength="250" />

                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="colFormLabelSm" className="col-sm-4 col-form-label col-form-label-sm">Refund amount</label>
                                        <div className="col-sm-8">
                                            <input type="number" id="RefundAmount" step={ 0.01 } name="RefundAmount" placeholder="$10" required className="form-control form-control-sm" />
                                            <br></br>

                                        </div>

                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-4"></div>
                                        <div className="col-sm-8">
                                            <div className="invalid-feedback d-block" id="errmessage"> </div>

                                        </div>
                                    </div>

                                    <div className="form-group row">

                                        <div className="align-self-center mx-auto">
                                            <button className="btn btn-primary d-block" type="submit">Add</button>
                                        </div>

                                    </div>

                                </form>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Quickbar />
                </div>


            </>
        )
    }
}
