import React, { Component } from 'react';
import Breadcrumb from '../Breadcrumb';
import { Tab, Nav } from "react-bootstrap";
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../../components/layouts/Topnavigation';
import Quickbar from '../../../components/layouts/Quickbar';
import OnBoardScreen from './OnBoardScreen';
import PersonalDetails from './PersonalDetails';
import PhotoGallary from './PhotoGallary';

class Tabs extends Component {
    render() {
        return (
            <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                <Sidenavigation></Sidenavigation>
                <main className="body-content">
                    <Topnavigation></Topnavigation>
                    <div className="ms-content-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <Breadcrumb FormName="Cook Profile" />
                            </div>

                            <div className="col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-header">
                                        <h6>Cook Profile</h6>
                                    </div>
                                    <div className="ms-panel-body">
                                        {/* <p className="ms-directions">Use <code>.tabs-bordered</code> in <code>.nav-tabs</code></p> */ }
                                        <Tab.Container defaultActiveKey="tab1">
                                            <Nav variant="tabs" className="nav nav-tabs tabs-bordered d-flex nav-justified mb-4">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="tab1">Restaurant Details</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="tab2"> Profile</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="tab3">Gallery</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="tab1">
                                                    <OnBoardScreen></OnBoardScreen>

                                                </Tab.Pane>
                                                <Tab.Pane eventKey="tab2">
                                                    <PersonalDetails></PersonalDetails>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="tab3">
                                                    <PhotoGallary></PhotoGallary>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Quickbar></Quickbar>
            </div>
        );
    }
}

export default Tabs;