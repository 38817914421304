import React, { Component } from 'react';
import Breadcrumb from '../Breadcrumb';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../../components/layouts/Topnavigation';
import Quickbar from '../../../components/layouts/Quickbar';
import ApiInstance from '../../../Utilities/axiosConfig';
import $ from 'jquery';
import { Multiselect } from 'multiselect-react-dropdown';
import { GetSessionData } from '../../../GlobalFactory';
import { ToastMessage } from '../../../Utilities/ToastMessage'

const CuisineTypearr = [];
const FoodTypearr = [];
const FoodMealarr = [];
class OnBoardScreen extends Component {


    constructor () {
        super()
        this.state = {
            FormData: {
                CookId: '',
                Address1: '',
                LandMark: '',
                State: '',
                City: '',
                Pincode: '',
                BussinessName: '',
                BussinessMobileNo: '',
                ProfilePhoto: '',
                AbountMe: '',
                CookingSpecility: '',
                Latitude: '',
                Longitude: '',
                CuisineType: [],
                MealType: [],
                BussinessEmail: '',
                FoodType: []
            },
            file: '',
            FoodTypeList: [],
            CuisineList: [],
            FoodMealList: [],
            FoodTypearrSelect: []

        }

        this._handleChangeEvent = this.ItemChangeEvent.bind(this);
        this.refAddress = React.createRef();
        this.refStreet = React.createRef();
        this.refLandmark = React.createRef();
        this.refCity = React.createRef();
        this.refState = React.createRef();
        this.refPinCode = React.createRef();
        this.refLat = React.createRef();
        this.refLong = React.createRef();

        // this._ItemCheckedEvent = this.ItemCheckedEvent.bind(this);

        this._handleSubmitEvent = this.SubmitData.bind(this);


    }



    componentDidMount() {

        function formValidation() {
            window.addEventListener('load', function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function (form) {
                    form.addEventListener('submit', function (event) {
                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add('was-validated');
                        }
                        else {
                            form.classList.remove('was-validated');
                        }
                    }, false);
                });
            }, false);
        }
        formValidation();

        this.GetFoodType();
        this.GetCuisineList();
        this.GetFoodMealList();

        setTimeout(() => {
            this.GetCookDetails();
        }, 2000);

    }

    GetFoodType() {
        ApiInstance().get('FoodTypeMaster/SelectAlltblFoodTypeMaster')
            .then(response => {
                var data = response.data.data;
                if (data) {
                    var objdata = JSON.parse(data)
                    console.log(objdata)

                    this.setState({
                        drpFoodType: objdata.Data
                    });
                }
            }, error => {
                console.log(error);
            });
    }
    GetFoodMealList() {

        ApiInstance().get('FoodMealTypeMaster/SelectAlltblFoodMealTypeMaster')
            .then(response => {

                var data = response.data.data;


                if (data) {

                    let dataObject = JSON.parse(response.data.data);

                    this.setState({ FoodMealList: dataObject.Data });
                }
                else {
                }
            }, error => {
                console.log(error);
            });
    }
    GetCuisineList() {

        ApiInstance().get('CuisineMaster/SelectAlltblCuisineMaster')
            .then(response => {

                var data = response.data.data;
                if (data) {
                    let dataObject = JSON.parse(response.data.data);
                    this.setState({ CuisineList: dataObject.Data })
                }

            }, error => {
                console.log(error);
            });
    }
    GetCookDetails() {

        ApiInstance().get('CookMaster/GetCookMasterProfileData?CookId=' + GetSessionData().UserId)
            .then(response => {

                var data = JSON.parse(response.data.data);

                if (data) {
                    console.log(data.Data[0])
                    console.log(data.Data[0].FoodType.split(','))
                    var FoodTypearrSelect = this.state.FoodTypearrSelect;
                    data.Data[0].FoodType.split(',').map(e => {
                        let obj = this.state.drpFoodType.find(e1 => e1.FoodTypeName == e)
                        FoodTypearrSelect.push(obj)
                    }
                    )
                    console.log(FoodTypearrSelect)
                    FoodTypearrSelect = { selectedValues: FoodTypearrSelect, objectArray: this.state.drpFoodType }
                    this.setState({ FormData: data.Data[0], FoodTypearrSelect: FoodTypearrSelect })
                }
                else {
                }
            }, error => {
                console.log(error);
            });
    }


    setFile(e) {

        this.setState({ file: e.target.files[0] }, () => { console.log(this.state.file) });

        var previews = $("#previews");
        previews.empty();
        Array.prototype.slice.call(e.target.files)
            .forEach(function (file, idx) {
                var reader = new FileReader();
                reader.onload = function (event) {
                    $("<img />", {
                        "src": event.target.result,
                        "class": 'd-block w-100'
                    }).appendTo(previews);
                };
                reader.readAsDataURL(file);
            });



    }

    ItemChangeEvent = event => {
        const value = event.target.value;
        const key = event.target.name;
        let FormData = this.state.FormData;
        FormData[key] = value;
        this.setState({
            FormData: FormData
        });

    }

    ItemCheckedEvent = event => {
        const value = event.target.checked;
        const key = event.target.name;
        let FormData = this.state.FormData;
        FormData[key] = value;
        this.setState({
            FormData: FormData
        }, console.log(this.state.FormData));

    }

    SubmitData = (event) => {
        event.preventDefault();
        var FilledData = this.state.FormData
        FilledData.CookId = GetSessionData().UserId;

        FilledData.FoodType = FoodTypearr.join();
        FilledData.MealType = FoodMealarr.join();
        FilledData.CuisineType = CuisineTypearr.join();

        FilledData.Address1 = this.refAddress.current.value;
        FilledData.Street = this.refStreet.current.value;
        FilledData.LandMark = this.refLandmark.current.value;
        FilledData.City = this.refCity.current.value;
        FilledData.State = this.refState.current.value;
        FilledData.Pincode = this.refPinCode.current.value;
        FilledData.Latitude = this.refLat.current.value;
        FilledData.Longitude = this.refLong.current.value;



        if (event.target.checkValidity()) {




            const formData = new FormData();
            formData.append("data", JSON.stringify(FilledData));
            formData.append("file", this.state.file);

            ApiInstance().post('CookMaster/UpdateCookProfileForOnBoarding', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(response => {
                    var data = response.data;
                    if (data.message == "Success") {
                        ToastMessage('Success', 'Record has been updated successfully', 'success')
                        window.location.href = '../Cook/Dashboard';
                    }
                    else {
                        ToastMessage('Error', 'Error in submit', 'danger')
                    }
                    console.log(response);


                }, error => {
                    console.log(error);
                });

        }
    }



    onCusineSelect(selectedList, selectedItem) {
        CuisineTypearr.push(selectedItem.CuisineName)
        console.log(CuisineTypearr);
    }

    onCusineRemove(selectedList, removedItem) {
        var index = CuisineTypearr.indexOf(removedItem.CuisineName);
        CuisineTypearr.splice(index, 1);
        console.log(CuisineTypearr);
    }

    onFoodTypeSelect(selectedList, selectedItem) {
        FoodTypearr.push(selectedItem)
        console.log(FoodTypearr);
    }

    onFoodTypeRemove(selectedList, removedItem) {
        var index = FoodTypearr.indexOf(removedItem.FoodTypeName);
        FoodTypearr.splice(index, 1);
        console.log(FoodTypearr);
    }

    onFoodMealSelect(selectedList, selectedItem) {
        FoodMealarr.push(selectedItem.FoodMealTypeName)
        console.log(FoodMealarr);
    }

    onFoodMealRemove(selectedList, removedItem) {
        var index = FoodMealarr.indexOf(removedItem.FoodMealTypeName);
        FoodMealarr.splice(index, 1);
        console.log(FoodMealarr);
    }

    render() {


        return (
            <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                <Sidenavigation />
                <main className="body-content">
                    <Topnavigation />
                    <div className="ms-content-wrapper">
                        <form className="needs-validation clearfix" noValidate onSubmit={ this._handleSubmitEvent }>
                            <div className="row">
                                <div className="col-md-12">
                                    <Breadcrumb FormName="On Board screen" />
                                </div>


                                <div className="col-xl-6 col-md-12">
                                    <div className="ms-panel ms-panel-fh">
                                        <div className="ms-panel-header">
                                            <h6>On Board Screen</h6>
                                        </div>
                                        <div className="ms-panel-body">

                                            <div className="form-row">
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="DishName">Rasturant name</label>
                                                    <div className="input-group">
                                                        <input type="text" onChange={ this._handleChangeEvent } className="form-control" id="BussinessName" value={ this.state.FormData.BussinessName || '' } name="BussinessName" placeholder="Restaurant name" required />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="FoodTypeId">Cusine type</label>
                                                    <div className="input-group">
                                                        <Multiselect
                                                            options={ this.state.CuisineList } // Options to display in the dropdown
                                                            selectedValues={ CuisineTypearr } // Preselected value to persist in dropdown
                                                            onSelect={ this.onCusineSelect } // Function will trigger on select event
                                                            onRemove={ this.onCusineRemove } // Function will trigger on remove event
                                                            displayValue="CuisineName" // Property name to display in the dropdown options
                                                        />
                                                        <div className="invalid-feedback">
                                                            Please select a Food Type.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="FoodTypeId">Type of meal serving</label>
                                                    <div className="input-group">
                                                        <Multiselect
                                                            options={ this.state.FoodMealList } // Options to display in the dropdown
                                                            // selectedValues={ this.state.selectedValue } // Preselected value to persist in dropdown
                                                            onSelect={ this.onFoodMealSelect } // Function will trigger on select event
                                                            onRemove={ this.onFoodMealRemove } // Function will trigger on remove event
                                                            displayValue="FoodMealTypeName" // Property name to display in the dropdown options

                                                        />
                                                        <div className="invalid-feedback">
                                                            Please select a type of meal serving.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="FoodTypeId">Type of food serve</label>
                                                    <div className="input-group">
                                                        <Multiselect
                                                            options={ this.state.drpFoodType } // Options to display in the dropdown
                                                            selectedValues={ this.state.FoodTypearrSelect } // Preselected value to persist in dropdown
                                                            onSelect={ this.onFoodTypeSelect } // Function will trigger on select event
                                                            onRemove={ this.onFoodTypeRemove } // Function will trigger on remove event
                                                            displayValue="FoodTypeName" // Property name to display in the dropdown options
                                                        />


                                                    </div>
                                                </div>

                                            </div>

                                            <div className="form-row">
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="Address1">Address</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" value={ this.state.FormData.Address1 || '' } id="Address1" name="Address1" placeholder="Address" required onChange={ this._handleChangeEvent } ref={ this.refAddress } />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="Street">Street</label>
                                                    <input type="text" id="Street" name="Street" value={ this.state.FormData.Street || '' } className="form-control" onChange={ this._handleChangeEvent } required ref={ this.refStreet } />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="LandMark">Landmark</label>
                                                    <input type="text" id="LandMark" name="LandMark" value={ this.state.FormData.LandMark || '' } className="form-control" onChange={ this._handleChangeEvent } required ref={ this.refLandmark } />
                                                </div>


                                                {/* <div className="col-md-12 mb-3">
                                                    <label htmlFor="DishName">Country</label>
                                                    <input type="text" id="txtCountry" className="form-control" />
                                                </div> */}
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="City">City</label>
                                                    <input type="text" id="City" name="City" value={ this.state.FormData.City || '' } className="form-control" onChange={ this._handleChangeEvent } required ref={ this.refCity } />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="State">State</label>
                                                    <input type="text" id="State" name="State" value={ this.state.FormData.State || '' } className="form-control" onFocus={ this._handleChangeEvent } required ref={ this.refState } />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="State">Country</label>
                                                    <input type="text" id="Country" name="Country" value={ this.state.FormData.Country || '' } className="form-control" onFocus={ this._handleChangeEvent } required ref={ this.refState } />
                                                </div>



                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="Pincode">Zip code</label>
                                                    <input type="text" id="Pincode" name="Pincode" className="form-control" onChange={ this._handleChangeEvent } required ref={ this.refPinCode } value={ this.state.FormData.Pincode || '' } />
                                                    <input type="hidden" id="Latitude" name="Latitude" className="form-control" ref={ this.refLat } />
                                                    <input type="hidden" id="Longitude" name="Longitude" className="form-control" ref={ this.refLong } />
                                                </div>

                                            </div>

                                            <div className="form-row">
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="BussinessEmail">Rasturant email</label>
                                                    <div className="input-group">
                                                        <input type="text" onChange={ this._handleChangeEvent } value={ this.state.FormData.BussinessEmail || '' } className="form-control" id="BussinessEmail" name="BussinessEmail" placeholder="Bussiness email" required />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="BussinessMobileNo">Business mobile</label>
                                                    <div className="input-group">
                                                        <input type="text" pattern="^[0-9]+$" minLength="10" maxLength="10" onChange={ this._handleChangeEvent } value={ this.state.FormData.BussinessMobileNo || '' } className="form-control" id="BussinessMobileNo" name="BussinessMobileNo" placeholder="Bussiness mobile no." />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="ms-panel">
                                                <div className="ms-panel-header">
                                                    <h6>Restaurant Image </h6>
                                                </div>
                                                <div className="ms-panel-body" style={ { margin: "auto" } } id="previews">
                                                    {/* <Productslider /> */ }
                                                    <img className="d-block" style={ { margin: "auto" } } src="../assets/img/costic/customer-2.jpg" alt="First slide" />

                                                </div>
                                                <div className="ms-panel-header new" >
                                                    <div className="col-md-12 mb-3">
                                                        <label htmlFor="validationCustom12">Upload File</label>
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input" id="validatedCustomFile" onChange={ e => this.setFile(e) } style={ { display: 'block' } } />
                                                            <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Images...</label>
                                                            <div className="invalid-feedback">Example invalid custom file feedback</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="ms-panel-header new">
                                                    <div className="col-md-12 mb-3">
                                                        <label htmlFor="BussinessEmail">About me</label>
                                                        <div className="input-group">
                                                            <textarea onChange={ this._handleChangeEvent } className="form-control" value={ this.state.FormData.AbountMe || '' } id="AbountMe" name="AbountMe" placeholder="" required > </textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ms-panel-header new">
                                                    <div className="col-md-12 mb-3">
                                                        <label htmlFor="CookingSpecility">Cooking speciality</label>
                                                        <div className="input-group">
                                                            <textarea onChange={ this._handleChangeEvent } className="form-control" value={ this.state.FormData.CookingSpecility || '' } id="CookingSpecility" name="CookingSpecility" placeholder="" required ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ms-panel-header new">
                                                    <button className="btn btn-primary d-block" type="submit">Save and Add</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </form>
                    </div>

                </main>
                <Quickbar />
            </div>
        )
    }
}

export default OnBoardScreen;