import React, { Component } from 'react';
import { GetSessionData } from '../../../GlobalFactory';
import ApiInstance from '../../../Utilities/axiosConfig';
import Swal from 'sweetalert2'
import { renderIntoDocument } from 'react-dom/test-utils';
import LoaderComponent from '../../../components/LoaderComponent';
import Config from '../../../Config';
import { ChangeDateFormatToLocal } from '../../../GlobalFactory';
export default class PersonalDetails extends Component {

    constructor () {
        super();

        this.state = {

            RegistrationData: {},
            IsEmailExist: false,
            IsUserNameExist: false,
            IsMobileExist: false,

            file: '',
            ActiveLoader: false,
            filebtnvisibity: false
        }


        this._handleChangeEvent = this.ItemChangeEvent.bind(this);
        this._checkboxChangeHandler = this.checkboxChangeEvent.bind(this)
        this.sweetalerthtml = this.sweetalerthtml.bind(this);
        this._handleSubmitEvent = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        //initialize datatable
        function formValidation() {
            window.addEventListener('load', function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function (form) {
                    form.addEventListener('submit', function (event) {


                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add('was-validated');
                        }
                        else {
                            form.classList.remove('was-validated');
                        }
                    }, false);
                });

            }, false);
        }
        formValidation();


        this.GetCookDetails();
    }

    GetCookDetails() {

        ApiInstance().get('CookMaster/GetCookMasterProfileData?CookId=' + GetSessionData().UserId)
            .then(response => {

                var data = JSON.parse(response.data.data);
                console.log(data)
                if (data) {
                    console.log(data)
                    this.setState({ RegistrationData: data.Data[0] })
                }
                else {
                }
            }, error => {
                console.log(error);
            });
    }

    ItemChangeEvent = event => {

        const value = event.target.value;
        const key = event.target.name;

        if (key === 'FirstName' || key === 'LastName') {
            var regex = /^[a-zA-Z ]*$/;
            if (value.match(regex)) {

                let RegistrationData = this.state.RegistrationData;
                RegistrationData[key] = value;

                this.setState({
                    RegistrationData: RegistrationData
                });
            }
        }
        else if (key === "InsuranceNumber") {
            var regex = /^[a-zA-Z0-9]*$/;
            if (value.match(regex)) {

                let RegistrationData = this.state.RegistrationData;
                RegistrationData[key] = value;

                this.setState({
                    RegistrationData: RegistrationData
                });
            }
        }
        else if (key === 'MobileNo') {
            var regex = /^[0-9]*$/;

            if (value.match(regex)) {
                let RegistrationData = this.state.RegistrationData;
                RegistrationData[key] = value;

                this.setState({
                    RegistrationData: RegistrationData
                });
            }

        }
        else if (key === 'EmailId') {
            if (this.validateEmail()) {
                let RegistrationData = this.state.RegistrationData;
                RegistrationData[key] = value;

                this.setState({
                    RegistrationData: RegistrationData
                });
            }
        }
        else {


            let RegistrationData = this.state.RegistrationData;
            RegistrationData[key] = value;

            this.setState({
                RegistrationData: RegistrationData
            });

        }
    }

    resetFormData = () => {

        const clonedState = this.state.RegistrationData;
        const keys = Object.keys(clonedState);
        keys.forEach(key => (delete clonedState[key]));

        this.setState({
            RegistrationData: {},
            IsEmailExist: false,
            IsUserNameExist: false,
            IsMobileExist: false
        });

        var forms = document.getElementsByClassName('needs-validation');
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.classList.remove('was-validated');
        }, false);

    }

    sweetalerthtml() {
        Swal.fire({
            title: '<spam>Success!</spam>',
            icon: '',
            html: 'Your profile has been updated successfully!',
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            cancelButtonText: 'Close',
            cancelButtonAriaLabel: 'Thumbs down'
        }).then(function (result) {
            if (result.value) {
                window.location.reload();
                //this.resetFormData();
            }
        });

    }


    checkboxChangeEvent(event) {

        const value = event.target.checked;
        const key = event.target.name;

        this.setState({
            TermsAndCondition: value
        }, () => { console.log(this.state.TermsAndCondition) });

    }


    setFile(e) {
        if (this.validate(e.target)) {
            this.setState({ file: e.target.files[0] });
            console.log(e.target.files[0]);
        }
    }

    validate(file) {
        console.log(file);

        var valid = true;
        var file_name = "";
        var file_type = "";
        var file_size = "";
        var error_msg = "";
        var valid_size = 5 * 1000 * 1000;
        var display_error = document.getElementById('file_error');
        console.log(file.files.length)


        if (file.files.length != 0) {
            file_name = file.files[0].name;
            file_size = file.files[0].size;
            file_type = file.files[0].type;
            console.log(file_type)
            if (file_type != "image/png" && file_type != "image/jpeg" && file_type != "application/pdf" && file_type != "image/bmp") {
                valid = false;
                error_msg = error_msg + "\n* Only 'PNG', 'JPG/JPEG','BMP' and 'PDF' file type supported.";
            }
            if (file_size > valid_size) {
                valid = false;
                error_msg = error_msg + "\n* Filesize should be upto 5MB.";
            }
        }
        else {
            valid = false;

            error_msg = error_msg + "\n* Please select any image file.";
        }

        if (valid == true) {
            display_error.innerHTML = "";

            console.log(valid)
            /*Write ajax code here to send file to the server.*/
            return true;
        }
        else {
            console.log(valid)
            display_error.innerHTML = error_msg;
            return false;
        }
    }

    handleSubmit = event => {
        event.preventDefault()

        this.setState({
            IsEmailExist: false,
            IsUserNameExist: false,
            IsMobileExist: false

        })

        if (event.target.checkValidity()) {
            this.setState({ ActiveLoader: true })
            const formData = new FormData();
            formData.append("InsurancePhotos", this.state.file);

            let rdata = this.state.RegistrationData;


            formData.append("data", JSON.stringify(rdata));

            ApiInstance().post("CookMaster/UpdateCookProfileForOnBoarding", formData, { headers: { 'Content-Type': 'multipart/form-data', } })
                .then(response => {
                    let data = response.data;
                    console.log(data);
                    if (data.status) {
                        this.sweetalerthtml();
                    }
                    else {
                        if (data.data.toLowerCase().includes("username")) {
                            this.setState({ IsUserNameExist: true }, () => { })
                        }

                        if (data.data.toLowerCase().includes("email")) {
                            this.setState({ IsEmailExist: true }, () => { })
                        }

                        if (data.data.toLowerCase().includes("mobileno")) {
                            this.setState({ IsMobileExist: true }, () => { })
                        }
                    }

                    this.setState({ ActiveLoader: false })

                }, error => {
                    //window.init_PNotify("Error", "error", "Record not updated!!");
                    this.setState({ ActiveLoader: false })
                    console.log(error);
                });



        }
        else {
            console.log(event.target.checkValidity())
        }

    }

    validateEmail() {

        var strongRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        var txtEmail = document.getElementById("EmailId");

        if (!strongRegex.test(txtEmail.value)) {
            document.getElementById("ValidateEmail").innerHTML = "Invalid email address";
            return false;
        }
        else {
            document.getElementById("ValidateEmail").innerHTML = "";
            return true;
        }

    }


    onKeyPress(event) {
        return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122));
    }


    render() {
        console.log(ChangeDateFormatToLocal(this.state.RegistrationData.InsuranceExpiryDate))

        return (
            <><LoaderComponent ActiveLoader={ this.state.ActiveLoader }></LoaderComponent>
                <form className="needs-validation" noValidate onSubmit={ this._handleSubmitEvent } id="registrationform">
                    {/* <p style={ { fontSize: "18px", fontWeight: 'bold' } }>Personal Information : </p>
                                    <hr /> */}
                    <div className="form-row">
                        <div className="col-md-6 ">
                            <label htmlFor="FristName">First name <span className="required">*</span></label>
                            <div className="input-group">
                                <input type="text" readOnly className="form-control" pattern="^[a-zA-Z]+$" id="FirstName" value={ this.state.RegistrationData.FirstName || '' } name="FirstName" placeholder="John" required onChange={ this._handleChangeEvent } minLength="2" maxLength="15" />
                                <div className="invalid-feedback">
                                    Please enter first name.
                                              </div>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <label htmlFor="LastName">Last name <span className="required">*</span></label>
                            <div className="input-group">
                                <input type="text" readOnly className="form-control" pattern="^[a-zA-Z]+$" id="LastName" name="LastName" value={ this.state.RegistrationData.LastName || '' } placeholder="Doe" required onChange={ this._handleChangeEvent } minLength="2" maxLength="15" />

                                <div className="invalid-feedback">
                                    Please enter last name.
                                              </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-6 ">
                            <label htmlFor="EmailId">Email address <span className="required">*</span></label>
                            <div className="input-group">
                                <input type="email" readOnly className="form-control" id="EmailId" name="EmailId" value={ this.state.RegistrationData.EmailId || '' } placeholder="johndoe@xyz.com" required onChange={ this._handleChangeEvent } />
                                { !this.state.IsEmailExist && <div className="invalid-feedback">
                                    Please enter valid email.
                                            </div>
                                }

                                { this.state.IsEmailExist && <div className="invalid-feedback d-block">
                                    Email Id already exist.
                                            </div> }
                                <div className="invalid-feedback d-block" id="ValidateEmail"> </div>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <label htmlFor="MobileNo">Mobile number <span className="required">*</span></label>
                            <div className="input-group">
                                <input type="text" readOnly minLength="10" maxLength="10" className="form-control" id="MobileNo" pattern="^[0-9]+$" value={ this.state.RegistrationData.MobileNo || '' } name="MobileNo" placeholder="999 999 9999" required onChange={ this._handleChangeEvent } />
                                { !this.state.IsMobileExist && <div className="invalid-feedback">
                                    Please enter number.
                                                </div>
                                }
                                { this.state.IsMobileExist && <div className="invalid-feedback d-block">
                                    Mobile Number already exist.
                                            </div> }

                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-6 ">
                            <label htmlFor="UserName">User name <span className="required">*</span></label>
                            <div className="input-group">
                                <input type="text" readOnly minLength="4" maxLength="15" pattern="^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,18}[a-zA-Z0-9]$" className="form-control" id="UserName" name='UserName' value={ this.state.RegistrationData.UserName || '' } placeholder="JohnDoe" required onChange={ this._handleChangeEvent } />
                            </div>
                        </div>

                    </div>
                    <br></br>
                    {/* <p style={ { fontSize: "18px", fontWeight: 'bold' } }>Insurance Details : </p>
                                <hr /> */}
                    <div className="form-row">
                        <div className="col-md-6 ">
                            <label htmlFor="InsuranceProvider">Insurance provider <span className="required">*</span></label>
                            <div className="input-group">
                                <input type="text" className="form-control" id="InsuranceProvider" name='InsuranceProvider' value={ this.state.RegistrationData.InsuranceProvider || '' } placeholder="XYZ Ltd." required onChange={ this._handleChangeEvent } maxLength="20" />
                                <div className="invalid-feedback">
                                    Please enter insurance provider.
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 ">
                            <label htmlFor="InsuranceNumber">Insurance card number <span className="required">*</span></label>
                            <div className="input-group">
                                <input type="text" className="form-control" id="InsuranceNumber" name='InsuranceNumber' placeholder="ABC56789" value={ this.state.RegistrationData.InsuranceNumber || '' } required onChange={ this._handleChangeEvent } maxLength="20" />
                                <div className="invalid-feedback">
                                    Please enter insurance card number.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-6 ">
                            <label htmlFor="InsuranceExpiryDate">Insurance expiry date<span className="required">*</span></label>
                            <div className="input-group">
                                <input type="date" className="form-control" id="InsuranceExpiryDate" name='InsuranceExpiryDate' value={ this.state.RegistrationData.InsuranceExpiryDate || '' } required onChange={ this._handleChangeEvent } max='9999-12-31' />
                                <div className="invalid-feedback">
                                    Please enter insurance expiry date.
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <label htmlFor="InsuranceFile">Insurance Document <span className="required">*</span></label>
                            <div className="input-group">
                                { !this.state.filebtnvisibity && <div className="col-md-12">
                                    <a href={ (this.state.RegistrationData.InsuranceDocument) ? (Config.BaseURL + this.state.RegistrationData.InsuranceDocument) : "" } target="_blank">Download</a>
                                    <a href="#" onClick={ () => { this.setState({ filebtnvisibity: true }) } } style={ { float: "right" } }>Change</a>
                                </div>
                                }
                                { this.state.filebtnvisibity && <div className="custom-file col-md-12">
                                    <input type="file" className="custom-file-input" onChange={ e => this.setFile(e) } required id="txtfile" />
                                    <label className="custom-file-label" htmlFor="validatedCustomFile">Upload File (pdf, bmp, jpeg/jpg, png) </label>
                                    <div className="invalid-feedback">  Please select file.   </div>
                                    <div id="file_error" className="invalid-feedback d-block"></div>
                                </div>
                                }

                            </div>



                        </div>
                    </div>
                    <br></br>
                    <div className="form-row">
                        <div className="col-md-6 ">
                            <label htmlFor="BankName">Bank Name <span className="required">*</span></label>
                            <div className="input-group">
                                <input type="text" className="form-control" id="BankName" name='BankName' value={ this.state.RegistrationData.BankName || '' } placeholder="" required onChange={ this._handleChangeEvent } minLength="2" maxLength="25" />
                                <div className="invalid-feedback">
                                    Please enter Bank Name.
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 ">
                            <label htmlFor="BranchName">Branch Name <span className="required">*</span></label>
                            <div className="input-group">
                                <input type="text" className="form-control" id="BranchName" name='BranchName' placeholder="" value={ this.state.RegistrationData.BranchName || '' } required onChange={ this._handleChangeEvent } minLength="2" maxLength="25" />
                                <div className="invalid-feedback">
                                    Please enter branch.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-6 ">
                            <label htmlFor="AccountHolderName">Account Holder Name <span className="required">*</span></label>
                            <div className="input-group">
                                <input type="text" className="form-control" id="AccountHolderName" name='AccountHolderName' value={ this.state.RegistrationData.AccountHolderName || '' } placeholder="" required onChange={ this._handleChangeEvent } minLength="2" maxLength="50" />
                                <div className="invalid-feedback">
                                    Please enter account holder name.
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 ">
                            <label htmlFor="AccountName">Account number <span className="required">*</span></label>
                            <div className="input-group">
                                <input type="number" className="form-control" id="AccountName" name='AccountName' placeholder="ABC56789" value={ this.state.RegistrationData.AccountName || '' } required onChange={ this._handleChangeEvent } minLength="2" maxLength="30" />
                                <div className="invalid-feedback">
                                    Please enter account number.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-6 ">
                            <label htmlFor="IFSCCode">Routing Number/Swift Code <span className="required">*</span></label>
                            <div className="input-group">
                                <input type="text" className="form-control" id="IFSCCode" name='IFSCCode' value={ this.state.RegistrationData.IFSCCode || '' } placeholder="" required onChange={ this._handleChangeEvent } minLength="2" maxLength="20" />
                                <div className="invalid-feedback">
                                    Please enter account Routing Number/Swift Code.
                                </div>
                            </div>
                        </div>


                    </div>
                    <button className="btn btn-primary mt-4 d-block w-20" style={ { margin: "0 auto" } } type="submit" >Save</button>

                </form>
            </>
        )
    }
}
