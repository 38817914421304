import React from 'react'
import ApiInstance from './Utilities/axiosConfig';

export const SetSessionData = () => {

  ApiInstance().get('Login/GetSessionData')
    .then(response => {

      localStorage.setItem("SessionData", JSON.stringify(response.data));

    }, error => {
      console.log(error);
    });

};

export const GetSessionData = () => JSON.parse(localStorage.getItem("tokenData"));


export const GetLinkedData = async (LinkedTabel, LinkedKeyColumn, LinkedValueColumn, FilterExpression) => {

  let para = {
    "tablename": LinkedTabel,
    "key": LinkedKeyColumn,
    "value": LinkedValueColumn,
    "filterExpression": FilterExpression
  };
  let jsondata = [];
  await ApiInstance().post('DataSource/GetLinkedData', para, { headers: { 'Content-Type': 'application/json' } })
    .then(function (response) {
      if (response.data) {
        jsondata = JSON.parse(response.data);


      }

    }, error => {
      console.log(error);
    });

  return jsondata;
}

export const ChangeDateFormatToLocal = (datevalue) => {
  return (datevalue != undefined) ? datevalue.toString().substr(0, 10) : "";
}

export const TodaysDate = () => {

  var monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

  var date = new Date()
  //date.setDate(date.getDate())
  var Todaydate_YYYYMMMDD = date.getFullYear() + "-" + monthNames[date.getMonth()] + "-" + date.getDate();
  var Todaydate_YYYYMMDD = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

  console.log(Todaydate_YYYYMMMDD);
  console.log(Todaydate_YYYYMMDD);
  return {
    Todaydate_YYYYMMMDD: Todaydate_YYYYMMMDD,
    Todaydate_YYYYMMDD: Todaydate_YYYYMMDD
  }
}
export const DateFromLastMonth = () => {

  var monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

  var date = new Date()
  date.setMonth(date.getMonth() - 1)

  var PrevMonthdate_YYYY_MMM_DD = date.getFullYear() + "-" + monthNames[date.getMonth()] + "-" + date.getDate();
  var PrevMonthdate_YYYY_MM_DD = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()

  console.log(PrevMonthdate_YYYY_MMM_DD)
  console.log(PrevMonthdate_YYYY_MM_DD)
  document.getElementById("dtTo").value = PrevMonthdate_YYYY_MMM_DD
  document.getElementById("dtFrom").value = PrevMonthdate_YYYY_MM_DD

  return {
    PrevMonthdate_YYYY_MMM_DD: PrevMonthdate_YYYY_MMM_DD,
    PrevMonthdate_YYYY_MM_DD: PrevMonthdate_YYYY_MM_DD
  }
}
