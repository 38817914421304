import React, { Component } from 'react';
import ApiInstance from '../../../Utilities/axiosConfig';
import $ from 'jquery';
import { GetSessionData } from '../../../GlobalFactory';
import { ToastMessage } from '../../../Utilities/ToastMessage'
import MultiSelectComponent from './MultiSelectComponent';
import Config from '../../../Config';
import PlaceAutoComplete from '../../../components/PlaceAutoComplete';
import Swal from 'sweetalert2'


class OnBoardScreen extends Component {


    constructor () {
        super()
        this.file = '';
        this.state = {
            FormData: {
                CookId: '',
                Address1: '',
                LandMark: '',
                State: '',
                City: '',
                Country: '',
                Pincode: '',
                BussinessName: '',
                BussinessMobileNo: '',
                ProfilePhoto: '',
                AbountMe: '',
                CookingSpecility: '',
                Latitude: '',
                Longitude: '',
                CuisineType: [],
                MealType: [],
                BussinessEmail: '',
                FoodType: []
            },
            // file: '',
            FoodTypeList: [],
            CuisineList: [],
            FoodMealList: [],
            FoodTypearrSelect: [],
            FoodMealarrSelect: [],
            CuisinearrSelect: []

        }

        this._handleChangeEvent = this.ItemChangeEvent.bind(this);
        // this.refAddress = React.createRef();
        // this.refStreet = React.createRef();
        // this.refLandmark = React.createRef();
        // this.refCity = React.createRef();
        // this.refState = React.createRef();
        // this.refCountry = React.createRef();
        // this.refPinCode = React.createRef();
        // this.refLat = React.createRef();
        // this.refLong = React.createRef();

        this.refFoodType = React.createRef();
        this.refFoodMealType = React.createRef();
        this.refCuisine = React.createRef();
        // this._ItemCheckedEvent = this.ItemCheckedEvent.bind(this);

        this._handleSubmitEvent = this.SubmitData.bind(this);

        this._setFile = this.setFile.bind(this);

    }

    componentDidMount() {

        function formValidation() {
            window.addEventListener('load', function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function (form) {
                    form.addEventListener('submit', function (event) {
                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add('was-validated');
                        }
                        else {
                            form.classList.remove('was-validated');
                        }
                    }, false);
                });
            }, false);
        }
        formValidation();

        this.GetFoodType();
        this.GetCuisineList();
        this.GetFoodMealList();

        setTimeout(() => {
            this.GetCookDetails();
        }, 3000);

    }



    GetCookDetails() {

        ApiInstance().get('CookMaster/GetCookMasterProfileData?CookId=' + GetSessionData().UserId)
            .then(response => {

                var data = JSON.parse(response.data.data);

                if (data) {
                    console.log(data)
                    var FoodTypearrSelect = this.state.FoodTypearrSelect;
                    var FoodMealarrSelect = this.state.FoodMealarrSelect;
                    var CuisinearrSelect = this.state.CuisinearrSelect;
                    if (data.Data[0].FoodType) {
                        data.Data[0].FoodType.split(',').map(e => {
                            let obj = this.state.drpFoodType.find(e1 => e1.FoodTypeName == e)
                            FoodTypearrSelect.push(obj)
                        }
                        )
                    }

                    if (data.Data[0].MealType) {
                        data.Data[0].MealType.split(',').map(e => {
                            let obj = this.state.FoodMealList.find(e1 => e1.FoodMealTypeName == e)
                            FoodMealarrSelect.push(obj)
                        }
                        )
                    }
                    if (data.Data[0].CuisineType) {
                        data.Data[0].CuisineType.split(',').map(e => {
                            let obj = this.state.CuisineList.find(e1 => e1.CuisineName == e)
                            CuisinearrSelect.push(obj)
                        }
                        )
                    }

                    FoodTypearrSelect = { selectedValues: FoodTypearrSelect, objectArray: this.state.drpFoodType, displayValue: "FoodTypeName" }
                    FoodMealarrSelect = { selectedValues: FoodMealarrSelect, objectArray: this.state.FoodMealList, displayValue: "FoodMealTypeName" }
                    CuisinearrSelect = { selectedValues: CuisinearrSelect, objectArray: this.state.CuisineList, displayValue: "CuisineName" }
                    this.setAddressObject(data.Data[0])
                    this.setState({ FormData: data.Data[0], FoodTypearrSelect: FoodTypearrSelect, FoodMealarrSelect: FoodMealarrSelect, CuisinearrSelect: CuisinearrSelect })
                }
                else {
                }
            }, error => {
                console.log(error);
            });
    }


    GetFoodType() {
        ApiInstance().get('FoodTypeMaster/SelectAlltblFoodTypeMaster')
            .then(response => {
                var data = response.data.data;
                if (data) {
                    var objdata = JSON.parse(data)


                    this.setState({
                        drpFoodType: objdata.Data
                    });
                }
            }, error => {
                console.log(error);
            });
    }
    GetFoodMealList() {

        ApiInstance().get('FoodMealTypeMaster/SelectAlltblFoodMealTypeMaster')
            .then(response => {

                var data = response.data.data;


                if (data) {

                    let dataObject = JSON.parse(response.data.data);

                    this.setState({ FoodMealList: dataObject.Data });
                }
                else {
                }
            }, error => {
                console.log(error);
            });
    }
    GetCuisineList() {

        ApiInstance().get('CuisineMaster/SelectAlltblCuisineMaster')
            .then(response => {

                var data = response.data.data;
                if (data) {
                    let dataObject = JSON.parse(response.data.data);
                    this.setState({ CuisineList: dataObject.Data })
                }

            }, error => {
                console.log(error);
            });
    }


    setFile(e) {
        e.preventDefault();
        // var previews = $("#previews");
        // previews.empty();
        // Array.prototype.slice.call(e.target.files)
        //     .forEach(function (file, idx) {
        //         var reader = new FileReader();
        //         reader.onload = function (event) {
        //             $("<img />", {
        //                 "src": event.target.result,
        //                 "class": 'd-block w-100'
        //             }).appendTo(previews);
        //         };
        //         reader.readAsDataURL(file);
        //     });
        this.file = e.target.files[0]
        //this.setState({ file: e.target.files[0] }, () => { console.log(this.state.file) });
        var image = document.getElementById('previewimage');
        image.src = URL.createObjectURL(e.target.files[0]);

        var imputfilename = document.getElementById("imputfilename");
        imputfilename.innerText = e.target.files[0].name;

    }

    ItemChangeEvent = event => {


        const value = event.target.value;
        const key = event.target.name;

        if (key === 'BussinessMobileNo') {
            var regex = /^[0-9]*$/;

            if (value.match(regex)) {
                let FormData = this.state.FormData;
                FormData[key] = value;

                this.setState({
                    FormData: FormData
                });
            }

        }
        else {
            let FormData = this.state.FormData;
            FormData[key] = value;
            this.setState({
                FormData: FormData
            });
        }

    }

    ItemCheckedEvent = event => {
        const value = event.target.checked;
        const key = event.target.name;
        let FormData = this.state.FormData;
        FormData[key] = value;
        this.setState({
            FormData: FormData
        }, console.log(this.state.FormData));

    }

    setAddressObject(obj) {
        if (obj) {

            document.getElementById("Address1").value = (obj.Address1 !== undefined) ? obj.Address1 : '';
            document.getElementById("LandMark").value = (obj.LandMark) ? obj.LandMark : '';
            document.getElementById("State").value = (obj.State) ? obj.State : '';
            document.getElementById("City").value = (obj.City) ? obj.City : '';
            document.getElementById("Pincode").value = (obj.Pincode) ? obj.Pincode : '';
            document.getElementById("Latitude").value = (obj.Latitude) ? obj.Latitude : '';
            document.getElementById("Longitude").value = (obj.Longitude) ? obj.Longitude : '';
            document.getElementById("Street").value = (obj.Street) ? obj.Street : '';
            document.getElementById("Country").value = (obj.Country) ? obj.Country : '';
        }
    }
    getAddressObject(obj) {

        obj.Address1 = document.getElementById("Address1").value
        obj.LandMark = document.getElementById("LandMark").value;
        obj.State = document.getElementById("State").value;
        obj.City = document.getElementById("City").value;
        obj.Pincode = document.getElementById("Pincode").value;
        obj.Latitude = document.getElementById("Latitude").value;
        obj.Longitude = document.getElementById("Longitude").value;
        obj.Street = document.getElementById("Street").value;
        obj.Country = document.getElementById("Country").value;
        return obj;
    }

    SubmitData = event => {



        event.preventDefault();
        var FilledData = this.state.FormData
        FilledData.CookId = GetSessionData().UserId;

        FilledData.FoodType = this.refFoodType.current.GetSelectedType().map(val => val.FoodTypeName).join();  //FoodTypearr.join();
        FilledData.MealType = this.refFoodMealType.current.GetSelectedType().map(val => val.FoodMealTypeName).join();//FoodMealarr.join();
        FilledData.CuisineType = this.refCuisine.current.GetSelectedType().map(val => val.CuisineName).join(); //CuisineTypearr.join();



        FilledData.Address1 = document.getElementById("Address1").value
        FilledData.LandMark = document.getElementById("LandMark").value;
        FilledData.State = document.getElementById("State").value;
        FilledData.City = document.getElementById("City").value;
        FilledData.Pincode = document.getElementById("Pincode").value;
        FilledData.Latitude = document.getElementById("Latitude").value;
        FilledData.Longitude = document.getElementById("Longitude").value;
        FilledData.Street = document.getElementById("Street").value;
        FilledData.Country = document.getElementById("Country").value;


        // FilledData = this.getAddressObject(FilledData)

        console.log(FilledData)


        if (event.target.checkValidity()) {

            const formData = new FormData();
            formData.append("data", JSON.stringify(FilledData));
            formData.append("ProfilePhoto", this.file);

            ApiInstance().post('CookMaster/UpdateCookProfileForOnBoarding', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(response => {
                    var data = response.data;
                    if (data.message == "Success") {
                        ToastMessage('Success', 'Record has been updated successfully', 'success')
                        this.sweetalerthtml()
                        //window.location.href = '../Cook/Dashboard';
                    }
                    else {
                        ToastMessage('Error', 'Error in submit', 'danger')
                    }
                    console.log(response);


                }, error => {
                    console.log(error);
                });

        }
    }

    initAutocompleteHome = (place) => {
        console.log(place)
        let placeSearch;
        let autocomplete;
        const componentForm = {
            street_number: "short_name",
            route: "long_name",
            locality: "long_name",
            administrative_area_level_1: "short_name",
            country: "long_name",
            postal_code: "short_name",
            lat: "lat",
            lng: "lng",
        };

        const googlePlaceMapwithform = {
            Street: "street_number",
            LandMark: "route",
            City: "locality",
            State: "administrative_area_level_1",
            Country: "country",
            Pincode: "postal_code",
            Latitude: "lat",
            Longitude: "lng",
        };
        console.log(place)

        for (const component in googlePlaceMapwithform) {

            document.getElementById(component).value = "";
            document.getElementById(component).disabled = false;
        }

        //var lat = place.geometry.location.lat();
        //var lng = place.geometry.location.lng(); 
        document.getElementById("Latitude").value = place.geometry.location.lat();
        document.getElementById("Longitude").value = place.geometry.location.lng();


        for (const component of place.address_components) {
            const addressType = component.types[0];
            if (componentForm[addressType]) {
                var fildid = Object.keys(googlePlaceMapwithform).find(key => googlePlaceMapwithform[key] === addressType)
                const val = component[componentForm[addressType]];
                document.getElementById(fildid).value = val;

            }

            document.getElementById("Address1").value = place.formatted_address + " " + document.getElementById("Pincode").value
        }
    }

    sweetalerthtml() {
        Swal.fire({
            title: '<spam>Success!</spam>',
            icon: '',
            html: 'Restaurant details has been updated successfully!',
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            cancelButtonText: 'Close',
            cancelButtonAriaLabel: 'Thumbs down'
        }).then(function (result) {
            if (result.value) {
                window.location.reload();
                //this.resetFormData();
            }
        });

    }
    render() {
        return (

            <form className="needs-validation clearfix" noValidate onSubmit={ this._handleSubmitEvent }>
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-header">
                                <h6>About Restaurant</h6>
                            </div>
                            <div className="ms-panel-body">
                                <div className="form-row">
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="DishName">Rastaurant name</label>
                                        <div className="input-group">
                                            <input type="text" onChange={ this._handleChangeEvent } className="form-control" id="BussinessName" value={ this.state.FormData.BussinessName || '' } name="BussinessName" placeholder="Restaurant name" required />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="FoodTypeId">Cuisine type</label>
                                        <div className="input-group">

                                            <MultiSelectComponent dataobject={ this.state.CuisinearrSelect } ref={ this.refCuisine }></MultiSelectComponent>

                                            <div className="invalid-feedback">
                                                Please select a Cuisine Type.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="FoodTypeId">Type of meal serving</label>
                                        <div className="input-group">

                                            <MultiSelectComponent dataobject={ this.state.FoodMealarrSelect } ref={ this.refFoodMealType }></MultiSelectComponent>
                                            <div className="invalid-feedback">
                                                Please select a type of meal serving.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="FoodTypeId">Type of food serve</label>
                                        <div className="input-group">


                                            <MultiSelectComponent dataobject={ this.state.FoodTypearrSelect } ref={ this.refFoodType } ></MultiSelectComponent>
                                            <div className="invalid-feedback">
                                                Please select a Food Type.
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="form-row">
                                    {/* <div className="col-md-12 mb-3">
                                        <label htmlFor="Address1">Address</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={ this.state.FormData.Address1 || '' } id="Address1" name="Address1" placeholder="Address" required onChange={ this._handleChangeEvent } onKeyPress={ (e) => { if (e.key === 'Enter') e.preventDefault(); } } ref={ this.refAddress } />
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="Street">Street</label>
                                        <input type="text" id="Street" name="Street" value={ this.state.FormData.Street || '' } className="form-control" ref={ this.refStreet } />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="LandMark">Landmark</label>
                                        <input type="text" id="LandMark" name="LandMark" value={ this.state.FormData.LandMark || '' } className="form-control" ref={ this.refLandmark } />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="City">City</label>
                                        <input type="text" id="City" name="City" value={ this.state.FormData.City || '' } className="form-control" ref={ this.refCity } />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="State">State</label>
                                        <input type="text" id="State" name="State" value={ this.state.FormData.State || '' } className="form-control" ref={ this.refState } />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="State">Country</label>
                                        <input type="text" id="Country" name="Country" value={ this.state.FormData.Country || '' } className="form-control" ref={ this.refCountry } />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="Pincode">Zip code</label>
                                        <input type="text" id="Pincode" name="Pincode" className="form-control" ref={ this.refPinCode } value={ this.state.FormData.Pincode || '' } />
                                        <input type="hidden" id="Latitude" name="Latitude" className="form-control" value={ this.state.FormData.Latitude || '' } ref={ this.refLat } />
                                        <input type="hidden" id="Longitude" name="Longitude" className="form-control" value={ this.state.FormData.Longitude || '' } ref={ this.refLong } />
                                    </div> */}

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="text-light-black fw-700">Find Location</label>
                                            <PlaceAutoComplete SetPlace={ this.initAutocompleteHome }></PlaceAutoComplete>

                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="text-light-black fw-700">Full Address</label>
                                            <input type="text" name="Address1" id="Address1" className="form-control form-control-submit" placeholder="Type Your Address" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="text-light-black fw-700">Street</label>
                                            <input type="text" name="Street" id="Street" className="form-control form-control-submit" placeholder="Street" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="text-light-black fw-700">Landmark</label>
                                            <input type="text" name="LandMark" id="LandMark" className="form-control form-control-submit" placeholder="Landmark" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="text-light-black fw-700">City</label>
                                            <input type="text" name="City" id="City" className="form-control form-control-submit" placeholder="City" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="text-light-black fw-700">State</label>
                                            <input type="text" name="State" id="State" className="form-control form-control-submit" placeholder="State" />
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="text-light-black fw-700">Country
                                                                                                        </label>
                                            <input type="text" name="Country" id="Country" className="form-control form-control-submit" placeholder="Country" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="text-light-black fw-700">Pincode
                                                                                                        </label>
                                            <input type="text" name="Pincode" id="Pincode" required className="form-control form-control-submit" placeholder="Pincode" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            {/* <label className="text-light-black fw-700">Latitude</label> */ }
                                            <input type="hidden" name="Latitude" id="Latitude" className="form-control form-control-submit" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            {/* <label className="text-light-black fw-700">Longitude</label> */ }
                                            <input type="hidden" name="Longitude" id="Longitude" className="form-control form-control-submit" />
                                        </div>
                                    </div>

                                </div>

                                <div className="form-row">

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="BussinessEmail">Restaurant email</label>
                                        <div className="input-group">
                                            <input type="email" onChange={ this._handleChangeEvent } value={ this.state.FormData.BussinessEmail || '' } className="form-control" id="BussinessEmail" name="BussinessEmail" placeholder="Bussiness email" required />
                                            <div className="invalid-feedback">
                                                Please provide email address.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="BussinessMobileNo">Business mobile</label>
                                        <div className="input-group">
                                            <input type="text" pattern="^[0-9]+$" minLength="10" maxLength="10" onChange={ this._handleChangeEvent } value={ this.state.FormData.BussinessMobileNo || '' } className="form-control" id="BussinessMobileNo" name="BussinessMobileNo" placeholder="Bussiness mobile no." />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <h6>Restaurant Image </h6>
                                    </div>
                                    <div className="ms-panel-body" style={ { margin: "auto", textAlign: "center" } } id="previews">
                                        {/* <Productslider /> */ }
                                        <img className="d-block" style={ { margin: "auto" } } src={ (this.state.FormData.ProfilePhoto) ? (Config.BaseURL + this.state.FormData.ProfilePhoto) : "../assets/img/costic/customer-2.jpg" } alt="First slide" id="previewimage" />

                                    </div>
                                    <div className="ms-panel-header new" >
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="validationCustom12">Upload File</label>
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="validatedCustomFile" onChange={ this._setFile } style={ { display: 'block' } } />
                                                <label className="custom-file-label" htmlFor="validatedCustomFile" id="imputfilename">Upload Images...</label>
                                                <div className="invalid-feedback">Example invalid custom file feedback</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ms-panel-header new">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="BussinessEmail">About me</label>
                                            <div className="input-group">
                                                <textarea onChange={ this._handleChangeEvent } className="form-control" value={ this.state.FormData.AbountMe || '' } id="AbountMe" name="AbountMe" placeholder="" required > </textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-panel-header new">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="CookingSpecility">Cooking speciality</label>
                                            <div className="input-group">
                                                <textarea onChange={ this._handleChangeEvent } className="form-control" value={ this.state.FormData.CookingSpecility || '' } id="CookingSpecility" name="CookingSpecility" placeholder="" required ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-panel-header new">
                                        <button className="btn btn-primary d-block" type="submit">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </form>




        )
    }
}

export default OnBoardScreen;