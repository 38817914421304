import React, { Component } from 'react'
import { Modal, Accordion, Card } from "react-bootstrap";
import ApiInstance from '../../../Utilities/axiosConfig';

export default class DishAddOns extends Component {
    constructor (props) {
        super(props);
        this.state = {
            rowData: {
                DishId: '',
                DishAddOnsId: '0',
                AddOnsName: "",
                FoodTypeId: "",
                AddOnsCharges: 0
            },
            FoodTypeList: [],
            show15: false
        }
        this.id = this.props.id;
        this._handleAddAddOnsEvent = this.AddAddOnsEvent.bind(this);
        this._handleAddOnsChangeEvent = this.AddOnsItemChangeEvent.bind(this);
    }

    componentDidMount() {
        this.GetFoodTypeList();

    }

    handleClose() {
        this.setState({ show15: false });
    }
    handleShowAddOns() {
        this.setState({ show15: true });
    }
    AddOnsItemChangeEvent = event => {
        const value = event.target.value;
        const key = event.target.name;

        let rowData = this.state.rowData;
        rowData[key] = value;

        this.setState({
            rowData: rowData
        });

    }
    AddAddOnsEvent = (event) => {
        event.preventDefault();

        let rowData = this.state.rowData;
        console.log(rowData);
        rowData.DishId = this.id;
        this.props.FillDishAddOnsTable(rowData);
        rowData = {
            DishId: '',
            DishAddOnsId: '0',
            AddOnsName: "",
            FoodTypeId: "",
            AddOnsCharges: 0
        }

        this.setState(
            { rowData: rowData }
        )
    }


    GetFoodTypeList() {

        ApiInstance().get('FoodTypeMaster/SelectAlltblFoodTypeMaster')
            .then(response => {

                var data = response.data.data;


                if (data) {

                    let dataObject = JSON.parse(response.data.data);

                    this.setState({ FoodTypeList: dataObject.Data });
                }
                else {
                }
            }, error => {
                console.log(error);
            });
    }

    render() {
        return (
            <div>
                <div className="row">

                    <Modal className="modal-max" show={ this.state.show15 } onHide={ () => this.handleClose() } aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Body>
                            <div className="col-md-12 mb-3">
                                <button type="button" className="close" onClick={ () => this.handleClose() }><span aria-hidden="true">×</span></button>
                                <h1 style={ { textAlign: "center" } }>Add AddOns</h1>
                                <hr></hr>
                            </div>
                            <div className="col-md-12 mb-3">
                                <form id="AddOnsForm" className="needs-validation clearfix" noValidate onSubmit={ this._handleAddAddOnsEvent }>
                                    <div className="form-group row">
                                        <label htmlFor="colFormLabelSm" className="col-sm-4 col-form-label col-form-label-sm">AddOns Name</label>
                                        <div className="col-sm-8">
                                            <input type="text" value={ this.state.rowData.AddOnsName || '' } onChange={ this._handleAddOnsChangeEvent } id="AddOnsName" name="AddOnsName" placeholder="Chees" required className="form-control form-control-sm" />
                                            <div className="invalid-feedback">
                                                Please enter AddOns Name.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="colFormLabelSm" className="col-sm-4 col-form-label col-form-label-sm">Food Type </label>
                                        <div className="col-sm-8">
                                            <select className="form-control" id="FoodTypeId" name="FoodTypeId" required value={ this.state.rowData.FoodTypeId || '' } onChange={ this._handleAddOnsChangeEvent }>
                                                <option></option>
                                                {
                                                    this.state.FoodTypeList.map((element, index) => <option key={ index } value={ element.FoodTypeId }>{ element.FoodTypeName }</option>)
                                                }
                                            </select>
                                            <div className="invalid-feedback">
                                                Please enter discount.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="colFormLabelSm" className="col-sm-4 col-form-label col-form-label-sm">AddOn Charge</label>
                                        <div className="col-sm-8">
                                            <input type="number" value={ this.state.rowData.AddOnsCharges || '' } onChange={ this._handleAddOnsChangeEvent } id="AddOnsCharges" name="AddOnsCharges" placeholder="$10" required className="form-control form-control-sm" />
                                            <div className="invalid-feedback">
                                                Please enter charges.
                                             </div>
                                        </div>
                                    </div>


                                    <div className="form-group row">

                                        <div className="align-self-center mx-auto">
                                            <button className="btn btn-primary d-block" type="submit">Add</button>
                                        </div>

                                    </div>

                                </form>
                            </div>
                        </Modal.Body>
                    </Modal>


                </div>


            </div>
        )
    }
}
