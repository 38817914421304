import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ApiInstance from '../../../Utilities/axiosConfig';
import Config from '../../../Config';
import $ from 'jquery';



class Recentorder extends Component {

    constructor () {
        super();

        this.state = {
            RecentOrderList: []
        }
    }

    componentDidMount() {
        this.GetOrderList();
        setInterval(this.GetOrderList, 15000);
    }

    GetOrderList = () => {
        //return;
        ApiInstance().get('CookMaster/GetCookNewOrderList')
            .then(response => {
                var data = response.data.data;

                if (data) {
                    let dataObject = JSON.parse(response.data.data);

                    this.setState({ RecentOrderList: dataObject.Data }, () => {
                        var objkeylist = dataObject.Columns.split(",").map(i => i.trim());
                        var columnlist = []
                        objkeylist.forEach(element => {
                            element = element.trim();
                            if (element !== 'OrderMasterId')
                                columnlist.push({ title: element.split(/(?=[A-Z])/).join(" "), data: element })
                        });

                        columnlist.push({
                            data: 'OrderMasterId',
                            title: 'Action',
                            render: function (td, data, type, row) {
                                return " ";
                            }

                        })

                        $('#Recent_Order_data-table').DataTable({

                            data: this.state.RecentOrderList,
                            columns: columnlist,
                            "bDestroy": true,
                            columnDefs: [
                                {
                                    targets: objkeylist.length - 1,
                                    createdCell: (td, cellData, rowData, row, col) =>

                                        ReactDOM.render(<>
                                            <a href={ "/Cook/OrderDetails/" + rowData.OrderMasterId } className="btn btn-primary btn-sm">View Details</a>

                                        </>, td),
                                }
                            ],
                        });
                    });
                }
                else {
                }
            }, error => {
                console.log(error);
            });
    }

    render() {
        return (
            <div className="table-responsive">
                <table id="Recent_Order_data-table" className="table table-striped thead-primary w-100" />
            </div>
        );
    }
}

export default Recentorder;