import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../../components/layouts/Topnavigation';
import Quickbar from '../../../components/layouts/Quickbar';
import Breadcrumb from '../Breadcrumb';
import "datatables.net-bs4/js/dataTables.bootstrap4"
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css"
import $ from 'jquery';
import ApiInstance from '../../../Utilities/axiosConfig'
import { ToastMessage } from '../../../Utilities/ToastMessage'
import Swal from 'sweetalert2'
import { GetSessionData } from '../../../GlobalFactory';

export default class Dishes extends PureComponent {
    constructor () {
        super();
        this.state = {
            DishList: [],

        }


        this._handleDeleteEvent = this.handleDeleteEvent.bind(this);
    }

    componentDidMount() {

        this.GetDishList();
    }

    GetDishList() {

        ApiInstance().get('DishMaster/SelectAlltblDishMasterByCookId?CookId=' + GetSessionData().UserId)
            .then(response => {

                var data = response.data.data;
                console.log(data)



                if (data) {

                    let dataObject = JSON.parse(response.data.data);

                    this.setState({ DishList: dataObject.Data }, () => {

                        var objkeylist = dataObject.Columns.split(",").map(i => i.trim());

                        var columnlist = []

                        objkeylist.forEach(element => {
                            element = element.trim();
                            if (element !== 'DishId')
                                columnlist.push({ title: element.split(/(?=[A-Z])/).join(" "), data: element })
                        });

                        columnlist.push({
                            data: 'DishId',
                            title: 'Action',
                            render: function (td, data, type, row) {
                                return " ";
                            }

                        })

                        $('#Dish_data-table').DataTable({

                            data: this.state.DishList,
                            columns: columnlist,
                            "bDestroy": true,
                            columnDefs: [
                                {
                                    targets: objkeylist.length - 1,
                                    createdCell: (td, cellData, rowData, row, col) =>

                                        ReactDOM.render(<>
                                            <a href={ "/Cook/EditDish/" + cellData } target="_blank"><i className='fas fa-pencil-alt text-secondary'></i></a>
                                            <a href='#' onClick={ () => this._handleDeleteEvent(cellData) }><i className='far fa-trash-alt ms-text-danger'></i></a>
                                        </>, td),
                                }
                            ],
                        });
                    });
                }
                else {
                }
            }, error => {
                console.log(error);
            });
    }


    handleDeleteEvent = (id) => {

        var thisobj = this;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showClass: {
                popup: 'animated fadeInDown faster',
                icon: 'animated heartBeat delay-1s'
            },
            hideClass: {
                popup: 'animated fadeOutUp faster',
            },
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(function (result) {
            if (result.value) {

                let formdata = {
                    'DishId': id
                }

                ApiInstance().post('DishMaster/DeletetblDishMasterByDishId', formdata, { headers: { 'Content-Type': 'application/json' } })
                    .then(response => {
                        let data = response.data;
                        if (data.status) {
                            ToastMessage('Success', 'Record has been deleted successfully.', 'success')
                            thisobj.GetDishList();
                        }
                        else {
                            ToastMessage('Fail!', data.data, 'danger')
                        }

                    }, error => {
                        console.log(error);
                    });

            }
        });



    }

    render() {
        return (
            <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                <Sidenavigation />
                <main className="body-content">

                    <Topnavigation />
                    <div className="ms-content-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <Breadcrumb FormName="Dishes" />
                            </div>
                            <div className="col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-header">
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <h6>Dishes List</h6>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <a href="/Cook/AddDish" className="btn btn-primary btn-sm float-right">Add Dish</a>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="ms-panel-body">
                                        <table id="Dish_data-table" className="table table-striped thead-primary w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Quickbar />
            </div>
        );
    }
}
