import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../../components/layouts/Topnavigation';
import Quickbar from '../../../components/layouts/Quickbar';
import Breadcrumb from '../Breadcrumb';
import "datatables.net-bs4/js/dataTables.bootstrap4"
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css"
import $ from 'jquery';
import ApiInstance from '../../../Utilities/axiosConfig'
import { ToastMessage } from '../../../Utilities/ToastMessage'
import Swal from 'sweetalert2'
import { GetSessionData, TodaysDate, DateFromLastMonth } from '../../../GlobalFactory';
import { Modal, Accordion, Card } from "react-bootstrap";
import Config from '../../../Config';

export default class RefundHistory extends Component {
    constructor () {
        super();

        this.state = {
            OrderList: [],

        }



    }
    componentDidMount() {
        function formValidation() {
            window.addEventListener('load', function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function (form) {
                    form.addEventListener('submit', function (event) {


                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add('was-validated');
                        }
                        else {
                            form.classList.remove('was-validated');
                        }
                    }, false);
                });

            }, false);
        }
        formValidation();


        this.GetOrderList();
    }

    GetOrderList = () => {

        var today = new Date()

        var dtfrom = (document.getElementById("dtTo").value) ? document.getElementById("dtTo").value : (DateFromLastMonth().PrevMonthdate_YYYY_MMM_DD);
        var dtto = (document.getElementById("dtFrom").value) ? document.getElementById("dtFrom").value : (TodaysDate().Todaydate_YYYYMMMDD);

        let jsonobj = {
            StartDate: dtfrom,
            EndDate: dtto,
            IsDisputeOpen: null
        };

        console.log(jsonobj);

        ApiInstance().post('DisputeMaster/GetDisputeDataByOpenCloseAndDateTime', jsonobj, { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                var data = response.data;
                console.log(data)
                if (data) {

                    var dataObject = JSON.parse(data.data)
                    console.log(dataObject.Data)


                    this.setState({
                        OrderList: dataObject.Data

                    }, () => {
                        var objkeylist = dataObject.Columns.split(",").map(i => i.trim());
                        var columnlist = []
                        objkeylist.forEach(element => {
                            element = element.trim();
                            if (element !== 'OrderMasterId')
                                columnlist.push({ title: element.split(/(?=[A-Z])/).join(" "), data: element })
                        });

                        // columnlist.push({
                        //     data: 'OrderMasterId',
                        //     title: 'Action',
                        //     render: function (td, data, type, row) {
                        //         return " ";
                        //     }

                        // })

                        $('#Cook_OrderList_data-table').DataTable({

                            data: this.state.OrderList,
                            columns: columnlist,
                            "bDestroy": true,
                            columnDefs: [
                                {
                                    targets: objkeylist.indexOf("ClientDisputePhoto") - 1,
                                    createdCell: (td, cellData, rowData, row, col) =>

                                        ReactDOM.render(<>
                                            {/* <a href={ "/Report/ReportCookOrderMasterDetailsByOrderMasterId/" + rowData.OrderMasterId } className="btn btn-primary btn-sm">View Details</a> */ }
                                            {rowData.ClientDisputePhoto && <a href={ Config.BaseURL + rowData.ClientDisputePhoto } target="_blank" >View </a> }
                                        </>, td),
                                },
                                {
                                    targets: objkeylist.indexOf("IsDisputeOpen") - 1,
                                    createdCell: (td, cellData, rowData, row, col) =>

                                        ReactDOM.render(<>
                                            {/* <a href={ "/Report/ReportCookOrderMasterDetailsByOrderMasterId/" + rowData.OrderMasterId } className="btn btn-primary btn-sm">View Details</a> */ }
                                            {(rowData.IsDisputeOpen) ? <span class="badge badge-primary">Open</span> : <span class="badge badge-success">Close</span> }
                                        </>, td),
                                },

                            ],
                        });
                    }

                    );
                }
            }, error => {
                console.log(error);
            });
    }







    render() {
        return (
            <>
                <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                    <Sidenavigation />
                    <main className="body-content">
                        <Topnavigation />
                        <div className="ms-content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <Breadcrumb FormName="Order List" />
                                </div>
                                <div className="col-md-12">
                                    <div className="ms-panel ms-panel-fh">
                                        <div className="ms-panel-header">
                                            <h6>Filter</h6>
                                        </div>
                                        <div className="ms-panel-body">

                                            <div className="form-row">
                                                <div className="col-md-3 mb-3">
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="DishName">From</label>
                                                    <div className="input-group">
                                                        <input type="date" className="form-control" id="dtFrom" />
                                                        <div className="invalid-feedback">
                                                            Please select day/week
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="DishName">To</label>
                                                    <div className="input-group">
                                                        <input type="date" className="form-control" id="dtTo" />
                                                        <div className="invalid-feedback">
                                                            Please select day/week
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <button className="btn btn-primary" type="submit" onClick={ () => { this.GetOrderList() } }>Search</button>
                                                    &nbsp;&nbsp; <button className="btn btn-primary" type="button" onClick={ () => { this.ResetData() } }>Reset</button>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="ms-panel ms-panel-fh">
                                        <div className="ms-panel-header">
                                            <h6>Order List</h6>
                                        </div>
                                        <div className="ms-panel-body">
                                            <table id="Cook_OrderList_data-table" className="table table-striped thead-primary w-100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>


                    <Quickbar />
                </div>


            </>
        )
    }
}
